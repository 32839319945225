import cx from "classnames"

const AdvisorySignupTestimonials = () => (
  <div className="w-full max-w-[816px] mx-auto mt-8">
    <h3 className="text-2xl text-autumn sm:text-center font-semibold leading-[124%] tracking-0.48">
      Hear from landowners like you
    </h3>

    <div className="sm:grid sm:grid-cols-3 sm:gap-12">
      <p
        className={cx(
          "relative text-base text-dusk leading-[140%] tracking-0.32 px-8 sm:px-0 lg:pr-1 mt-4",
          "before:absolute before:content-[''] before:w-px before:bg-autumn",
          "sm:before:h-20 before:left-0 sm:before:left-auto sm:before:-right-6 before:top-0 sm:before:top-1/2 before:bottom-0 sm:before:bottom-auto sm:before:-translate-y-1/2"
        )}
      >
        <q>
          I feel like I have somebody who is looking after my interests so that
          I don't do something that costs me down the road.
        </q>{" "}
        <strong>- Paul</strong>
      </p>

      <p
        className={cx(
          "relative text-base text-dusk leading-[140%] tracking-0.32 px-8 sm:px-0 mt-4",
          "before:absolute before:content-[''] before:w-px before:bg-autumn",
          "sm:before:h-20 before:left-0 sm:before:left-auto sm:before:-right-6 before:-top-4 sm:before:top-1/2 before:bottom-0 sm:before:bottom-auto sm:before:-translate-y-1/2"
        )}
      >
        <q>
          One of the things that has kept me from committing to these things in
          the past is the worry that I'm missing something else that might be
          going on. But with this, I don't have to worry about that anymore.
        </q>{" "}
        <strong>- James</strong>
      </p>

      <p
        className={cx(
          "relative text-base text-dusk leading-[140%] tracking-0.32 px-8 sm:px-0 lg:pl-1 mt-4",
          "before:absolute sm:before:hidden before:content-[''] before:w-px before:bg-autumn",
          "sm:before:h-20 before:left-0 sm:before:left-auto sm:before:-right-6 before:-top-4 sm:before:top-1/2 before:bottom-0 sm:before:bottom-auto sm:before:-translate-y-1/2"
        )}
      >
        <q>
          I am trying to make money, but I was about to give away money. Glad I
          engaged you guys.
        </q>{" "}
        <strong>- Orlando</strong>
      </p>
    </div>
  </div>
)

export default AdvisorySignupTestimonials
