// Initial form for unified sign-up and login page
// Based on: https://www.figma.com/file/y6PZFjtbHseeU5QD9WxmHM/Sign-Up%2FIn?type=design&node-id=12022-680&mode=design&t=bnm1iLqNTWG7Fpy3-4
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Formik, FormikHelpers } from "formik"
import { OptimizelyContext } from "@optimizely/react-sdk"
import * as Yup from "yup"

import GettingStartedFormComponent from "./GettingStartedFormComponent"
import { genericErrMsg } from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
  heapTrackEvent,
} from "../../api/integrations"
import { useCheckUserByEmail, useLoginWithGoogle } from "../../hooks"
import { AUTH_STEPS } from "./constants"
import { useSessionStore } from "../../stores"
import { AuthDataTypes } from "@/types/auth"

export interface GettingStartedFormValues {
  email: string
}

interface GettingStartedTypes {
  inputEmail: string
  setIsUserCreated: Dispatch<SetStateAction<boolean>>
  setInputEmail: Dispatch<SetStateAction<string>>
  setAuthData: Dispatch<SetStateAction<GettingStartedFormValues>>
}

const VITE_APP_PARTNER_VIEW = import.meta.env.VITE_APP_PARTNER_VIEW

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
})

const GettingStartedForm = ({
  inputEmail,
  setIsUserCreated,
  setInputEmail,
  setAuthData,
}: GettingStartedTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const navigate = useNavigate()
  const location = useLocation()
  const { optimizely } = useContext(OptimizelyContext)
  const { identified, setIdentified } = useSessionStore((state) => state)

  const { mutateAsync: checkUserByEmail } = useCheckUserByEmail<
    any,
    Error,
    AuthDataTypes
  >({
    onSuccess: (
      data: { exists: any; sso_provider: any },
      variables: {
        email?: SetStateAction<string>
      }
    ) => {
      setInputEmail(variables.email as SetStateAction<string>)
      let nextStep
      if (data.exists) {
        if (data.sso_provider) {
          // DEV: Currently we only support google, if we add more we'll need to handle showing right provider
          nextStep = AUTH_STEPS.ssoLogin
        } else {
          nextStep = AUTH_STEPS.emailLogin
        }
      } else {
        nextStep = AUTH_STEPS.signUp
      }
      navigate(location.pathname, {
        state: { ...location.state, authStep: nextStep },
      })
    },
    onError: (error: Error) => {
      setErrorMessage(error?.message || genericErrMsg)
    },
  })

  const { mutateAsync: loginWithGoogle } = useLoginWithGoogle({
    /*
     * data is from api response (our jwt info + user info),
     * variables is what we send in onSubmit (google token)
     */
    onSuccess: (
      data: { email: string; new_sign_up: any; profile_type: string },
      _variables: any
    ) => {
      identifyUserForIntegrations(
        data.email,
        optimizely,
        identified,
        setIdentified
      )
      if (data.new_sign_up) {
        sendAnalyticsEvent({
          action: "lp_google_reg",
          label: "Logical Position: Landowner Signup with Social",
        })
      } else {
        sendAnalyticsEvent({
          action: "login_google",
          label: "Landowner Sign-in with Social",
        })
      }

      heapTrackEvent("google_login", {
        authStep: "getting-started",
        newUser: !!data.new_sign_up,
      })
      let landingPage
      // bark_api is telling us this is a new user
      if (data.new_sign_up) {
        setIsUserCreated(true)
        landingPage = "/onboarding"
      } else {
        // DEV: multi-account users have to select an account context before viewing programs
        landingPage =
          data?.profile_type === "multi_account" &&
          VITE_APP_PARTNER_VIEW === "0"
            ? "/accounts"
            : "/programs"
      }
      // Complete any existing login state, https://reactrouter.com/web/example/auth-workflow
      // DEV: They use `replace` but I'd rather keep `login` on the history stack
      const redirect: string = location.state?.from || landingPage
      navigate(redirect)
    },
    onError: () => {
      setErrorMessage(genericErrMsg)
    },
  })

  const handleSubmitSso = async (values: { accessToken: string }) => {
    setErrorMessage(null)
    await loginWithGoogle(values)
  }

  const onSubmitEmail = async (
    values: GettingStartedFormValues,
    { setSubmitting }: FormikHelpers<GettingStartedFormValues>
  ) => {
    await checkUserByEmail(values)
    setSubmitting(false)
  }

  return (
    <Formik<GettingStartedFormValues>
      initialValues={{
        email: inputEmail || "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmitEmail}
    >
      {(formikProps) => (
        <GettingStartedFormComponent
          {...formikProps}
          setAuthData={setAuthData}
          errorMessage={errorMessage}
          onSsoSuccess={(values: { accessToken: string }) => {
            handleSubmitSso(values)
          }}
          onSsoError={() => setErrorMessage(genericErrMsg)}
        />
      )}
    </Formik>
  )
}
export default GettingStartedForm
