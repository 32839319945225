import { useNavigate } from "react-router-dom"

import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ContentCard } from "../../components/Card"
import { useAccountUrlPrefix } from "../../hooks"
import { AccountRoleType } from "../../types/account"
import { FEATURE_FLAGS } from "../../shared/constants"
import useFeatureFlags from "../../hooks/useFeatureFlags"

interface BookAnExpertConsultationProps {
  accountRole: AccountRoleType
}

const BookAnExpertConsultation = ({
  accountRole,
}: BookAnExpertConsultationProps) => {
  const navigate = useNavigate()
  const accountUrlPrefix = useAccountUrlPrefix()

  const { flagEnabled: altMessagingEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.nca_messaging_20241107
  )

  return (
    <ContentCard>
      <h2 className="text-xl md:text-3xl text-autumn font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px]">
        Your land. Your goals. Expert strategy.
      </h2>

      <p className="leading-[140%] tracking-0.32 mt-4">
        {clientReady && altMessagingEnabled
          ? "$3,000 in income opportunities or your money back"
          : "Break even on your property taxes in year 1 or your money back"}
      </p>

      <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
        <button
          className="dashboard-nca max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          onClick={() => {
            navigate(`${accountUrlPrefix}/advisor-signup`)
          }}
        >
          Learn More
        </button>
      </ActionPermissionWrapper>
    </ContentCard>
  )
}

export default BookAnExpertConsultation
