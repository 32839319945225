import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { cloneDeep } from "lodash"

import { useAccountCycleOverrider, useAccountQueryWithRefresh } from "../utils"
import { getAccountAccountCycles } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { AccountCycleTypes } from "../../../types/accountCycles"

export function useAccountAccountCycles<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  data?: { is_archived: boolean } | undefined,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const applyAccountCycleOverride = useAccountCycleOverrider()

  const query = useAccountQueryWithRefresh(
    queryClient,
    ["accounts", accountId, "cycles", data],
    () => getAccountAccountCycles(accountId, data),
    config
  )

  const transformedData = query.data ? cloneDeep(query.data) : query.data

  if (transformedData) {
    // One day this may move to `object_list`, but not today, https://app.asana.com/0/1201653128864814/1201759560003317/f
    const accountCycleList: AccountCycleTypes[] = transformedData
    accountCycleList.forEach((accountCycle) =>
      applyAccountCycleOverride(accountCycle, accountId)
    )
  }

  return {
    ...query,
    data: transformedData,
  }
}
