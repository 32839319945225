import { USStateAbbrType, USStateFullType } from "../types/constants"

export type USStateOption = [USStateAbbrType, USStateFullType]

type PrivateIndividualFamilyForestOwnerType =
  "Private-Individual/Family Forest Owner"

// Each element of this array is [state database value, state display value]
// https://www.bls.gov/respondents/mwr/electronic-data-interchange/appendix-d-usps-state-abbreviations-and-fips-codes.htm
// Excludes Virgin Islands and Puerto Rico
export const stateOptions: USStateOption[] = [
  ["AK", "Alaska"],
  ["AL", "Alabama"],
  ["AR", "Arkansas"],
  ["AZ", "Arizona"],
  ["CA", "California"],
  ["CO", "Colorado"],
  ["CT", "Connecticut"],
  ["DC", "District of Columbia"],
  ["DE", "Delaware"],
  ["FL", "Florida"],
  ["GA", "Georgia"],
  ["HI", "Hawaii"],
  ["IA", "Iowa"],
  ["ID", "Idaho"],
  ["IL", "Illinois"],
  ["IN", "Indiana"],
  ["KS", "Kansas"],
  ["KY", "Kentucky"],
  ["LA", "Louisiana"],
  ["MA", "Massachusetts"],
  ["MD", "Maryland"],
  ["ME", "Maine"],
  ["MI", "Michigan"],
  ["MN", "Minnesota"],
  ["MO", "Missouri"],
  ["MS", "Mississippi"],
  ["MT", "Montana"],
  ["NC", "North Carolina"],
  ["ND", "North Dakota"],
  ["NE", "Nebraska"],
  ["NH", "New Hampshire"],
  ["NJ", "New Jersey"],
  ["NM", "New Mexico"],
  ["NV", "Nevada"],
  ["NY", "New York"],
  ["OH", "Ohio"],
  ["OK", "Oklahoma"],
  ["OR", "Oregon"],
  ["PA", "Pennsylvania"],
  ["RI", "Rhode Island"],
  ["SC", "South Carolina"],
  ["SD", "South Dakota"],
  ["TN", "Tennessee"],
  ["TX", "Texas"],
  ["UT", "Utah"],
  ["VA", "Virginia"],
  ["VT", "Vermont"],
  ["WA", "Washington"],
  ["WI", "Wisconsin"],
  ["WV", "West Virginia"],
  ["WY", "Wyoming"],
]

export const privateIndividualFamilyForestOwnerDB: PrivateIndividualFamilyForestOwnerType =
  "Private-Individual/Family Forest Owner"

const stateNames: USStateFullType[] = stateOptions.map(
  (option: USStateOption) => option[1]
)

export const stateAbbreviations: USStateAbbrType[] = stateOptions
  .map((option: USStateOption) => option[0])
  .filter((state: USStateAbbrType) => state !== "DC")

export const conus: USStateAbbrType[] = stateAbbreviations.filter(
  (state: USStateAbbrType) => state !== "AK" && state !== "HI"
)

export const stateAbbreviationPattern: RegExp = new RegExp(
  "\\b(?:" + stateAbbreviations.join("|") + ")\\b",
  "g"
)

export const stateNamePattern: RegExp = new RegExp(
  "\\b(?:" + stateNames.join("|") + ")\\b",
  "g"
)
