import { Link } from "react-router-dom"
import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import {
  Tooltip as ReakitTooltip,
  TooltipArrow,
  TooltipReference,
  useTooltipState,
} from "reakit/Tooltip"

import { Toast } from "../../components/Toast"
import CheckmarkListItem from "../../components/CheckmarkListItem"
import { useAccount, useAccountId, useCreateStripeSession } from "../../hooks"
import useFeatureFlags from "../../hooks/useFeatureFlags"
import { FEATURE_FLAGS } from "../../shared/constants"
import { genericErrMsg } from "../../api/auth"

const AdvisorSignupPay = () => {
  const tooltip = useTooltipState()
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { flagEnabled: altMessagingEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.nca_messaging_20241107
  )

  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: "nca_onetime",
    }

    await createStripeSession(postData)
  }

  if (accountIsLoading) {
    return null
  }

  return (
    <div className="w-full max-w-[816px] mx-auto">
      <h2 className="text-xl md:text-3xl text-autumn text-center font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px] mt-7">
        {clientReady && altMessagingEnabled
          ? "$3,000 in income opportunities or your money back"
          : "Break even on your property taxes in year 1 or your money back"}
      </h2>

      <div className="w-full border border-dusk-50 rounded py-4 pr-8 pl-7 mt-7 mb-3">
        <div className="md:flex md:gap-x-5">
          <div className="grow">
            <div className="flex gap-2 items-center">
              <h4 className="text-sm font-medium font-overline uppercase leading-[150%] tracking-[1.68px] flex-0">
                What you'll get
              </h4>

              <div className="h-0 border-b border-[#C0C8CE] grow" />
            </div>

            <ul className="mt-6">
              <CheckmarkListItem className="min-h-[26px]!leading-[140%]">
                1-on-1 phone consultation
              </CheckmarkListItem>
              <CheckmarkListItem className="min-h-[26px] !leading-[140%] mt-3">
                Strategy session to understand your goals
              </CheckmarkListItem>
              <CheckmarkListItem className="min-h-[26px]!leading-[140%] mt-3">
                Personalized analysis of your land’s potential and local market
                conditions
              </CheckmarkListItem>
              <CheckmarkListItem className="min-h-[26px] !leading-[140%] mt-3">
                Documented plan so that you know what to do, when to do it, and
                recommended income streams to fund your goals
              </CheckmarkListItem>
              <CheckmarkListItem className="min-h-[26px]!leading-[140%] mt-3">
                6 months of expert support
              </CheckmarkListItem>
            </ul>
          </div>

          <div className="flex flex-col w-full md:w-[230px] shrink-0 bg-sand border border-charcoal-50 rounded pt-5 px-9 pb-4 mt-5 md:mt-0">
            <p className="text-xl text-charcoal-500 text-center md:text-left leading-[140%] tracking-[0.28px] md:pl-[10px]">
              Pay now and an NCX expert will reach out to schedule a call.
            </p>

            <p className="text-2xl text-charcoal-500 text-center font-medium leading-[124%] tracking-0.48 pt-3 mt-auto">
              $300
            </p>

            <TooltipReference {...tooltip} className="ring-0">
              <button
                className="member-widget-join-now max-w-40 h-[45px] btn2 btn2-primary font-semibold btn2-block mx-auto mt-3 mb-0"
                onClick={() => {
                  handleStripeSubmit()
                }}
                disabled={account?.role !== "admin"}
              >
                Pay Now
              </button>
            </TooltipReference>

            {account?.role !== "admin" ? (
              <ReakitTooltip {...tooltip} className="st-tooltip">
                <div className="text-sm max-w-sm p-3 st-tooltip-inner">
                  <TooltipArrow {...tooltip} />
                  Only admins can subscribe.
                </div>
              </ReakitTooltip>
            ) : null}
          </div>
        </div>
      </div>

      <Link
        to="/"
        className="member-widget-dashboard-link text-lg text-leaf leading-[140%] tracking-0.36"
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  )
}

export default AdvisorSignupPay
