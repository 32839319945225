import { useLocation } from "react-router-dom"

import { e } from "../utils"
import { buildAccountDocumentActionURL } from "../../../api/data"
import { DocumentType } from "@/types/document"

export interface UseAccountDocumentActionURLTypes {
  accountId: string
  cycleKey: string
  documentType: DocumentType
  action: string
  returnUrl?: string
}

export function useAccountDocumentActionURL(
  params: UseAccountDocumentActionURLTypes
) {
  // DEV: Adding return URL is excessive for eligibility report but makes sense for other document types
  //   Consider it part of futureproofing? bark_api can discard the parameters freely
  // DEV: We intentionally don't include hostname to reduce amount of security checks (allowlist) in `bark_api`
  // const returnUrl = '/accounts/:id?document_type=ncapx_sale_agreement'
  const location = useLocation()
  const returnUrl =
    location.pathname + `?document_type=${e(params.documentType)}`
  return buildAccountDocumentActionURL({
    ...params,
    returnUrl,
  })
}
