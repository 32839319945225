import { useMemo } from "react"
import { TooltipProps } from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"
import cx from "classnames"

import { INDEX_DATA_NAMES } from "./constants"

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  const indexes = useMemo(() => {
    return payload
      ? [
          payload.find((item) => item.name === INDEX_DATA_NAMES.timber_index),
          payload.find((item) => item.name === INDEX_DATA_NAMES.carbon_index),
          payload.find(
            (item) => item.name === INDEX_DATA_NAMES.forest_health_index
          ),
        ]
      : []
  }, [payload])

  const sortedIndexes = useMemo(() => {
    return indexes
      .filter((index) => index !== undefined && index?.value !== null)
      .sort((a, b) => (b?.value as number) - (a?.value as number))
  }, [indexes])

  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-charcoal-50 rounded p-2">
        <p className="text-base charcoal-500 font-semibold leading-130 tracking-0.32 mb-3">
          Year: {label}
        </p>

        {sortedIndexes.map((index, i, arr) => (
          <p
            key={index?.name}
            className={cx(
              "flex items-center text-base leading-130 tracking-0.32",
              { "mb-1": i !== arr.length - 1 }
            )}
            style={{ color: index?.stroke }}
          >
            {index?.name}:{" "}
            {Math.round(
              (((index?.value as number) ?? 0) + Number.EPSILON) * 100
            )}
          </p>
        ))}
      </div>
    )
  }
  return null
}

export default CustomTooltip
