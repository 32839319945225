import { useDecision } from "@optimizely/react-sdk"
import { useQueryParam } from "."

interface UseFeatureFlagsResult {
  flagEnabled: boolean | undefined
  clientReady: boolean
}

/**
 * Custom hook for getting feature flag `enabled` value from Optimizely service
 * If we are in a state where a user is logged-out and we have not re-set the correct user,
 * we return {flagEnabled: null, clientReady: false} else we return {flagEnabled: boolean, clientReady}
 * Suggested usage:
 *   const { flagEnabled: myFlagEnabled, clientReady } = useFeatureFlags(FEATURE_FLAGS.<flag_key>)
 *   // wait to render until client is ready & has correct user context
 *   if (!clientReady) return
 *   {myFlagEnabled ? <div>New Feature is enabled</div> : <div>Default component</div>}
 *
 * A flag value can be overridden with a query param: `?flagOverride-<flagKey>=<true/false>`
 * @param {string} flagKey - the key for the feature flag as defined in Optimizely
 */
const useFeatureFlags = (flagKey: string): UseFeatureFlagsResult => {
  const overridePrefix = "flagOverride-"
  const flagOverrideVal = useQueryParam(`${overridePrefix}${flagKey}`)
  const [decision, clientReady] = useDecision(flagKey, {
    // DEV: we need this so that optimizely re-checks user context when making a decision.
    // in cases where a user logged-out and logged-in as a different user
    autoUpdate: true,
  })
  if (flagOverrideVal !== null) {
    return { flagEnabled: flagOverrideVal === "true", clientReady: true }
  }

  const userContextId = decision.userContext.id
  // we are between resetting user context on a logout/login
  if (userContextId === null || userContextId === undefined) {
    return { flagEnabled: undefined, clientReady: false }
  }
  return { flagEnabled: decision.enabled, clientReady }
}

export default useFeatureFlags
