import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useAccountQueryWithRefresh } from "../utils"
import { getAccountUsers } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useAccountUsers<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh(
    queryClient,
    ["accounts", accountId, "users"],
    () => getAccountUsers(accountId),
    config
  )
}
