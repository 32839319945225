import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { postQuestion } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { FormikValues } from "formik"

export const usePostQuestion = <TData, TError, TVariables extends FormikValues>(
  queryClient: QueryClient,
  projectId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => postQuestion(projectId, values),
    {
      _onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["projects", projectId, "questions"],
        })
      },
      ...config,
    }
  )
}
