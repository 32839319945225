import { ReactElement, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import * as Yup from "yup"
import { Formik, FormikValues } from "formik"

import { HelpCard } from "../../components/HelpCard"
import AddAccountFormComponent from "./AccountInfoFormComponent"
import { genericErrMsg } from "../../api/auth"
import { useAccountId, useCreateAccount, useUpdateAccount } from "../../hooks"
import useIsNew from "../../hooks/useIsNew"
import { phoneRegex } from "../../shared/constants"
import { AccountTypes } from "../../types/account"
import { InitialAccountValuesTypes } from "../../pages/accounts/AccountInfo"

interface AccountInfoFormTypes {
  backLink: ReactElement
  initialValues: AccountTypes | InitialAccountValuesTypes
  onSuccess: (account: AccountTypes) => void
  page?: string
  submitText: string
}

const HELP_CARDS = [
  {
    title: "What should I name this account?",
    content:
      "This could be a landowner name, entity name, fund name, or anything else that you would recognize as this property if you saw it in a list.",
  },
  {
    title: "Who’s the account contact?",
    content:
      "If you’re representing a landowner, you can add their info here for your records.",
  },
]

const validationSchema = Yup.object().shape({
  account_name: Yup.string().required("Please provide an account name"),
  optional_contact_email: Yup.string().email(
    "Please provide a valid email address"
  ),
  optional_contact_first_name: Yup.string(),
  optional_contact_last_name: Yup.string(),
  optional_contact_phone_number: Yup.string().matches(
    phoneRegex,
    "Please provide a valid phone number"
  ),
})

// DEV: Minimize loading spinner for form by receiving `initialValues` from parent
// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const AccountInfoForm = ({
  backLink,
  initialValues,
  onSuccess,
  page,
  submitText,
}: AccountInfoFormTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const isNew = useIsNew()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const useUpsertAccount = isNew
    ? useCreateAccount.bind(this, queryClient, accountId)
    : useUpdateAccount.bind(this, queryClient, accountId)
  const { mutateAsync: upsertAccount } = useUpsertAccount({
    onSuccess: (data: unknown) => {
      onSuccess(data as AccountTypes)
    },
    onError: (error) => {
      const err = error as { detail: string }
      setErrorMessage(err?.detail || genericErrMsg)
    },
  })

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage(null)
    await upsertAccount(values)
  }

  const isOnboardingPage = page === "onboarding"

  return (
    <div className="grid grid-cols-1 gap-4 mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3">
      {isOnboardingPage && (
        <div className="max-w-xs  md:max-w-none md:order-2">
          <HelpCard items={HELP_CARDS} />
        </div>
      )}

      <div
        className={cx(
          "md:order-1",
          isOnboardingPage ? "md:col-span-2" : "md:col-span-full"
        )}
      >
        {/* DEV: Avoid `enableReinitialize=true` as if we set up `window.refetchOnWindowFocus` */}
        {/*   then any form updates will be swept away on window refocus */}
        {/*   Can also be remedied by putting `useProfile` at same level as `Formik` component */}
        {/*   These comments may now be out of date after https://app.asana.com/0/0/1201367694639660/1201368096424394/f */}
        <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <AddAccountFormComponent
              errorMessage={errorMessage}
              formikProps={formikProps}
              isOnboardingPage={isOnboardingPage}
              submitText={submitText}
              backLink={backLink}
            />
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AccountInfoForm
