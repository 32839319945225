import { IndexDataScenarioType } from "@/types/property"

export const scenarios: { key: IndexDataScenarioType; label: string }[] = [
  { key: "base", label: "Do nothing" },
  { key: "thin_below", label: "Thin from below" },
  { key: "thin_uniform", label: "Uniform thin" },
  { key: "clearcut", label: "Clearcut" },
]

export const INDEX_DATA_NAMES = {
  timber_index: "Timber Value",
  forest_health_index: "Fire Resilience",
  carbon_index: "Carbon Storage",
}
