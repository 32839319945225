import { createContext, Dispatch } from "react"
import { PAYMENT_TYPES, PROJECT_ACTIONS } from "../shared/constants"
import { ProgramCategoryType } from "../types/constants"
import { PaymentTypeKeysType } from "../types/program"

export interface StateTypes {
  category: ProgramCategoryType | null
  govtProjectsOn: boolean
  ineligibleProjectsOn: boolean
  hasLandownerCost: boolean
  termLengthFilter: [number, number]
  paymentTypes: Record<PaymentTypeKeysType, boolean>
  searchProjects: string
}

export type ActionType =
  | {
      type: typeof PROJECT_ACTIONS.setCategory
      payload: ProgramCategoryType | null
    }
  | { type: typeof PROJECT_ACTIONS.setGovtProjectsOn; payload: boolean }
  | { type: typeof PROJECT_ACTIONS.setIneligibleProjectsOn; payload: boolean }
  | { type: typeof PROJECT_ACTIONS.setHasLandownerCost; payload: boolean }
  | {
      type: typeof PROJECT_ACTIONS.setTermLengthFilter
      payload: [number, number]
    }
  | {
      type: typeof PROJECT_ACTIONS.setPaymentTypes
      payload: Record<string, boolean>
    }
  | { type: typeof PROJECT_ACTIONS.setSearchProjects; payload: string }
  | { type: typeof PROJECT_ACTIONS.setInitialState; payload: StateTypes }

export const initialState: StateTypes = {
  category: null,
  govtProjectsOn: true,
  ineligibleProjectsOn: false,
  hasLandownerCost: true,
  termLengthFilter: [0, 100],
  paymentTypes: Object.keys(PAYMENT_TYPES).reduce(
    (acc, paymentType) => {
      return { ...acc, [paymentType]: false }
    },
    {} as Record<string, boolean>
  ),
  searchProjects: "",
}

export const ProjectsContext = createContext<{
  state: StateTypes
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null,
})
