import { LandCoverData, LandCoverDataType } from "../../types/property"
import { ProgramCategoryType } from "../../types/constants"

const LANDCOVER_PROJECT_TYPE_RECOMMENDATIONS = {
  forest: [
    ProgramCategoryType.timber,
    ProgramCategoryType.harvest_deferral,
    ProgramCategoryType.other,
  ],
  cultivated: [
    ProgramCategoryType.tree_planting,
    ProgramCategoryType.regen_ag,
    ProgramCategoryType.recreation,
  ],
  herbaceous: [
    ProgramCategoryType.renewable_infrastructure,
    ProgramCategoryType.biodiversity,
    ProgramCategoryType.other,
  ],
  shrub: [
    ProgramCategoryType.harvest_deferral,
    ProgramCategoryType.biodiversity,
    ProgramCategoryType.other,
  ],
  water: [ProgramCategoryType.biodiversity, ProgramCategoryType.recreation],
  wetlands: [ProgramCategoryType.biodiversity, ProgramCategoryType.other],
  developed: [
    ProgramCategoryType.renewable_infrastructure,
    ProgramCategoryType.other,
  ],
  barren: [ProgramCategoryType.renewable_infrastructure],
}

// all program types for which we have a quiz to send the user to
export const allProgramTypes = [
  ProgramCategoryType.harvest_deferral,
  ProgramCategoryType.tree_planting,
  ProgramCategoryType.recreation,
  ProgramCategoryType.regen_ag,
  ProgramCategoryType.renewable_infrastructure,
  ProgramCategoryType.other, // aka advisory services
  ProgramCategoryType.timber,
  ProgramCategoryType.biodiversity,
  ProgramCategoryType.wildfire,
  ProgramCategoryType.water,
  ProgramCategoryType.forest_carbon,
]

export const getProjectTypeRecommendations = (
  landCoverData: LandCoverData | null | undefined
): ProgramCategoryType[] => {
  if (!landCoverData) {
    return []
  }
  const highestLandCoverType = Object.entries(landCoverData).reduce<
    [string, LandCoverDataType] | null
  >((acc, [key, value]) => {
    if (!acc) {
      return [key, value]
    }
    return value.pct > acc[1].pct ? [key, value] : acc
  }, null)
  if (!highestLandCoverType) {
    return []
  }

  const landCoverKey =
    highestLandCoverType[0] as keyof typeof LANDCOVER_PROJECT_TYPE_RECOMMENDATIONS

  return LANDCOVER_PROJECT_TYPE_RECOMMENDATIONS[landCoverKey] || []
}
