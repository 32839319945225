import { eligibilityQuizBackground } from "./helpers"
import { EligibilityQuizCategoryType } from "@/types/constants"

interface EligibilityQuizStartMaskTypes {
  categoryClass: EligibilityQuizCategoryType | undefined
}

const EligibilityQuizStartMask = ({
  categoryClass,
}: EligibilityQuizStartMaskTypes) => {
  const bgImage = eligibilityQuizBackground(categoryClass)

  return (
    <div className="absolute inset-0 flex items-end">
      <div
        className="absolute inset-0 w-full h-full bg-no-repeat bg-cover bg-bottom"
        style={{
          background: `linear-gradient(0deg, rgba(38, 38, 36, 0.8) 0%, rgba(38, 38, 36, 0.8) 100%), url(${bgImage}) lightgray center / cover no-repeat`,
          backgroundPositionY: categoryClass === "general" ? "4rem" : "0 50%",
        }}
      />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 873"
        fill="none"
        className="absolute inset-x-0 bottom-0"
      >
        <path
          d="M-382 959.971H1822V1042L1081.15 557.133L516.675 854.908L286.389 736.359L-111.274 935.531L-345.491 1042L-382 959.971Z"
          fill="#FFFFFF"
        />
      </svg>
    </div>
  )
}

export default EligibilityQuizStartMask
