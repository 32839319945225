import React from "react"
import cx from "classnames"

interface ProjectTypeHolderTypes {
  onClick: () => void
  isSelected: boolean
  text: string
}

const ProjectTypeHolder = ({
  onClick,
  isSelected,
  text,
}: ProjectTypeHolderTypes) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={(e) => (e.key === "Enter" || e.key === " " ? onClick() : null)}
      className={cx(
        "cursor-pointer select-none flex justify-center items-center w-fit border border-gray-300 shadow focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-45 font-semibold px-2 py-1 rounded",
        { "color-neutral-charcoal": isSelected },
        { "bg-neutral-dusk": isSelected }
      )}
      role="button"
      tabIndex={0}
      aria-label={text}
    >
      {text}
    </div>
  )
}

export default ProjectTypeHolder
