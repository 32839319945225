import { memo } from "react"
import cx from "classnames"

interface CategoryFilterItemProps {
  tag: string
  image: string
  selected: boolean
  onChange: () => void
}

const CategoryFilterItem = memo(
  ({ tag, image, selected, onChange }: CategoryFilterItemProps) => (
    <button
      id={tag}
      className={cx("category-filter-item", {
        "text-autumn": selected,
      })}
      aria-label={`Filter programs by ${tag}`}
      onClick={onChange}
    >
      <div className="w-24 mb-4">
        <img
          src={image}
          alt={tag}
          className="block h-[91px] w-full pointer-events-none"
        />
      </div>

      {tag}
    </button>
  )
)

export default CategoryFilterItem
