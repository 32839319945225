import React, { ReactNode } from "react"
import assert from "assert"
import {
  Radio,
  RadioGroup as ReakitRadioGroup,
  useRadioState,
} from "reakit/Radio"
import { useField } from "formik"
import cx from "classnames"

type ValueContentTuple = [string, string | ReactNode]

interface RadioGroupTypes {
  valueContentTuples: ValueContentTuple[]
  name: string
  className?: string
  state?: string | number
}

export const RadioGroup = ({
  valueContentTuples, // [ ['choice_one', <>This is choice one</>], ... ]
  name, // `name` prop to use for all radios
  className,
  state,
}: RadioGroupTypes) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")
  const [field] = useField(name)
  const radio = useRadioState({ state: state })

  return (
    <ReakitRadioGroup
      state={state}
      className={cx("reakit-radiogroup", className)}
    >
      {valueContentTuples.map(([value, content]) => (
        <label key={value}>
          <Radio {...field} {...radio} name={name} value={value} />
          <span className="flex grow pl-2">{content}</span>
        </label>
      ))}
    </ReakitRadioGroup>
  )
}
