import { ReactNode } from "react"

import { WrappedInput } from "../../components/Input"
import { Checkbox2 } from "../../components/Checkbox"
import { ChoiceType } from "@/types"

interface EligibilityInterestModalFormInputTypes {
  attestationData: any
}

type ComponentType = "bool" | "str" | "float" | "int" | "multiple_choice"

type ComponentsTypes = Record<ComponentType, ReactNode>

const EligibilityInterestModalFormInput = ({
  attestationData,
}: EligibilityInterestModalFormInputTypes) => {
  const components: ComponentsTypes = {
    bool: (
      <div className="col-span-2">
        <Checkbox2
          name={attestationData.attestation_id}
          label={attestationData.attestation}
          className="text-sm font-bold leading-18 text-charcoal-500 mb-0"
        />
      </div>
    ),
    str: (
      <div className="col-span-2">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500"
          aria-label={attestationData.attestation}
          name={attestationData.attestation_id}
          as="textarea"
          className="mt-2 resize-none border-charcoal-50"
          maxLength={500}
        />
      </div>
    ),
    float: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          type="number"
          min="0"
          step="0.01"
          name={attestationData.attestation_id}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
    int: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          type="number"
          min="0"
          name={attestationData.attestation_id}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
    multiple_choice: (
      <div className="col-span-2 md:col-span-1 flex flex-col justify-between">
        <WrappedInput
          label={attestationData.attestation}
          labelClass="leading-18px text-charcoal-500 mb-auto"
          aria-label={attestationData.attestation}
          name={attestationData.attestation_id}
          as="select"
          placeholder="Select a value"
          options={attestationData?.choices?.map((choice: ChoiceType) => ({
            label: choice,
            value: choice,
          }))}
          className="mt-2 resize-none border-charcoal-50"
        />
      </div>
    ),
  }

  return (
    components[attestationData.response_type as ComponentType] || components.str
  )
}

export default EligibilityInterestModalFormInput
