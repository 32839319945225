import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { getRoiWithParams } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useUpdateRoi = <
  TData,
  TError,
  TVariables extends Record<string, string>,
>(
  queryClient: QueryClient,
  accountId: string,
  projectId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (queryParams: TVariables) =>
      getRoiWithParams(accountId, projectId, queryParams),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["accounts", accountId, "projects", projectId, "roi"],
          data
        )
      },
      ...config,
    }
  )
}
