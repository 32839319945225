import React from "react"

import NumberedListItem from "../../components/NumberedListItem"
import { AuthLayoutTypes } from "../../types/auth"
import logoIcon from "../../images/ncx-login-logo.png"

const AuthLayout = ({ form }: AuthLayoutTypes) => (
  <div className="flex flex-auto flex-col lg:flex-row">
    <div className="flex items-center justify-center w-full lg:w-1/2 bg-tree-img">
      <div className="max-w-[491px] text-white px-4 pt-16 pb-[74px] lg:pb-[82px]">
        <h1 className="text-xl lg:text-3xl leading-120 tracking-0.4 lg:tracking-0.9">
          Join a Community of 20,000+ Landowners and Find New Income Streams for
          Your Land
        </h1>

        <ol className="list-none mt-6 lg:mt-10 relative">
          <NumberedListItem
            heading="Add your property"
            text="No acreage minimums or fees required to add your land."
            className="before:content-['1'] mb-8"
          />

          <NumberedListItem
            heading="Get eligibility report"
            text="Answer questions about your land to see eligible programs in each category."
            className="before:content-['2'] mb-8"
          />

          <NumberedListItem
            heading="Browse programs for your land"
            text="Evaluate eligible programs to find new income streams."
            className="before:content-['3']"
          />
        </ol>
      </div>
    </div>
    <div className="flex flex-col items-center w-full lg:w-1/2 bg-white pt-6 pb-5 lg:pb-0 grow">
      <figure className="w-[440px] max-w-full lg:mt-auto">
        <img src={logoIcon} alt="NCX logo" className="h-[46px] block mx-auto" />
      </figure>

      <div className="w-[440px] max-w-full">{form}</div>

      <div
        className="hidden lg:block text-sm text-center text-gray-500 space-x-5 justify-center mb-12 mt-auto"
        data-test="terms-privacy"
      >
        <a
          className="link text-leaf"
          href="https://www.ncx.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>

        <a
          className="link text-leaf"
          href="https://www.ncx.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
)

export default AuthLayout
