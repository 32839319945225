import EMPTY from "../../images/empty-inbox.svg"

const EmptyInbox = () => (
  <div className="max-w-[342px] px-[43px] py-[78px] mx-auto">
    <img src={EMPTY} alt="empty" className="block w-[86px] h-[98px] mx-auto" />

    <h4 className="text-base text-center font-semibold leading-130 tracking 0.32 mt-6">
      Your inbox is as quiet as a forest at midnight
    </h4>

    <p className="text-base text-center leading-130 tracking 0.32 mt-6">
      When your first message arrives, it'll show up here.
    </p>
  </div>
)

export default EmptyInbox
