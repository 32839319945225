import { useMemo } from "react"
import {
  IndexDataScenarioType,
  IndexDataType,
  IndexType,
} from "@/types/property"

const getAllYears = (indexData: IndexDataType) => {
  const years: number[] = []

  Object.values(indexData).forEach((index: IndexType) => {
    years.push(...index.history.map((d: [number, number]) => d[0]))

    Object.values(index.scenario).forEach((scenario: [number, number][]) => {
      years.push(...scenario.map((d: [number, number]) => d[0]))
    })
  })

  return years
}

export function useChartData(
  indexData: IndexDataType,
  selectedScenario: IndexDataScenarioType
) {
  const mergeDataByYear = useMemo(
    () => (history: [number, number][], scenario: [number, number][]) => {
      const lastHistoryItem = history[history.length - 1]
      const extendedScenario = [lastHistoryItem, ...scenario]

      const allYearsSet = new Set([
        ...history.map((item) => item[0]),
        ...extendedScenario.map((item) => item[0]),
      ])
      const allYears = Array.from(allYearsSet).sort()

      return allYears.map((year) => {
        const historyData = history.find((item) => item[0] === year)
        const scenarioData = extendedScenario.find((item) => item[0] === year)

        return {
          year,
          historyValue: historyData ? historyData[1] : null,
          scenarioValue: scenarioData ? scenarioData[1] : null,
        }
      })
    },
    []
  )

  const chartData = useMemo(() => {
    const timberIndex = indexData.timber_index
    const forestHealthIndex = indexData.forest_health_index
    const carbonIndex = indexData.carbon_index

    const timberData = mergeDataByYear(
      timberIndex.history,
      timberIndex.scenario[selectedScenario]
    )
    const forestHealthData = mergeDataByYear(
      forestHealthIndex.history,
      forestHealthIndex.scenario[selectedScenario]
    )
    const carbonData = mergeDataByYear(
      carbonIndex.history,
      carbonIndex.scenario[selectedScenario]
    )

    return timberData.map((item) => {
      const forestHealthItem = forestHealthData.find(
        (f) => f.year === item.year
      )
      const carbonItem = carbonData.find((c) => c.year === item.year)

      return {
        year: item.year,
        timberIndexHistory: item.historyValue,
        timberIndexScenario: item.scenarioValue,
        forestHealthHistory: forestHealthItem?.historyValue,
        forestHealthScenario: forestHealthItem?.scenarioValue,
        carbonIndexHistory: carbonItem?.historyValue,
        carbonIndexScenario: carbonItem?.scenarioValue,
      }
    })
  }, [indexData, selectedScenario, mergeDataByYear])

  return chartData
}

export function useYearTicks(indexData: IndexDataType) {
  const { minYear, maxYear } = useMemo(() => {
    const years = getAllYears(indexData)

    return {
      minYear: Math.min(...years),
      maxYear: Math.max(...years),
    }
  }, [indexData])

  const ticks = useMemo(() => {
    const decadeTicks: number[] = []

    const startTick = Math.ceil(minYear / 10) * 10
    const endTick = Math.floor(maxYear / 10) * 10

    for (let year = startTick; year <= endTick; year += 10) {
      decadeTicks.push(year)
    }

    return decadeTicks
  }, [minYear, maxYear])

  return { minYear, maxYear, ticks }
}
