import { useDialogState } from "reakit/Dialog"

import HelperCard from "../../../components/HelperCard"
import { ActiveTabType } from "../Land"
import LossDetectionText from "./LossDetection/LossDetectionText"
import { ExplanatoryText } from "../../LandCoverDetails/ExplanatoryText"
import LandCoverClassesModal from "../../LandCoverDetails/LandCoverClassesModal"

interface LandTextProps {
  activeTab: ActiveTabType
}

const LandText = ({ activeTab }: LandTextProps) => {
  const landCoverDialog = useDialogState({ animated: true })

  if (activeTab === "land-cover") {
    return (
      <>
        <ExplanatoryText onClick={landCoverDialog.show} />

        <LandCoverClassesModal dialog={landCoverDialog} />
      </>
    )
  }

  return (
    <>
      <LossDetectionText />

      <HelperCard className="mt-4">
        Looking for loss events that may have occurred 1 month prior to the
        detection date? Request historical loss detection data by submitting a
        request to{" "}
        <a
          className="link"
          href="mailto:landowners@ncx.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          landowners@ncx.com
        </a>
        .
      </HelperCard>
    </>
  )
}

export default LandText
