import { Checkbox2 } from "../../components/Checkbox"
import { WrappedInput } from "../../components/Input"
import { RadioGroup } from "../../components/RadioGroup"
import { QuizAttestationType } from "@/types/eligibilityQuiz"

interface EligibilityQuizFormInputTypes {
  attestation: QuizAttestationType
}

const EligibilityQuizFormInput = ({
  attestation,
}: EligibilityQuizFormInputTypes) => {
  const components = {
    bool: (
      <Checkbox2
        name={attestation.id.toString()}
        className="text-sm font-bold leading-18 text-charcoal-500 mb-0 mt-8"
      />
    ),
    str: (
      <WrappedInput
        name={attestation.id.toString()}
        as="textarea"
        className="resize-none border-charcoal-50 mt-8"
        maxLength={500}
      />
    ),
    float: (
      <WrappedInput
        type="number"
        min="0"
        step="0.01"
        name={attestation.id.toString()}
        className="resize-none border-charcoal-50 mt-8"
      />
    ),
    int: (
      <WrappedInput
        type="number"
        min="0"
        name={attestation.id.toString()}
        className="resize-none border-charcoal-50 mt-8"
      />
    ),
    multiple_choice: (
      <RadioGroup
        name={attestation.id.toString()}
        className="space-y-1 md:space-y-2 mt-8"
        valueContentTuples={attestation.choices.map((choice) => [
          choice,
          choice,
        ])}
      />
    ),
  }

  return components[attestation.response_type] || components.str
}

export default EligibilityQuizFormInput
