import React from "react"

import AccountRow from "./AccountRow"
import { getAccountAcreage } from "../../utils"
import { ObjectListItemTypes } from "../../types/dashboardAccounts"

interface AccountRowTypes {
  filteredAccounts: ObjectListItemTypes[]
  searchTerm: string
}

const AccountRows = ({ filteredAccounts, searchTerm }: AccountRowTypes) => {
  if (filteredAccounts.length === 0) {
    return (
      <p className="text-center text-lg mt-8">
        No accounts matching "{searchTerm}".
      </p>
    )
  }

  return filteredAccounts.map((account) => (
    <div key={account.id}>
      <AccountRow
        id={account.id}
        name={account.account_name}
        totalAcres={getAccountAcreage(account)}
        accountProjectsToView={account.accountprojects_to_view}
      />
    </div>
  ))
}

export default AccountRows
