import { ReactNode } from "react"

import ProjectInterest from "../../images/project-interest.png"

interface ModalImageLayoutTypes {
  children: ReactNode
  heading: string
  img: string
}

const ModalImageLayout = ({
  children,
  heading,
  img,
}: ModalImageLayoutTypes) => (
  <div className="flex flex-col lg:max-w-1095 lg:flex-row-reverse md:justify-between gap-6">
    <div className="w-full lg:max-w-355 self-start">
      <img
        src={img || ProjectInterest}
        className="block object-cover aspect-video lg:aspect-auto w-full lg:w-355 lg:h-209"
        alt="trees-river"
      />
    </div>
    <div className="w-full space-y-p">
      <p className="text-overline">{heading}</p>
      {children}
    </div>
  </div>
)

export default ModalImageLayout
