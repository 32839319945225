import { ReactNode } from "react"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"

interface IconTextTypes {
  icon: IconDefinitionSvg
  children: ReactNode
  className: string
  iconClass: string
}

const IconText = ({ icon, children, className, iconClass }: IconTextTypes) => (
  <div className={cx("flex", className)}>
    <div
      className={cx(
        "inline-block w-6 leading-6 text-center shrink-0",
        iconClass
      )}
    >
      <FontAwesomeIcon icon={icon} />
    </div>{" "}
    <div>{children}</div>
  </div>
)

export default IconText
