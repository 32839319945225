import React from "react"
import {
  Popover as PopoverBase,
  PopoverDisclosure,
  PopoverArrow,
  PopoverProps,
} from "reakit/Popover"
import cx from "classnames"
import { ReactNode } from "react"

interface PopoverTypes {
  popover: PopoverProps
  label: string
  content?: ReactNode
  className?: string
  children: ReactNode
}

const Popover = ({
  popover,
  label = "Popover",
  className,
  children,
  content,
}: PopoverTypes) => (
  <>
    <PopoverDisclosure {...popover} className="popover-disclosure">
      {content || label}
    </PopoverDisclosure>
    {popover.visible ? (
      <PopoverBase
        {...popover}
        tabIndex={0}
        aria-label={label}
        className={cx("popover", className)}
      >
        <PopoverArrow {...popover} size={20} />
        {children}
      </PopoverBase>
    ) : null}
  </>
)

export default Popover
