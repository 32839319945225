import { Dispatch, SetStateAction } from "react"

import OnboardingForm from "./OnboardingForm"
import OnboardingReportLoading from "./OnboardingReportLoading"
import { ProgressBar } from "../../components/ProgressBar"
import useViewport from "../../hooks/useViewport"
import ONBOARDING from "../../images/onboarding-survey.webp"
import BLOB from "../../images/blob.png"
import DEER from "../../images/deer.gif"
import { AccountInterestTypes } from "@/types/accountInterest"

interface OnboardingStepsProps {
  onboardingStep: number
  setOnboardingStep: Dispatch<SetStateAction<number>>
  surveyCompleted: boolean
  setSurveyCompleted: Dispatch<SetStateAction<boolean>>
  progressStep: number
  maxSteps: number
  accountInterest?: AccountInterestTypes
  fromDashboard?: boolean
}

const OnboardingSteps = ({
  onboardingStep,
  setOnboardingStep,
  surveyCompleted,
  setSurveyCompleted,
  progressStep,
  maxSteps,
  accountInterest,
  fromDashboard,
}: OnboardingStepsProps) => {
  const { width } = useViewport()

  const isMobile = width < 768
  return (
    <>
      <ProgressBar
        className="onboarding-survey-progress-bar w-full h-5"
        value={progressStep}
        max={maxSteps}
      />
      <div className="pb-24 md:pb-0">
        <div className="flex justify-end px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]" />
        <div className="container xl:max-w-[1440px] flex justify-between gap-8">
          <div className="hidden md:block w-auto max-w-[528px]">
            {surveyCompleted ? (
              <div className="relative w-242 md:w-auto max-w-full">
                <img src={BLOB} alt="" />
                <img src={DEER} alt="deer" className="error-404-deer" />
              </div>
            ) : (
              <img
                src={ONBOARDING}
                alt="Ebony Tree Image"
                className="w-auto max-h-[480px]"
              />
            )}
          </div>

          <div className="md:w-[500px] max-w-full">
            {surveyCompleted ? (
              <OnboardingReportLoading isMobile={isMobile} />
            ) : (
              <OnboardingForm
                onboardingStep={onboardingStep}
                setOnboardingStep={setOnboardingStep}
                setSurveyCompleted={setSurveyCompleted}
                isMobile={isMobile}
                accountInterest={accountInterest}
                fromDashboard={fromDashboard}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OnboardingSteps
