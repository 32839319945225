import React, { ReactNode } from "react"

interface SignUpFooterTypes {
  secondaryInfo: ReactNode
}

const SignUpFooter = ({ secondaryInfo }: SignUpFooterTypes) => (
  <div className="text-center text-carbon text-sm">
    <p>
      Need help?{" "}
      <a
        className="link--underline-only text-sm text-dusk-500"
        href="mailto:landowners@ncx.com"
        data-test="having-trouble"
      >
        <span className="link">landowners@ncx.com</span>
      </a>
    </p>
    {secondaryInfo && <p className="mt-4">{secondaryInfo}</p>}
  </div>
)

export default SignUpFooter
