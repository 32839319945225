import {
  useState,
  useRef,
  useCallback,
  MouseEvent,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from "react"

interface UseDragResult {
  dragStart: (ev: MouseEvent) => void
  dragStop: () => void
  dragMove: (ev: MouseEvent, cb: (diff: number) => void) => void
  dragging: boolean
  position: MutableRefObject<number>
  setDragging: Dispatch<SetStateAction<boolean>>
}

function useDrag(): UseDragResult {
  const [clicked, setClicked] = useState<boolean>(false)
  const [dragging, setDragging] = useState<boolean>(false)
  const position = useRef<number>(0)

  const dragStart = useCallback((ev: MouseEvent) => {
    position.current = ev.clientX
    setClicked(true)
  }, [])

  const dragStop = useCallback(
    () =>
      // DEV: need some delay so item under cursor won't be clicked
      window.requestAnimationFrame(() => {
        setDragging(false)
        setClicked(false)
      }),
    []
  )

  const dragMove = (ev: MouseEvent, cb: (diff: number) => void) => {
    const newDiff = position.current - ev.clientX

    const movedEnough = Math.abs(newDiff) > 5

    if (clicked && movedEnough) {
      setDragging(true)
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX
      cb(newDiff)
    }
  }

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
  }
}

export default useDrag
