import cx from "classnames"

import { guidesBackground } from "../../../sections/EligibilityQuiz/helpers"
import { GuideType } from "./helpers"
import { EligibilityQuizCategoryType } from "@/types/constants"

interface GuidesBannerTypes {
  guide: GuideType
  category: EligibilityQuizCategoryType | undefined
}

const GuidesBanner = ({ guide, category }: GuidesBannerTypes) => {
  if (guide === null) {
    return null
  }

  const bgImage = guidesBackground(category)

  return (
    <a
      href={guide.url}
      className={cx(
        "guide-banner",
        "block w-full bg-center rounded shadow-banner p-4 md:px-12 md:py-6"
      )}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(36, 36, 38, 0.60) 0%, rgba(36, 36, 38, 0.60) 100%), url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 40%",
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <h3 className="text-white text-lg md:text-xl leading-130 md:leading-120 tracking-0.378 md:tracking-0.4">
        {guide.headline}
      </h3>

      <p className="text-white text-base leading-130 tracking 0.32 my-2">
        Get informed about different program categories with NCX guides.
      </p>

      <p className="text-west-side text-base font-bold leading-130 tracking-0.32">
        Read More
      </p>
    </a>
  )
}

export default GuidesBanner
