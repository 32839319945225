import {
  QueryClient,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import { isAuthenticationError } from "../../../api/auth"
import { AxiosError } from "axios"

export interface UseQueryWithRefreshConfig<TData, TError>
  extends Omit<UseQueryOptions<TData, TError>, "queryKey"> {
  queryKey?: QueryKey
  meta?: {
    accountId: string | undefined
  }
}

/**
 * Use this hook for queries that call a protected API
 * (those that use api.data.getProtected or api.data.postProtected)
 */
export function useQueryWithRefresh<TData, TError>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  queryFn: QueryFunction<TData>,
  config?: UseQueryWithRefreshConfig<TData, TError>
): UseQueryResult<TData, TError> {
  return useQuery<TData, TError>({
    queryKey,
    queryFn,
    ...config,
    // Hackishly use `retry` to catch/handle invalidation errors
    // DEV: By using `retry`, we never enter an "error" state for queries for auth errors
    //   (resolves `Main` layout occasionally showing big error with expired access token)
    // DEV: Simply invalidating `refresh` + returning false also seems to trigger error state
    //   Instead, we need to invalidate + coax `react-query` to try requesting again
    //   In that setup, React will interject with a new render in `App.js` + fetch new token
    //   thus cancelling this request and replacing it with a new one, once we have a new token
    retry: (failureCount, error) => {
      if (isAuthenticationError(error as AxiosError)) {
        // Fail-safe: If we've already invalidated + are retrying now, stop it early (should never get here)
        if (failureCount >= 1) {
          return false
        }
        queryClient.invalidateQueries({ queryKey: ["refresh"] })
        return true
      }
      return false
    },
  })
}
