import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getParcelGeoms } from "../../../api/data"

export const useGetParcelGeoms = <TData, TError>(
  accountId: string,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "parcel-geoms"],
    queryFn: async () => getParcelGeoms(accountId),
    ...config,
  })
}
