import React from "react"
import { Link } from "react-router-dom"
import { Notification } from "../../components/Notification"
import { useAccountUrlPrefix } from "../../hooks"

interface CompleteSurveyNotificationTypes {
  onDismiss?: () => void
}

const CompleteSurveyNotification = ({
  onDismiss,
}: CompleteSurveyNotificationTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <Notification
      title="New feature!"
      variant="warning"
      className="md:pl-12"
      onDismiss={onDismiss}
    >
      <p className="leading-[140%] tracking-0.32 mt-4">
        Share your goals to receive personalized recommendations.
      </p>

      <Link
        to={`${accountUrlPrefix}/onboarding/goals`}
        state={{ fromDashboard: true }}
        className="dashboard-complete-goals btn2 btn2-primary font-semibold mt-4"
      >
        {" "}
        Add Land Goals
      </Link>
    </Notification>
  )
}

export default CompleteSurveyNotification
