import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useAccountQueryWithRefresh } from "../utils"
import { getAccountInterest } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useAccountInterest<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "account-interest"],
    () => getAccountInterest(accountId),
    config
  )
}
