import difference from "@turf/difference"
import booleanIntersects from "@turf/boolean-intersects"
import {
  Feature,
  Geometry,
  GeometryCollection,
  MultiPolygon,
  Polygon,
  Properties,
} from "@turf/helpers"

import { EMPTY_FEATURE } from "./constants"
import { FeatureType } from "@/types"

export function isPolygonOrMultiPolygon(
  feature: FeatureType
): feature is Feature<Polygon | MultiPolygon, Properties> {
  return (
    feature.geometry.type === "Polygon" ||
    feature.geometry.type === "MultiPolygon"
  )
}

export function clipSelectedParcelsFromExistingFeatures({
  features,
  parcelFeatures,
}: {
  features: FeatureType[]
  parcelFeatures: FeatureType[]
}): FeatureType[] {
  return features.map((feature) => {
    let clippedFeature: Feature<
      Geometry | GeometryCollection,
      Properties
    > | null = feature

    for (const parcelFeature of parcelFeatures) {
      if (
        clippedFeature &&
        isPolygonOrMultiPolygon(clippedFeature) &&
        isPolygonOrMultiPolygon(parcelFeature)
      ) {
        if (booleanIntersects(clippedFeature, parcelFeature)) {
          clippedFeature = difference(clippedFeature, parcelFeature) as Feature<
            Geometry | GeometryCollection,
            Properties
          > | null
        }
      }
    }

    if (clippedFeature === null) {
      clippedFeature = { ...EMPTY_FEATURE, id: feature.id }
    } else {
      clippedFeature.id = feature.id
    }

    return clippedFeature
  })
}

// https://github.com/mapbox/mapbox-gl-draw/blob/v1.2.0/docs/MODES.md
// Define a custom file upload mode so that we can control the isActive
// status of the File Upload MapMainMenuButton
export const FileUploadMode = {
  onSetup: function (_opts: any) {
    return {}
  },
  toDisplayFeatures: function (
    _opts: any,
    geojson: any,
    display: (geojson: any) => void
  ) {
    display(geojson)
  },
}
