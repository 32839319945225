import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"
import { DialogStateReturn } from "reakit"

import AcceptModalForm from "./AcceptModalForm"
import { Modal } from "../../components/Modal"
import { Toast } from "../../components/Toast"
import {
  LANDOWNER_STATUS,
  phoneRegex,
  PREFERRED_CONTACT_METHOD,
  PHONE_NUMBER,
} from "../../shared/constants"
import {
  useUpdateAccountProject,
  useUpdateProfile,
  useViewNotification,
} from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { BadgeDisplayType } from "@/types/constants"
import { Profile } from "@/types"

interface AcceptModalTypes {
  dialog: DialogStateReturn
  attestationsDialog: DialogStateReturn
  accountId: string
  projectId: string
  sender: string | undefined
  badgeDisplay: BadgeDisplayType | undefined
  profile: Profile | undefined
  notificationId?: string | number
}

const validationSchema = (isValidPhoneNumber: boolean) => {
  return yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methodsArray, schema) => {
        // methodsArray is an array of 1 array strings
        const methods = [...methodsArray[0]]
        return !isValidPhoneNumber &&
          (methods.includes("phone") || methods.includes("text"))
          ? yup
              .string()
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
  })
}

const AcceptModal = ({
  dialog,
  attestationsDialog,
  accountId,
  projectId,
  sender,
  badgeDisplay,
  profile,
  notificationId,
}: AcceptModalTypes) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient)
  const { mutateAsync: viewNotification } = useViewNotification(
    queryClient,
    accountId,
    {}
  )
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })
        Toast.success(`You accepted an invitation from ${sender}.`)
        if (notificationId) {
          viewNotification(notificationId.toString())
        }
      },
      onError: (error: { message: string }) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const handleFormSubmit = async (values: FormikValues) => {
    const newValues = {
      preferred_contact_method: values.preferred_contact_method,
      ...(values.preferred_contact_method.includes("phone") ||
      values.preferred_contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
    }

    await updateProfile(newValues, {
      onSuccess: () => {
        const handleUpdateAccountProject = async () => {
          await updateAccountProject({
            status: LANDOWNER_STATUS.request_information,
          })
        }

        if (badgeDisplay === LANDOWNER_STATUS.information_needed) {
          dialog.hide()
          attestationsDialog.show()
        } else {
          handleUpdateAccountProject()
        }
      },
    })
  }

  const isValidPhoneNumber: boolean = phoneRegex.test(
    profile?.phone_number as string
  )

  return (
    <Modal
      header="Accept invitation?"
      aria-label="Accept invitation?"
      className="max-w-[400px]"
      dialog={dialog}
    >
      <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mb-4">
        If you accept this invite to connect, we'll share the contact
        information below with the program partner and they'll reach out to you
        to talk more about their program and your land goals.
      </p>

      <Formik
        initialValues={{
          [PREFERRED_CONTACT_METHOD]: profile?.preferred_contact_method || [],
          [PHONE_NUMBER]: profile?.phone_number || "",
        }}
        validationSchema={() => validationSchema(isValidPhoneNumber)}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <AcceptModalForm formikProps={formikProps} dialog={dialog} />
        )}
      </Formik>
    </Modal>
  )
}

export default AcceptModal
