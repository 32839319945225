import { Link } from "react-router-dom"
import cx from "classnames"

import { useAccountUrlPrefix } from "../hooks"

interface BecomeAMemberOverlayProps {
  show: boolean
  absolute?: boolean
  isSidebar?: boolean
}

const BecomeAMemberOverlay = ({
  show,
  absolute = false,
  isSidebar = false,
}: BecomeAMemberOverlayProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!show) {
    return null
  }

  return (
    <div
      className={cx(
        "flex flex-col-reverse w-full h-full z-[2] bg-dusk-500/75",
        absolute ? "absolute inset-0" : "relative"
      )}
    >
      <div className="w-full bg-sand flex flex-col items-center p-6">
        <h3
          className={cx(
            "text-charcoal-500 text-4xl font-medium leading-[124%] tracking-[0.72px]",
            isSidebar
              ? "text-[24px] sm:text-4xl lg:text-[24px]"
              : "text-[24px] sm:text-4xl"
          )}
        >
          Get full access to NCX
        </h3>

        <p className="text-autumn text-2xl font-medium leading-[124%] tracking-0.48 mt-3">
          Become a member today
        </p>

        <Link
          to={`${accountUrlPrefix}/become-a-member`}
          className="change-map-membership btn2 btn2-primary mt-4"
          state={{ scrollToTop: true }}
        >
          Learn More
        </Link>
      </div>
    </div>
  )
}

export default BecomeAMemberOverlay
