// will replace /login and /signup pages
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useNavigate, useLocation, Location } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { OptimizelyContext } from "@optimizely/react-sdk"

import AuthLayout from "../../pages/_layouts/Auth"
import EmailLoginForm from "./EmailLoginForm"
import GettingStartedForm from "./GettingStartedForm"
import ResetPasswordForm from "./ResetPasswordForm"
import SsoLogin from "./SsoLogin"
import SignUpForm from "./SignUpForm"
import VerifyEmail from "./VerifyEmail"
import { useProfile, useQueryParam } from "../../hooks"
import { resetUserForIntegrations } from "../../api/integrations"
import { AUTH_STEPS } from "./constants"
import { Profile } from "../../types"
import { AuthDataTypes } from "../../types/auth"

interface GettingStartedTypes {
  authData: AuthDataTypes
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
}

const AUTH_STEP_FORMS = {
  getStarted: GettingStartedForm,
  emailLogin: EmailLoginForm,
  ssoLogin: SsoLogin,
  signUp: SignUpForm,
  resetPassword: ResetPasswordForm,
}

const GettingStarted = ({ authData, setAuthData }: GettingStartedTypes) => {
  const queryClient = useQueryClient()
  const { optimizely } = useContext(OptimizelyContext)

  const navigate = useNavigate()
  const location = useLocation() as Location & {
    state?: {
      authStep?: keyof typeof AUTH_STEPS
      doesUserExist?: boolean
      from?: { pathname: string }
    }
  }
  const queryEmail = useQueryParam("email") as string
  const authStep: keyof typeof AUTH_STEP_FORMS =
    (location.state?.authStep as keyof typeof AUTH_STEP_FORMS) ||
    AUTH_STEPS.getStarted

  const [inputEmail, setInputEmail] = useState<string>(queryEmail)
  const [isUserCreated, setIsUserCreated] = useState<boolean>(
    location.state?.doesUserExist as boolean
  )

  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!isUserCreated,
    refetchInterval: 1000,
  })

  // Delete HubSpot cookies so that this page view is not associated with a particular contact.
  // We could do this on explicit logout, but in the case where a user doesn't allow cookies on their
  // browser they will be redirected to the Login page on every refresh without logging out explicitly.
  useEffect(() => {
    if (optimizely) {
      resetUserForIntegrations(optimizely)
    }
    if (profile?.is_email_verified) {
      navigate((location?.state?.from?.pathname as string) || "/onboarding")
    }
  }, [profile, navigate, location, optimizely])

  const FormComponent = authStep
    ? AUTH_STEP_FORMS[authStep]
    : AUTH_STEP_FORMS.getStarted

  return (
    <AuthLayout
      form={
        isUserCreated ? (
          <VerifyEmail />
        ) : (
          <FormComponent
            authData={authData}
            setAuthData={setAuthData}
            setIsUserCreated={setIsUserCreated}
            inputEmail={inputEmail}
            setInputEmail={setInputEmail}
          />
        )
      }
    />
  )
}
export default GettingStarted
