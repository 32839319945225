import { Dispatch, SetStateAction } from "react"
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

import MessageThreadContent from "./MessageThreadContent"
import { Profile } from "@/types"
import { NotificationTypes } from "@/types/notifications"
import { ActiveViewType } from "@/pages/Inbox"
import { AccountTypes } from "@/types/account"
import { AttestationsType } from "@/types/attestations"
import { QueryStatus } from "@tanstack/react-query"

interface MessagesThreadTypes {
  width: number
  profile: Profile | undefined
  messages: NotificationTypes[] | undefined
  activeView: ActiveViewType
  setActiveView: Dispatch<SetStateAction<ActiveViewType>>
  activeTab: string | number | null
  activeThread: NotificationTypes | undefined
  account: AccountTypes | undefined
  attestationsData: AttestationsType | undefined
  attestationsStatus: QueryStatus
}

const MessagesThread = ({
  width,
  profile,
  messages,
  activeView,
  setActiveView,
  activeTab,
  activeThread,
  account,
  attestationsData,
  attestationsStatus,
}: MessagesThreadTypes) => (
  <div
    className={cx(
      "w-full lg:w-[600px] block border lg:border-l-0 border-dusk-50",
      {
        "hidden lg:block": activeView === "tabs",
      }
    )}
  >
    <div className="h-14 flex items-center gap-4 px-4 lg:px-6">
      {width < 1025 ? (
        <button
          type="button"
          onClick={() => setActiveView("tabs")}
          className="w-6 h-6"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      ) : null}

      <span className="text-leaf text-base font-bold leading-130 tracking-0.32">
        {activeThread?.extra_fields?.project_name}
      </span>
    </div>

    <div className="h-[484px] overflow-auto border-t-1 border-dusk-50">
      <MessageThreadContent
        activeThread={messages?.find((message) => message.id === activeTab)}
        profile={profile}
        account={account}
        attestationsData={attestationsData}
        attestationsStatus={attestationsStatus}
      />
    </div>
  </div>
)

export default MessagesThread
