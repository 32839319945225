import { Link } from "react-router-dom"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../../hooks"
import BG_IMG from "../../../images/book-consultation.jfif"

interface BookConsultationBannerTypes {
  show: boolean
}

const BookConsultationBanner = ({ show }: BookConsultationBannerTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!show) {
    return null
  }

  return (
    <Link
      to={`${accountUrlPrefix}/advisor-signup`}
      className={cx(
        "book-consult-banner",
        "block w-full bg-center rounded shadow-banner p-4 md:px-12 md:py-6"
      )}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(36, 36, 38, 0.60) 0%, rgba(36, 36, 38, 0.60) 100%), url(${BG_IMG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 8%",
      }}
    >
      <h3 className="text-white text-lg md:text-xl leading-130 md:leading-120 tracking-0.378 md:tracking-0.4">
        Book a Consult
      </h3>

      <p className="text-white text-base leading-130 tracking 0.32 my-2">
        Need help figuring out what program is right for you? Talk to an NCX
        expert.
      </p>

      <p className="text-west-side text-base font-bold leading-130 tracking-0.32">
        Learn More
      </p>
    </Link>
  )
}

export default BookConsultationBanner
