import { findKey } from "lodash"

import ExpandableContainer from "../../components/ExpandableContainer"
import QuizCard from "./QuizCard"
import { allProgramTypes } from "./helpers"
import {
  ProgramCategoryType,
  EligibilityQuizCategoryType,
} from "../../types/constants"
import { EligibilityQuizzes } from "../../types/eligibility"
import useViewport from "../../hooks/useViewport"

interface OnboardingProjectQuizCardsProps {
  recommendedProgramTypes: ProgramCategoryType[]
  quizzesData: EligibilityQuizzes
}

interface QuizCardData {
  quizCategory: EligibilityQuizCategoryType
  projectType: ProgramCategoryType
  recommended: boolean
}

const OnboardingProjectQuizCards = ({
  recommendedProgramTypes,
  quizzesData,
}: OnboardingProjectQuizCardsProps) => {
  const { width } = useViewport()

  // DEV: containerHeight is used to determine the height of the unexpanded card container
  // based on the width of the viewport, which corresponds to the grid layout.
  const containerHeight = width < 1024 ? 510 : width >= 1280 ? 170 : 340

  const quizCardData: QuizCardData[] = allProgramTypes
    .map((type) => {
      return {
        quizCategory: findKey(
          quizzesData,
          (d) => d.project_type === type
        ) as EligibilityQuizCategoryType,
        projectType: type,
        recommended: recommendedProgramTypes.includes(type),
      }
    })
    .filter((d) => Boolean(d.quizCategory))
    .sort((a, b) => {
      return a.recommended === b.recommended ? 0 : a.recommended ? -1 : 1
    })

  const cardComponents = quizCardData.map(
    (cardData) =>
      cardData.quizCategory && (
        <QuizCard
          key={cardData.projectType}
          quizCategory={cardData.quizCategory}
          projectType={cardData.projectType}
          recommended={cardData.recommended}
        />
      )
  )

  return (
    <div className="onboarding-quiz-cards">
      {recommendedProgramTypes.length === 0 ? (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {cardComponents}
        </div>
      ) : (
        <div>
          <ExpandableContainer
            id="quiz-cards"
            btnTextOpen="View all categories"
            btnTextClose="View fewer categories"
            containerHeight={containerHeight}
            className="onboarding-quiz-cards-container"
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 ">
              {cardComponents}
            </div>
          </ExpandableContainer>
        </div>
      )}
    </div>
  )
}

export default OnboardingProjectQuizCards
