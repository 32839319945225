import React from "react"
import { Link } from "react-router-dom"

import {
  faList,
  faHourglassEnd,
  faClock,
} from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { ProjectBadge } from "./ProjectBadge"
import ProjectVerificationBadge from "./ProjectVerificationBadge"
import IconText from "./IconText"
import {
  getEnrollmentDeadline,
  getProjectType,
  getProjectBadgeType,
  getProjectBadgeText,
  getProjectEarningsNullValue,
} from "../shared/utils"
import { formatCurrency } from "../utils"
import { useAccountUrlPrefix } from "../hooks"
import { ProjectBadgeType } from "../types/constants"
import { faDollarSign } from "@fortawesome/pro-duotone-svg-icons"
import { ProjectDataTypes } from "@/types/program"

interface ProjectCardTypes {
  className?: string
  programData: ProjectDataTypes
  showMinimalInfo?: boolean
  onClick?: () => void
}

const ProjectCard = ({
  className,
  programData,
  showMinimalInfo = false,
  onClick,
}: ProjectCardTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const badgeType = getProjectBadgeType(programData.badge_display)
  const badgeText = getProjectBadgeText(programData.badge_display)
  const projectType = getProjectType(programData.type, "short")
  const enrollmentDeadline = getEnrollmentDeadline(
    programData.enrollment_deadline_type,
    programData.enrollment_deadline_date
  )

  return (
    <div className="relative flex">
      {!showMinimalInfo ? (
        <div className="verification-badge-card">
          <ProjectVerificationBadge
            isVerified={programData.is_verified}
            includeLabel={false}
          />
        </div>
      ) : null}

      <Link
        to={`${accountUrlPrefix}/programs/${programData.id}`}
        className={cx("project-card min-w-full", className)}
        aria-label={programData.name}
        onClick={onClick}
      >
        {!showMinimalInfo ? (
          <div className="mb-3">
            <ProjectBadge type={badgeType as ProjectBadgeType} size="sm">
              {badgeText}
            </ProjectBadge>
          </div>
        ) : null}

        <div className="mb-3">
          <img
            src={programData.image_url}
            alt=""
            className={cx("w-full h-121 sm:h-222 object-cover rounded", {
              grayscale: !programData.is_verified,
            })}
          />
        </div>

        <div className="line-clamp-2 mb-2">
          <h3 className="font-semibold text-lg leading-130 mb-0">
            {programData.name}
          </h3>
        </div>

        {!showMinimalInfo ? (
          <div className="line-clamp-2 mb-3.5">
            <p className="text-base leading-130 mb-0">
              {programData.description_short}
            </p>
          </div>
        ) : null}

        <div
          className={cx(
            "grid grid-cols-2-max-content gap-2 mt-auto mb-0",
            showMinimalInfo ? "grid-rows-1" : "grid-rows-2"
          )}
        >
          <IconText
            icon={faList}
            className="text-sm text-dusk-700 leading-6"
            iconClass="text-base mr-1"
          >
            {projectType}
          </IconText>
          <IconText
            icon={faHourglassEnd}
            className="text-sm text-dusk-700 leading-6"
            iconClass="text-base mr-1"
          >
            {enrollmentDeadline}
          </IconText>

          {!showMinimalInfo ? (
            <IconText
              icon={faClock}
              className="text-sm text-dusk-700 leading-6"
              iconClass="text-base mr-1"
            >
              {programData.term === null
                ? "Term length varies"
                : `${programData.term} year term`}
            </IconText>
          ) : null}
          {!showMinimalInfo ? (
            <IconText
              icon={faDollarSign}
              className="text-sm text-dusk-700 leading-6"
              iconClass="text-base mr-1"
            >
              {programData.potential_earnings === null ||
              programData.potential_earnings === undefined
                ? getProjectEarningsNullValue(programData.type)
                : `${formatCurrency(programData.potential_earnings)}/acre`}
            </IconText>
          ) : null}
        </div>
      </Link>
    </div>
  )
}

export default ProjectCard
