import { useQueryClient } from "@tanstack/react-query"

import { Modal } from "../../components/Modal"
import { Toast } from "../../components/Toast"
import { useUpdateAccountProject } from "../../hooks"
import { AxiosError } from "axios"
import { DialogStateReturn } from "reakit"

interface IneligibleModalTypes {
  dialog: DialogStateReturn
  accountId: string
  projectId: string
  onClose?: () => void
}

const IneligibleModal = ({
  dialog,
  accountId,
  projectId,
  onClose,
}: IneligibleModalTypes) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success(
          "We've received your request for more information. We'll be in touch soon!"
        )
        onClose && onClose()
      },
      onError: (error) => {
        const err = error as AxiosError
        dialog.hide()
        Toast.error(
          err?.message || "An error occurred while updating your preferences."
        )
      },
    }
  )

  const handleSubmit = async () => {
    const newValues = {
      status: "request_information",
    }
    await updateAccountProject(newValues)
  }

  return (
    <Modal
      header="Thanks for your interest!"
      aria-label="Ineligible dialog"
      dialog={dialog}
      className="p-5"
    >
      <p className="mb-4">
        You are not eligible for this program based on your land details, but
        your interest will help us to find similar opportunities for you.
      </p>
      <button
        className="btn2 btn2-primary font-semibold"
        type="button"
        onClick={() => {
          handleSubmit()
        }}
      >
        Got It
      </button>
    </Modal>
  )
}

export default IneligibleModal
