import { useQuery } from "@tanstack/react-query"

import { establishMembership } from "../../../api/auth"

export function useCreateMembership(
  accountId: string,
  successToken: string,
  config = {}
) {
  return useQuery({
    queryKey: ["establishMembership"],
    queryFn: () => establishMembership(accountId, successToken),
    ...config,
  })
}
