import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import {
  useMutationWithRefresh,
  UseMutationWithRefreshConfig,
} from "../utils/useMutationWithRefresh"
import { unmarkAnswerHelpful } from "../../../api/data"
import { QuestionsTypes } from "@/types/questions"

interface MutationContext {
  updatedQuestionsData: QuestionsTypes
}

export const useUnmarkAnswerHelpful = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  projectId: string,
  questionId: number,
  answerId: number,
  config: UseMutationWithRefreshConfig<
    TData,
    TError,
    TVariables,
    MutationContext
  > = {}
): UseMutationResult<TData, TError, TVariables, MutationContext> => {
  return useMutationWithRefresh<TData, TError, TVariables, MutationContext>(
    queryClient,
    () =>
      unmarkAnswerHelpful(
        projectId,
        questionId.toString(),
        answerId.toString()
      ),
    {
      onMutate: () => {
        // DEV: Get the current questions data from the cache
        const questionsData = queryClient.getQueryData([
          "projects",
          projectId,
          "questions",
        ]) as QuestionsTypes

        // DEV: Find the question and answer in the data
        const updatedQuestionsData = questionsData.map((question) => {
          if (question.id === questionId) {
            const updatedAnswers = question.answers.map((answer) => {
              if (answer.id === answerId) {
                // DEV: Update the user_marked_helpful and helpful_count properties
                return {
                  ...answer,
                  user_marked_helpful: !answer.user_marked_helpful,
                  helpful_count: answer.helpful_count - 1,
                }
              }
              return answer
            })
            return {
              ...question,
              answers: updatedAnswers,
            }
          }
          return question
        })

        // DEV: Update the cached data with the modified data
        queryClient.setQueryData(
          ["projects", projectId, "questions"],
          updatedQuestionsData
        )

        return { updatedQuestionsData }
      },
      onError: (_error, _variables, context) => {
        // DEV: Revert the cache to the previous data in case of an error
        if (context?.updatedQuestionsData) {
          queryClient.setQueryData(
            ["projects", projectId, "questions"],
            context.updatedQuestionsData
          )
        }
      },
      ...config,
    }
  )
}
