import { QueryClient, QueryKey, UseQueryResult } from "@tanstack/react-query"
import assert from "assert"

import {
  useQueryWithRefresh,
  UseQueryWithRefreshConfig,
} from "./useQueryWithRefresh"

export function useAccountQueryWithRefresh<TData, TError>(
  queryClient: QueryClient,
  queryKey: QueryKey,
  func: () => Promise<TData>,
  config?: UseQueryWithRefreshConfig<TData, TError>
): UseQueryResult<TData, TError> {
  assert(
    queryKey.length >= 2 && queryKey[0] === "accounts",
    "`useAccountQueryWithRefresh` received non-account queryKey"
  )
  const accountId = queryKey[1]

  return useQueryWithRefresh<TData, TError>(queryClient, queryKey, func, {
    ...config,
    meta: {
      accountId: accountId,
    },
  } as UseQueryWithRefreshConfig<TData, TError>)
}
