import { createContext, Dispatch } from "react"
import {
  ColumnFiltersState,
  FilterFnOption,
  SortingState,
} from "@tanstack/react-table"

import { QUALIFIED_LEADS, ELIGIBLE_LEADS } from "../shared/constants"
import { EligibleLeadTypes, RowsType, RowType } from "../types/partners"
import { FILTER_FNS } from "../sections/Partners/PartnersTable/constants"
import { ViewportTypes } from "@/types"

export type QualifiedFilterFNSType = FilterFnOption<RowsType> | undefined
export type EligibleFilterFNSType =
  | FilterFnOption<EligibleLeadTypes[]>
  | undefined

export interface QualifiedLeadFilterFunctionsTypes {
  project_name: QualifiedFilterFNSType
  deal_owner: QualifiedFilterFNSType
  partner_name: QualifiedFilterFNSType
  id: QualifiedFilterFNSType
  status: QualifiedFilterFNSType
  qualified_status: QualifiedFilterFNSType
  first_name: QualifiedFilterFNSType
  last_name: QualifiedFilterFNSType
  primary_property_state: QualifiedFilterFNSType
  acreage: QualifiedFilterFNSType
  eligible_acres: QualifiedFilterFNSType
  legal_entity_name: QualifiedFilterFNSType
  email: QualifiedFilterFNSType
  phone_number: QualifiedFilterFNSType
  interest_date: QualifiedFilterFNSType
  county: QualifiedFilterFNSType
}

export interface EligibleLeadFilterFunctionsTypes {
  project_name: EligibleFilterFNSType
  id: EligibleFilterFNSType
  status: EligibleFilterFNSType
  // DEV: Temporarily disable filtering by qualified_status
  // qualified_status: EligibleFilterFNSType
  first_name: EligibleFilterFNSType
  last_name: EligibleFilterFNSType
  primary_property_state: EligibleFilterFNSType
  acreage: EligibleFilterFNSType
  eligible_acres: EligibleFilterFNSType
  email: EligibleFilterFNSType
  phone_number: EligibleFilterFNSType
  interest_date: EligibleFilterFNSType
}

export interface StateTypes {
  activeRow: RowType | EligibleLeadTypes | null
  activeTab: typeof QUALIFIED_LEADS | typeof ELIGIBLE_LEADS
  eligibleActiveRowUnlocked: boolean
  viewport: ViewportTypes | null
  dealOwnerRowId: number | null
  eligibleLeadDataLoaded: boolean
  qualifiedLeadSorting: SortingState
  qualifiedLeadColumnFilters: ColumnFiltersState
  qualifiedLeadFilters: QualifiedLeadColumnType[]
  qualifiedLeadFilterFunctions: QualifiedLeadFilterFunctionsTypes
  eligibleLeadSorting: SortingState
  eligibleLeadColumnFilters: ColumnFiltersState
  eligibleLeadFilterFunctions: EligibleLeadFilterFunctionsTypes
  eligibleLeadFilters: EligibleLeadColumnType[]
}

export type QualifiedLeadColumnType =
  | "project_name"
  | "deal_owner"
  | "partner_name"
  | "id"
  | "status"
  | "qualified_status"
  | "first_name"
  | "last_name"
  | "primary_property_state"
  | "acreage"
  | "eligible_acres"
  | "legal_entity_name"
  | "email"
  | "phone_number"
  | "interest_date"
  | "county"

export type EligibleLeadColumnType =
  | "project_name"
  | "id"
  | "status"
  | "qualified_status"
  | "first_name"
  | "last_name"
  | "primary_property_state"
  | "acreage"
  | "eligible_acres"
  | "email"
  | "phone_number"
  | "interest_date"

export const PARTNERS_ACTIONS = {
  setActiveRow: "setActiveRow",
  setActiveTab: "setActiveTab",
  setEligibleActiveRowUnlocked: "setEligibleActiveRowUnlocked",
  setViewport: "setViewport",
  setDealOwnerRowId: "setDealOwnerRowId",
  setEligibleLeadDataLoaded: "setEligibleLeadDataLoaded",
  setQualifiedLeadSorting: "setQualifiedLeadSorting",
  setQualifiedLeadColumnFilters: "setQualifiedLeadColumnFilters",
  setQualifiedLeadFilters: "setQualifiedLeadFilters",
  setQualifiedLeadFilterFunctions: "setQualifiedLeadFilterFunctions",
  setEligibleLeadSorting: "setEligibleLeadSorting",
  setEligibleLeadColumnFilters: "setEligibleLeadColumnFilters",
  setEligibleLeadFilters: "setEligibleLeadFilters",
  setEligibleLeadFilterFunctions: "setEligibleLeadFilterFunctions",
} as const

export type ActionType =
  | { type: typeof PARTNERS_ACTIONS.setActiveRow; payload: any }
  | { type: typeof PARTNERS_ACTIONS.setActiveTab; payload: string }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleActiveRowUnlocked
      payload: boolean
    }
  | { type: typeof PARTNERS_ACTIONS.setViewport; payload: any }
  | { type: typeof PARTNERS_ACTIONS.setDealOwnerRowId; payload: any }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleLeadDataLoaded
      payload: boolean
    }
  | {
      type: typeof PARTNERS_ACTIONS.setQualifiedLeadSorting
      payload: SortingState
    }
  | {
      type: typeof PARTNERS_ACTIONS.setQualifiedLeadColumnFilters
      payload: ColumnFiltersState
    }
  | {
      type: typeof PARTNERS_ACTIONS.setQualifiedLeadFilters
      payload: QualifiedLeadColumnType[]
    }
  | {
      type: typeof PARTNERS_ACTIONS.setQualifiedLeadFilterFunctions
      payload: QualifiedLeadFilterFunctionsTypes
    }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleLeadSorting
      payload: SortingState
    }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleLeadColumnFilters
      payload: ColumnFiltersState
    }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleLeadFilters
      payload: EligibleLeadColumnType[]
    }
  | {
      type: typeof PARTNERS_ACTIONS.setEligibleLeadFilterFunctions
      payload: EligibleLeadFilterFunctionsTypes
    }

const initialState: StateTypes = {
  activeRow: null,
  activeTab: QUALIFIED_LEADS,
  eligibleActiveRowUnlocked: false,
  viewport: null,
  dealOwnerRowId: null,
  eligibleLeadDataLoaded: false,
  qualifiedLeadColumnFilters: [],
  qualifiedLeadFilters: [],
  qualifiedLeadFilterFunctions: {
    project_name: FILTER_FNS.includesString as QualifiedFilterFNSType,
    deal_owner: FILTER_FNS.includesString as QualifiedFilterFNSType,
    partner_name: FILTER_FNS.includesString as QualifiedFilterFNSType,
    id: FILTER_FNS.lessOrEqual as QualifiedFilterFNSType,
    status: FILTER_FNS.includesString as QualifiedFilterFNSType,
    qualified_status: FILTER_FNS.includesString as QualifiedFilterFNSType,
    first_name: FILTER_FNS.includesString as QualifiedFilterFNSType,
    last_name: FILTER_FNS.includesString as QualifiedFilterFNSType,
    primary_property_state: FILTER_FNS.includesString as QualifiedFilterFNSType,
    acreage: FILTER_FNS.lessOrEqual as QualifiedFilterFNSType,
    eligible_acres: FILTER_FNS.lessOrEqual as QualifiedFilterFNSType,
    legal_entity_name: FILTER_FNS.includesString as QualifiedFilterFNSType,
    email: FILTER_FNS.includesString as QualifiedFilterFNSType,
    phone_number: FILTER_FNS.includesString as QualifiedFilterFNSType,
    interest_date: FILTER_FNS.isBefore as QualifiedFilterFNSType,
    county: FILTER_FNS.includesString as QualifiedFilterFNSType,
  },
  qualifiedLeadSorting: [{ id: "status", desc: true }],
  eligibleLeadColumnFilters: [],
  eligibleLeadFilters: [],
  eligibleLeadFilterFunctions: {
    project_name: FILTER_FNS.includesString as EligibleFilterFNSType,
    id: FILTER_FNS.lessOrEqual as EligibleFilterFNSType,
    status: FILTER_FNS.includesString as EligibleFilterFNSType,
    // DEV: Temporarily disable filtering by qualified_status
    // qualified_status: FILTER_FNS.includesString as EligibleFilterFNSType,
    first_name: FILTER_FNS.includesString as EligibleFilterFNSType,
    last_name: FILTER_FNS.includesString as EligibleFilterFNSType,
    primary_property_state: FILTER_FNS.includesString as EligibleFilterFNSType,
    acreage: FILTER_FNS.lessOrEqual as EligibleFilterFNSType,
    eligible_acres: FILTER_FNS.lessOrEqual as EligibleFilterFNSType,
    email: FILTER_FNS.includesString as EligibleFilterFNSType,
    phone_number: FILTER_FNS.includesString as EligibleFilterFNSType,
    interest_date: FILTER_FNS.isBefore as EligibleFilterFNSType,
  },
  eligibleLeadSorting: [{ id: "status", desc: true }],
}

const PartnersContext = createContext<{
  state: StateTypes
  dispatch: Dispatch<ActionType>
}>({
  state: initialState,
  dispatch: () => null,
})

export { initialState, PartnersContext }
