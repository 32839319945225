import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
  useEffect,
} from "react"

import MapGL, { Source, Layer } from "@urbica/react-map-gl"
import { bbox } from "@turf/turf"
import buffer from "@turf/buffer"

import ToggleSwitch from "../../../components/ToggleSwitch"
import { MAPBOX_TOKEN, mapStyles } from "../../../shared/constants"
import { useAccessToken } from "../../../stores"
import { TileURLsTypes } from "@/types/tiles"
import { ViewportTypes } from "@/types"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

interface EligibilityLayerTypes {
  showEligibilityLayer: boolean
  tileData?: TileURLsTypes
}

export const EligibilityLayer = ({
  showEligibilityLayer,
  tileData,
}: EligibilityLayerTypes) => {
  if (!showEligibilityLayer) {
    return null
  }

  if (!tileData?.url && tileData?.raster.min.length === 0) {
    return (
      <>
        <Source id="default" type="vector" url={tileData.vector} />
        <Layer
          id="default"
          source="default"
          source-layer="default"
          type="fill"
          paint={{
            "fill-color": "#3BB2D0",
            "fill-opacity": 0.8,
          }}
        />
      </>
    )
  }

  return (
    <>
      <Source
        id="default"
        type="raster"
        tiles={tileData?.url ? [tileData?.url] : tileData?.raster.min}
      />
      <Layer id="default" source="default" type="raster" />
    </>
  )
}

interface ProjectMapVisualizationTypes {
  viewport: ViewportTypes | null
  setViewport: Dispatch<SetStateAction<ViewportTypes | null>>
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  tileData?: TileURLsTypes
  activeFeature: number | null
  isIneligible: boolean
  enableEligibility?: boolean
  enableZoomPan?: boolean
  layerSwitchText?: string
  legend?: ReactNode
  overlay?: ReactNode
  isMember?: boolean
}

const ProjectMapVisualization = ({
  viewport,
  setViewport,
  parcelData,
  tileData,
  activeFeature,
  isIneligible,
  enableEligibility = true,
  enableZoomPan = false,
  layerSwitchText,
  legend,
  overlay,
  isMember,
}: ProjectMapVisualizationTypes) => {
  const [showEligibilityLayer, setShowEligibilityLayer] =
    useState<boolean>(enableEligibility)
  const accessToken = useAccessToken()

  useEffect(() => {
    setShowEligibilityLayer(enableEligibility)
  }, [enableEligibility])

  // DEV: Get bounding box around buffered area. 80.4672km = 50 miles
  const bounds = useMemo(() => {
    if (!parcelData) return null

    return bbox(
      // @ts-ignore
      buffer(parcelData, 80.4672, {
        units: "kilometers",
      })
    )
  }, [parcelData])

  if (viewport === null || viewport === undefined) {
    return null
  }

  return (
    <div tabIndex={isIneligible ? -1 : 0} className="w-full h-[400px] relative">
      <MapGL
        style={{ width: "100%", height: "400px" }}
        mapStyle={mapStyles.aerial.url}
        accessToken={MAPBOX_TOKEN}
        onViewportChange={(newViewport) => setViewport(newViewport)}
        viewportChangeMethod="flyTo"
        viewportChangeOptions={{
          duration: 1000,
        }}
        cursorStyle="default"
        scrollZoom={enableZoomPan}
        doubleClickZoom={enableZoomPan}
        dragPan={enableZoomPan}
        attributionControl={false}
        maxBounds={
          bounds
            ? [
                [bounds[0], bounds[1]],
                [bounds[2], bounds[3]],
              ]
            : undefined
        }
        transformRequest={(url) => {
          if (
            url.includes("tipg") ||
            url.includes("amazonaws.com/collections") ||
            url.includes("amazonaws.com/searches")
          ) {
            return {
              url: url,
              headers: { Authorization: `Bearer ${accessToken}` },
              credentials: "include",
            }
          }
          return { url }
        }}
        {...viewport}
      >
        <Source
          id="features"
          type="geojson"
          data={
            activeFeature !== null
              ? {
                  type: "FeatureCollection",
                  features: parcelData?.features,
                }
              : null
          }
        />
        <Layer
          id="featuresLayer"
          source="features"
          type="line"
          paint={{
            "line-color": "#77A0C3",
            "line-width": 2,
          }}
        />

        {isMember !== false ? (
          <EligibilityLayer
            showEligibilityLayer={showEligibilityLayer}
            tileData={tileData}
          />
        ) : null}

        {legend}

        {overlay}
      </MapGL>
      {enableEligibility && layerSwitchText && (
        <div className="absolute top-4 left-4 block bg-white border border-dusk-50 rounded px-2 py-[7px]">
          <ToggleSwitch
            label={
              <>
                Layer: <span className="font-normal">{layerSwitchText}</span>
              </>
            }
            value={showEligibilityLayer}
            onChange={() => setShowEligibilityLayer((oldValue) => !oldValue)}
          />
        </div>
      )}
    </div>
  )
}

export default ProjectMapVisualization
