import { FeatureType } from "@/types"

export const EMPTY_FEATURE: FeatureType = {
  type: "Feature",
  geometry: {
    type: "Point",
    coordinates: [],
  },
  properties: {},
}

export const WEBGL_NOT_SUPPORTED = "insufficient WebGL support"
