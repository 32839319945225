import cx from "classnames"
import { useNavigate } from "react-router-dom"

import { eligibilityQuizBackground } from "../EligibilityQuiz/helpers"
import { PROJECT_TYPES } from "../../shared/constants"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "@/types/constants"
import { useAccountUrlPrefix } from "../../hooks"

interface QuizCardProps {
  quizCategory: EligibilityQuizCategoryType
  projectType: ProgramCategoryType
  recommended: boolean
}

const QuizCard = ({
  quizCategory,
  projectType,
  recommended,
}: QuizCardProps) => {
  const navigate = useNavigate()
  const accountUrlPrefix = useAccountUrlPrefix()
  const bgImage = eligibilityQuizBackground(quizCategory)

  return (
    <div
      className={cx(
        "quiz-card cursor-pointer w-[416px] h-[165px]",
        projectType,
        { recommended: recommended }
      )}
      style={{
        background: `linear-gradient(0deg, rgba(36, 36, 38, 0.70) 0%, rgba(36, 36, 38, 0.70) 100%), url(${bgImage}) lightgray 50% / cover no-repeat`,
        backgroundPosition:
          quizCategory === "regen_ag"
            ? "50% 40%"
            : quizCategory === "general"
              ? "50% 8%"
              : "50%",
      }}
      onClick={() =>
        navigate(`${accountUrlPrefix}/eligibility-quiz/${quizCategory}`)
      }
      role="button"
      tabIndex={0}
      aria-label={PROJECT_TYPES[projectType]?.long}
      id={quizCategory}
    >
      <div className="w-full flex justify-between">
        <h3 className="text-white text-lg">
          {PROJECT_TYPES[projectType]?.long}
        </h3>
        {recommended && (
          <p className="text-west-side text-overline font-bold">Recommended</p>
        )}
      </div>

      <p className="text-white text-left text-base my-2">
        {PROJECT_TYPES[projectType]?.description}
      </p>
    </div>
  )
}

export default QuizCard
