import { CSSProperties, Dispatch, SetStateAction, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalculator } from "@fortawesome/pro-solid-svg-icons"
import {
  faChartLineUp,
  faTableCells,
  IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons"

import cx from "classnames"
import RoiChart from "./RoiChart"
import RoiTable from "./RoiTable"
import RoiCalculator from "./RoiCalculator"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"
import { FormikProps } from "formik"

interface TabButtonTypes {
  icon: IconDefinition
  onClick: () => void
  active: boolean
}

interface TabTypes {
  activeTab: any
  roiData: RoiTypes
  isMobile: boolean
  width: number
  height: number
  formikProps: FormikProps<CalculatorInputsTypes>
  handleReset: (resetForm: () => void) => Promise<void>
  isReseting: boolean
  activeYear: number | null
  setActiveYear: Dispatch<SetStateAction<number | null>>
}

interface RoiModalContentMobileTypes {
  roiData: RoiTypes
  isMobile: boolean
  width: number
  height: number
  formikProps: FormikProps<CalculatorInputsTypes>
  handleReset: (resetForm: () => void) => Promise<void>
  isReseting: boolean
}

const CALCULATOR = "calculator"
const CHART = "chart"
const TABLE = "table"

const TabButton = ({ icon, onClick, active }: TabButtonTypes) => (
  <button
    type="button"
    className={cx(
      "h-8.5 w-12 flex justify-center items-center font-normal",
      active
        ? "btn2 btn2-primary btn2-sm cursor-default"
        : "btn btn-secondary btn-xs shadow-sm active:bg-white"
    )}
    onClick={onClick}
  >
    <FontAwesomeIcon
      icon={icon}
      size="xs"
      style={
        {
          "--fa-primary-opacity": 1,
          "--fa-secondary-opacity": 1,
        } as CSSProperties
      }
      fixedWidth
    />
  </button>
)

const Tab = ({
  activeTab,
  roiData,
  isMobile,
  width,
  height,
  formikProps,
  handleReset,
  isReseting,
  activeYear,
  setActiveYear,
}: TabTypes) => {
  if (activeTab === CHART) {
    return (
      <div className="w-full mt-6">
        <p className="text-xs text-dusk-700 text-center leading-130 mb-4">
          Tap on the chart to see revenue and profit.
        </p>

        <RoiChart
          roiData={roiData}
          isMobile={isMobile}
          width={width}
          height={height}
          setActiveYear={setActiveYear}
        />

        <p className="text-xs text-dusk-700 text-center leading-130 -mt-3">
          These calculations are not financial advice. They are a projection
          estimate based on programs and not a guarantee of returns.
        </p>
      </div>
    )
  }

  if (activeTab === TABLE) {
    return (
      <RoiTable
        roiData={roiData}
        isMobile={isMobile}
        activeYear={activeYear}
        setActiveYear={setActiveYear}
      />
    )
  }

  return (
    <div className="mt-6">
      <p className="text-xs text-dusk-700 leading-130 mb-4">
        Edit any field and recalculate for the latest data.
      </p>

      <RoiCalculator
        formikProps={formikProps}
        handleReset={handleReset}
        isReseting={isReseting}
      />
    </div>
  )
}

const RoiModalContentMobile = ({
  roiData,
  isMobile,
  width,
  height,
  formikProps,
  handleReset,
  isReseting,
}: RoiModalContentMobileTypes) => {
  const [activeTab, setActiveTab] = useState(CALCULATOR)
  const [activeYear, setActiveYear] = useState<number | null>(null)

  return (
    <>
      <div className="flex gap-1">
        <TabButton
          icon={faCalculator}
          onClick={() => setActiveTab(CALCULATOR)}
          active={activeTab === CALCULATOR}
        />
        <TabButton
          icon={faChartLineUp}
          onClick={() => setActiveTab(CHART)}
          active={activeTab === CHART}
        />
        <TabButton
          icon={faTableCells}
          onClick={() => setActiveTab(TABLE)}
          active={activeTab === TABLE}
        />
      </div>

      <div>
        <Tab
          activeTab={activeTab}
          roiData={roiData}
          isMobile={isMobile}
          width={width}
          height={height}
          formikProps={formikProps}
          handleReset={handleReset}
          isReseting={isReseting}
          activeYear={activeYear}
          setActiveYear={setActiveYear}
        />
      </div>
    </>
  )
}

export default RoiModalContentMobile
