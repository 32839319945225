import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { changePassword } from "../../../api/auth"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { AxiosResponse } from "axios"
import { ChangePasswordVariables } from "@/types/auth"

export function useChangePassword<TError = unknown>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<
    AxiosResponse<any>,
    TError,
    ChangePasswordVariables
  > = {}
): UseMutationResult<
  AxiosResponse<any>,
  TError,
  ChangePasswordVariables,
  unknown
> {
  return useMutationWithRefresh<
    AxiosResponse<any>,
    TError,
    ChangePasswordVariables
  >(queryClient, changePassword, config)
}
