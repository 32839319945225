import { ReactNode } from "react"

interface ToggleSwitchTypes {
  label: ReactNode
  value: boolean
  onChange: () => void
}

const ToggleSwitch = ({ label, value, onChange }: ToggleSwitchTypes) => (
  <label className="toggle-switch-label">
    {label}
    <input
      className="toggle-switch-input"
      type="checkbox"
      role="switch"
      value={value.toString()}
      onChange={onChange}
      checked={value}
    />
  </label>
)

export default ToggleSwitch
