import { useQuery } from "@tanstack/react-query"

import { refreshToken } from "../../../api/auth"

export function useRefresh(config = {}) {
  return useQuery({
    queryKey: ["refresh"],
    queryFn: refreshToken,
    ...config,
  })
}
