import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { getAttestationQuiz } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { EligibilityQuizCategoryType } from "@/types/constants"

export const useRefetchAttestationQuiz = <TData, TError, TVariables>(
  queryClient: QueryClient,
  accountId: string,
  quizCategory: EligibilityQuizCategoryType,
  sendAll: boolean,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    () => getAttestationQuiz(accountId, quizCategory, sendAll),
    {
      _onSuccess: (data) => {
        queryClient.setQueryData(
          ["accounts", accountId, "attestationQuiz", quizCategory],
          data
        )
      },
      ...config,
    }
  )
}
