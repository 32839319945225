import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import cx from "classnames"

import ProjectMapParcelsFeature from "./ProjectMapParcelsFeature"
import {
  getViewportFromFeature,
  handleNextFeature,
  handlePrevFeature,
} from "./helpers"
import { shortenAcreage } from "../../../utils"
import { Dispatch, SetStateAction } from "react"
import { ViewportTypes } from "@/types"

type FeaturePrevNextClickType = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: Dispatch<SetStateAction<ViewportTypes | null>>,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => void

interface ProjectMapParcelsTypes {
  isIneligible: boolean
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  activeFeature: number | null
  setActiveFeature: Dispatch<SetStateAction<number | null>>
  setViewport: Dispatch<SetStateAction<ViewportTypes | null>>
  className: string
  onNextFeatureClick?: FeaturePrevNextClickType
  onPrevFeatureClick?: FeaturePrevNextClickType
  enableEligibility?: boolean
  isWidget?: boolean
}

const ProjectMapParcels = ({
  isIneligible,
  parcelData,
  activeFeature = 0,
  setActiveFeature,
  setViewport,
  className,
  onNextFeatureClick,
  onPrevFeatureClick,
  enableEligibility = true,
  isWidget = false,
}: ProjectMapParcelsTypes) => (
  <div
    className={cx(
      "parcel-sidebar w-full flex flex-col-reverse",
      isWidget
        ? "xl:max-w-[244px] xl:flex-col lg:!h-auto"
        : "lg:max-w-[244px] lg:flex-col",
      className
    )}
  >
    <ul
      className={cx(
        "block border border-charcoal-50 border-t-0 border-b-0 rounded-b overflow-y-auto mt-0 mb-auto",
        isWidget
          ? "xl:border-t-1 lg:!h-auto xl:!h-[340px] xl:rounded-tr xl:rounded-br-none"
          : "lg:border-t-1 lg:rounded-tr lg:rounded-br-none"
      )}
    >
      {parcelData?.features.map((feature, index, arr) => {
        const disabled =
          enableEligibility && feature?.properties?.elig_acres === 0

        return (
          <li
            key={index}
            tabIndex={isIneligible ? -1 : 0}
            onClick={() => {
              if (!disabled) {
                setActiveFeature(index)
                setViewport(getViewportFromFeature(feature))
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !disabled) {
                setActiveFeature(index)
                setViewport(getViewportFromFeature(feature))
              }
            }}
            className={cx(
              "w-full flex justify-between items-center p-2 border-b-1 border-b-charcoal-50 focus:ring-inset",
              "text-charcoal-500 text-sm leading-130 tracking-0.14",
              { "bg-charcoal-50": disabled },
              { "hover:bg-grass-50 cursor-pointer": !disabled },
              { "bg-grass-50": activeFeature === index },
              { "border-b-0": index === arr.length - 1 }
            )}
          >
            {enableEligibility ? (
              <div>
                <strong className="font-bold">
                  {shortenAcreage(feature?.properties?.elig_acres as number)}/
                  {shortenAcreage(feature?.properties?.parcel_acres as number)}
                </strong>{" "}
                acres eligible
              </div>
            ) : (
              <div>
                <strong className="font-bold">
                  {shortenAcreage(feature?.properties?.parcel_acres as number)}
                </strong>{" "}
                acres
              </div>
            )}

            <ProjectMapParcelsFeature
              parcelData={parcelData}
              featureIndex={index}
            />
          </li>
        )
      })}
    </ul>

    <div
      className={cx(
        "w-full h-[60px] flex gap-4 border border-charcoal-50 px-4 py-2 mt-0 mb-0",
        isWidget ? "xl:rounded-br xl:mb-0 xl:mt-auto" : "lg:rounded-br"
      )}
    >
      <button
        type="button"
        className="grow btn2 btn2-outline-primary text-dusk-500 p-[10px]"
        onClick={() =>
          onPrevFeatureClick
            ? onPrevFeatureClick(setActiveFeature, setViewport, parcelData)
            : handlePrevFeature(setActiveFeature, setViewport, parcelData)
        }
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      <button
        type="button"
        className="grow btn2 btn2-outline-primary text-dusk-500 p-[10px]"
        onClick={() =>
          onNextFeatureClick
            ? onNextFeatureClick(setActiveFeature, setViewport, parcelData)
            : handleNextFeature(setActiveFeature, setViewport, parcelData)
        }
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  </div>
)

export default ProjectMapParcels
