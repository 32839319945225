import React from "react"
import { Link } from "react-router-dom"
import PropertyMap from "../LandCoverDetails/PropertyMap"
import { useAccountUrlPrefix } from "../../hooks"

interface OnboardingPropertyMapProps {
  accountId: string
}

const OnboardingPropertyMap = ({ accountId }: OnboardingPropertyMapProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  return (
    <PropertyMap
      accountId={accountId}
      footer={
        <div className="p-6 w-full lg:max-w-[600px] bg-white">
          Not seeing all your land?{" "}
          <Link
            to={`${accountUrlPrefix}/onboarding/property-boundaries`}
            state={{
              hasCompletedSurvey: true,
            }}
            className="link add-more-parcels"
          >
            {" "}
            Add more parcels
          </Link>
        </div>
      }
    />
  )
}

export default OnboardingPropertyMap
