import { QueryClient } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { verifyEmail } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useVerifyEmail = <
  TData extends AxiosResponse<any>,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
) => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    verifyEmail,
    {
      _onSuccess: () => {
        // Update `profile` query data now that email is verified
        queryClient.setQueryData(
          ["profile"],
          (oldData: Record<string, unknown>) => {
            assert(
              Object.prototype.hasOwnProperty.call(
                oldData,
                "is_email_verified"
              ),
              "`is_email_verified` no longer exists on Profile"
            )
            return {
              ...oldData,
              is_email_verified: true,
            }
          }
        )
        queryClient.invalidateQueries({ queryKey: ["profile"] })
      },
      ...config,
    }
  )
}
