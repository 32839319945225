import React from "react"

interface ProgressBarTypes {
  value: number
  max: number
  className?: string
}

export const ProgressBar = ({
  value,
  max,
  className = "",
}: ProgressBarTypes) => {
  return <progress className={className} value={value} max={max} />
}
