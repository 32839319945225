import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { updateAccount } from "../../../api/data"
import { useMutationWithRefresh } from "../utils"
import { Profile } from "@/types"

export function useUpdateAccount<
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => updateAccount(accountId, values),
    {
      _onSuccess: (data: TData) => {
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-cycles"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })
        queryClient.setQueryData(["accounts"], (prevAccountsValue: Profile[]) =>
          (prevAccountsValue || []).map((prevAccount) => {
            if (prevAccount.id.toString() === accountId.toString()) {
              return data
            }
            return prevAccount
          })
        )
        queryClient.setQueryData(["accounts", accountId], data)
      },
      ...config,
    }
  )
}
