import React, { useContext } from "react"

import SearchField from "../../components/SearchField"
import { ProjectsContext } from "../../context/ProjectsContext"
import { PROJECT_ACTIONS } from "../../shared/constants"

interface PageHeroTypes {
  handleFirstPageRedirect: () => void
}

const PageHero = ({ handleFirstPageRedirect }: PageHeroTypes) => {
  const {
    state: { searchProjects },
    dispatch,
  } = useContext(ProjectsContext)

  return (
    <header className="bg-charcoal-600 py-6 md:py-12">
      <div className="container max-w-6xl">
        <p className="text-cloud-50 text-lg text-center font-normal leading-130 tracking-0.36">
          Browse program categories or search keywords to find programs.
        </p>

        <div className="max-w-full w-[343px] mx-auto mt-6">
          <SearchField
            id="search-projects"
            initialValue={searchProjects}
            onChange={(value) => {
              dispatch({
                type: PROJECT_ACTIONS.setSearchProjects,
                payload: value,
              })
              handleFirstPageRedirect()
            }}
            placeholder="Search by keyword"
            className="order-1"
          />
        </div>
      </div>
    </header>
  )
}

export default PageHero
