import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import {
  _getAccountUserListMutationConfig,
  useMutationWithRefresh,
} from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { addAccountUser } from "../../../api/data"
import { AccountUsers } from "@/types/accountUsers"

export function useAddAccountUser<
  TData extends AccountUsers,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => addAccountUser(accountId, values),
    _getAccountUserListMutationConfig<TData, TError, TVariables>(queryClient, {
      action: "add",
      accountId,
      email: null,
      listQueryEagerMutateFn: (newAccountUser, oldAccountUsersPage) =>
        ({
          count: oldAccountUsersPage.count + 1,
          object_list: [...oldAccountUsersPage.object_list, newAccountUser],
        }) as TData,
      config,
    })
  )
}
