import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { getNotifications } from "../../../api/data"
import { useQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useGlobalNotifications<TData, TError>(
  queryClient: QueryClient,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query = useQueryWithRefresh<TData, TError>(
    queryClient,
    // DEV: We use `global` instead of `{is_global: true}` to simplify cache-busting in deletion
    //   It's preferred to use `values` in queryKey normally though
    ["notifications", "global"],
    () => getNotifications({ is_global: true }),
    config
  )
  return query
}
