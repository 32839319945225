import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { getAttestationQuiz } from "../../../api/data"

export const useGetAttestationQuizes = <TData, TError>(
  accountId: string,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "attestationQuizes"],
    queryFn: async () => getAttestationQuiz(accountId),
    ...config,
  })
}
