import { Link } from "react-router-dom"
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../hooks"
import { RoiTypes } from "@/types/roi"

interface ProjectNpvTypes {
  roiData: RoiTypes | undefined
  roiDialog: DialogStateReturn
  isMember: boolean | undefined
}

const ProjectNpv = ({ roiData, roiDialog, isMember }: ProjectNpvTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <div className="w-full lg:w-396 flex justify-between gap-4 border border-dusk-50 rounded p-4 mb-8">
      <div className="flex flex-col">
        <p
          className={cx(
            "text-xl text-charcoal-500 font-bold leading-120 tracking-0.4 mb-1",
            { "blur-sm": !isMember }
          )}
        >
          ${roiData?.project_npv.toLocaleString("en-US")}
        </p>
        <p className="text-sm text-dusk-700 leading-130 tracking-0.14">
          Time adjusted value
        </p>
      </div>

      <div className="h-6 flex items-center shrink-0">
        {isMember ? (
          <DialogDisclosure
            {...roiDialog}
            className="link text-sm font-semibold leading-130 tracking-0.14"
          >
            View Calculator
          </DialogDisclosure>
        ) : (
          <Link
            to={`${accountUrlPrefix}/become-a-member`}
            className="link text-sm font-semibold leading-130 tracking-0.14"
          >
            View Calculator
          </Link>
        )}
      </div>
    </div>
  )
}

export default ProjectNpv
