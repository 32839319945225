import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getTileLinks } from "../../../api/data"

export const useGetTileUrls = <TData, TError>(
  accountId: string,
  projectId: string,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "tile", "links", projectId],
    queryFn: async () => getTileLinks(accountId, projectId),
    gcTime: 0,
    ...config,
  })
}
