import { DialogStateReturn } from "reakit"
import HelperCard from "../../components/HelperCard"
import News from "./News"
import ProjectNpv from "./ProjectNpv"
import Stackables from "./Stackables"
import { ProgramType } from "@/types/program"
import { RoiTypes } from "@/types/roi"

interface ProjectDetailsSidebarTypes {
  projectData: ProgramType
  roiData: RoiTypes | undefined
  roiDialog: DialogStateReturn
  showRoi: boolean
  stackablePrograms: ProgramType[]
  isMultiAccount: boolean
  accountId: string
  isMember?: boolean
}

const ProjectDetailsSidebar = ({
  projectData,
  roiData,
  roiDialog,
  showRoi,
  stackablePrograms,
  isMultiAccount,
  accountId,
  isMember,
}: ProjectDetailsSidebarTypes) => (
  <div className="mb-19.5 lg:mb-0 mt-10 lg:mt-0">
    {showRoi ? (
      <ProjectNpv roiData={roiData} roiDialog={roiDialog} isMember={isMember} />
    ) : null}

    <Stackables
      stackablePrograms={stackablePrograms}
      isMultiAccount={isMultiAccount}
      accountId={accountId}
      isMember={isMember}
    />

    {projectData.article_data.length > 0 ? (
      <News articleData={projectData.article_data} />
    ) : null}

    {projectData.is_government_program ? (
      <HelperCard className="w-full lg:w-396 mb-6">
        <p className="text-charcoal-500 leading-130">
          New to government programs?{" "}
          <a
            href="https://www.farmers.gov/working-with-us/USDA-service-centers"
            className="get-started-steps link font-normal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn about the steps to get started.
          </a>
        </p>
      </HelperCard>
    ) : null}
  </div>
)

export default ProjectDetailsSidebar
