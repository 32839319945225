import { faTable } from "@fortawesome/pro-solid-svg-icons"

import IconLink from "../../components/IconLink"
import Sidebar from "../../components/Sidebar"
import { ArticleDataTypes } from "@/types/program"

interface NewsTypes {
  articleData: ArticleDataTypes[]
}

const News = ({ articleData }: NewsTypes) => {
  if (!articleData || articleData?.length === 0) {
    return null
  }

  return (
    <Sidebar
      title="In the news"
      description="Read recent news articles about this program partner."
      className="mb-6"
    >
      {articleData?.map((article) => (
        <IconLink
          key={article.url}
          to={article.url}
          icon={faTable}
          text={article.title}
          className="truncate mb-3"
          external
        />
      ))}
    </Sidebar>
  )
}

export default News
