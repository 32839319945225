import { QueryClient } from "@tanstack/react-query"

import { getParcelData } from "../../../api/data"

export function fetchParcelData(
  queryClient: QueryClient,
  ids: string[],
  config = {}
) {
  return queryClient.fetchQuery({
    queryKey: ["parcel-data", ids],
    queryFn: () => getParcelData(ids),
    ...config,
  })
}
