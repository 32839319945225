import {
  faFileContract,
  faClock,
  faCoins,
  faClipboardList,
  faDollarSign,
  faLock,
  faCheck,
  faKey,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons"

import IconText from "../../components/IconText"
import ExpandableContainer from "../../components/ExpandableContainer"
import { useAccountUrlPrefix } from "../../hooks"
import {
  ContractDetailsTypes,
  OverviewInformationTypes,
  ProgramHeadersTypes,
} from "@/types/program"

interface HeadingTypes {
  heading: string
}

interface TextTypes {
  textArray: string[]
}

interface SectionTypes {
  title: string
  data: ProgramHeadersTypes
  icon: IconDefinition
  className: string
}

interface ProjectOverviewTypes {
  data: OverviewInformationTypes
}

interface ProjectDetailsContentTypes {
  overviewInformation: OverviewInformationTypes
  contractDetails: ContractDetailsTypes
  projectId: string
  isMember: boolean | undefined
  isGovernmentProgram: boolean | undefined
  isSponsored: boolean | undefined
  isInvited: boolean
}

const Heading = ({ heading }: HeadingTypes) => {
  if (heading?.length === 0) {
    return null
  }
  return <h5 className="pdc-heading">{heading}</h5>
}

const Text = ({ textArray }: TextTypes) => {
  if (textArray?.length === 0) {
    return null
  }
  return textArray.map((txt) => (
    <p key={txt} className="pdc-text">
      {txt}
    </p>
  ))
}

const Section = ({ title, data, icon, className }: SectionTypes) => (
  <IconText icon={icon} className={className} iconClass="pdc-icon">
    <h4 className="pdc-title">{title}</h4>

    {Object.values(data).every((value) => value.length === 0) ? (
      <Heading heading="Information not readily available" />
    ) : (
      <>
        <Heading heading={data?.header_1 as string} />
        <Text textArray={data?.header_1_text as string[]} />
        <Heading heading={data?.header_2 as string} />
        <Text textArray={data?.header_2_text as string[]} />
        <Heading heading={data?.header_3 as string} />
        <Text textArray={data?.header_3_text as string[]} />
      </>
    )}
  </IconText>
)

const ProjectOverview = ({ data }: ProjectOverviewTypes) => (
  <>
    <h2 className="text-2xl lg:text-3xl mb-5 lg:mb-4.5">Program overview</h2>

    <Section
      title="Key obligation"
      data={data.key_obligation}
      icon={faFileContract}
      className="mb-6"
    />

    <Section
      title="Term length"
      data={data.term_length}
      icon={faClock}
      className="mb-6"
    />

    <Section
      title="Landowner costs"
      data={data.landowner_costs}
      icon={faCoins}
      className="mb-6"
    />

    <Section
      title="Eligibility requirements"
      data={data.eligibility_requirements}
      icon={faClipboardList}
      className="mb-12"
    />
  </>
)

const ContractDetails = ({ data }: { data: ContractDetailsTypes }) => (
  <>
    <h3 className="text-2xl text-charcoal-900 mb-4.5">Contract details</h3>

    <Section
      title="Earnings and penalties"
      data={data.earnings_and_penalties}
      icon={faDollarSign}
      className="mb-6"
    />

    <Section
      title="Restrictions"
      data={data.restrictions}
      icon={faLock}
      className="mb-6"
    />

    <Section
      title="Landowner rights"
      data={data.landowner_rights}
      icon={faCheck}
      className="mb-6"
    />

    <Section
      title="Property access"
      data={data.property_access}
      icon={faKey}
      className="mb-6"
    />
  </>
)

const ProjectDetailsContent = ({
  overviewInformation,
  contractDetails,
  projectId,
  isMember,
  isGovernmentProgram,
  isSponsored,
  isInvited,
}: ProjectDetailsContentTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!overviewInformation && !contractDetails) {
    return null
  }

  return (
    <div className="w-full lg:max-w-552">
      <ExpandableContainer
        id={projectId}
        btnTextOpen="View more details"
        btnTextClose="View fewer details"
        className="py-2 lg:p-3 w-full"
        overrideUrl={
          isMember || isGovernmentProgram || isSponsored || isInvited
            ? undefined
            : `${accountUrlPrefix}/become-a-member`
        }
      >
        {overviewInformation ? (
          <ProjectOverview data={overviewInformation} />
        ) : null}
        {contractDetails ? <ContractDetails data={contractDetails} /> : null}
      </ExpandableContainer>
    </div>
  )
}

export default ProjectDetailsContent
