import { AccountCycleKeyLongNameType } from "@/types/accountCycles"
import { ProgramCategoryType } from "@/types/constants"

// https://github.com/dockwa/simple-react-validator/blob/master/src/simple-react-validator.js#L42
export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/

export const DATETIME_TIMEZONE = "America/Los_Angeles"
// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
// DATETIME_MONTH_DAY = "January 1" (no zero padding)
export const DATETIME_MONTH_DAY = "MMMM d"
// DATETIME_MONTH_DAY_YEAR = "January 1, 2022"(no zero padding)
export const DATETIME_MONTH_DAY_YEAR = "MMMM d, yyyy"
export const REVIEW_PERIOD_DURATION = { days: 14 }

// Date objects below are currently only used for sorting
//   We don't follow them strictly operationally so don't rely heavily on them for logic
//   Maintaining PST/PDT timezones is finnicky so use -0800 since it'll always be later (vs -0700)
// DEV: We're aware that MDN recommends against using date strings, but the alternatives are more error-prone imo (Todd)
//   (e.g. 0-indexed months leading to human error, no timezone support in Date constructor)
//   We're confident that as long as we stick to long-form ISO 8601, then we'll be fine
//   https://app.asana.com/0/0/1201489485840830/f
// DEV: We could totally use a function to resolve names, this is just easier to maintain via multi-cursor editing
const cycleKeyLongNames = (cycle: AccountCycleKeyLongNameType) => {
  const seasons = {
    A: "Winter",
    B: "Spring",
    C: "Summer",
    D: "Fall",
  }
  const season = seasons[cycle.slice(-1) as keyof typeof seasons]
  const year = `20${cycle.slice(0, 2)}`

  return `${season} ${year}`
}
const CYCLE_INFO_DEFAULTS = {
  // Temporary constant for hiding dates, https://app.asana.com/0/1203651006916960/1203651016731406/f
  showDates: true,
  // Should `ActionNeededContent` be shown for this cycle
  showActionNeeded: true,
}
export const CYCLE_INFOS_BY_KEY = {
  "21B": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("21A"),
    longName: cycleKeyLongNames("21B"),
    nextLongName: cycleKeyLongNames("21C"),
    enrollmentArea: "the 11-state enrollment area",

    eligibilityReportRequestPeriodStart: "January 28",
    eligibilityReportRequestPeriodEnd: "March 4",
    eligibilityReportRequestPeriodEndDate: new Date("2021-03-04T23:59:00-0800"),

    submissionDeadline: "March 8",
    submissionDeadlineDate: new Date("2021-03-08T23:59:00-0800"),
    deferralPeriodStartDate: "April 1st",
    deferralPeriodEndDate: "March 31, 2022",

    harvestDeferralPeriod: "April 1, 2021 – March 31, 2022",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly until Mar 4", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Mar 8", // Offer submission deadline
      deferHarvest: "Apr 1, 2021 - Mar 31, 2022", // Deferral period with 3-letter months
      receivePayment: "Early Apr 2022", // 3-letter payment month
    },
  },

  "21C": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("21B"),
    longName: cycleKeyLongNames("21C"),
    nextLongName: cycleKeyLongNames("22A"), // DEV: We skipped 21D, so use 22A
    enrollmentArea: "the 16-state enrollment area",

    eligibilityReportRequestPeriodStart: "April 8",
    eligibilityReportRequestPeriodEnd: "June 2",
    eligibilityReportRequestPeriodEndDate: new Date("2021-06-02T23:59:00-0800"),

    submissionDeadline: "June 8",
    submissionDeadlineDate: new Date("2021-06-08T23:59:00-0800"),
    deferralPeriodStartDate: "July 1st",
    deferralPeriodEndDate: "June 30, 2022",

    harvestDeferralPeriod: "July 1, 2021 - June 30, 2022",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Apr 8 until Jun 2", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Jun 8", // Offer submission deadline
      deferHarvest: "Jul 1, 2021 - Jun 30, 2022", // Deferral period with 3-letter months
      receivePayment: "Jul 2022", // 3-letter payment month
    },
  },

  // 21D was skipped, this entry is identical to the 22A entry
  // for the brief period between when this is deployed
  // and the LATEST_CYCLE_KEY on the back end is updated to 22A
  "21D": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("21C"),
    longName: cycleKeyLongNames("21D"),
    nextLongName: cycleKeyLongNames("22A"),
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "October 8",
    eligibilityReportRequestPeriodEnd: "December 1",
    eligibilityReportRequestPeriodEndDate: new Date("2021-12-01T23:59:00-0800"),

    submissionDeadline: "December 8",
    submissionDeadlineDate: new Date("2021-12-08T23:59:00-0800"),
    deferralPeriodStartDate: "January 1st",
    deferralPeriodEndDate: "December 31, 2022",

    harvestDeferralPeriod: "January 1, 2022 - December 31, 2022",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Oct 8 until Dec 1", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Dec 8", // Offer submission deadline
      deferHarvest: "Jan 1, 2022 - Dec 31, 2022", // Deferral period with 3-letter months
      receivePayment: "Jan 2023", // 3-letter payment month
    },
  },

  "22A": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("21C"), // DEV: We skipped 21D, so use 21C
    longName: cycleKeyLongNames("22A"),
    nextLongName: cycleKeyLongNames("22B"),
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "October 8",
    eligibilityReportRequestPeriodEnd: "December 1",
    eligibilityReportRequestPeriodEndDate: new Date("2021-12-01T23:59:00-0800"),

    submissionDeadline: "December 8",
    submissionDeadlineDate: new Date("2021-12-08T23:59:00-0800"),
    deferralPeriodStartDate: "January 1st",
    deferralPeriodEndDate: "December 31, 2022",

    harvestDeferralPeriod: "January 1, 2022 - December 31, 2022",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Oct 8 until Dec 1", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Dec 8", // Offer submission deadline
      deferHarvest: "Jan 1, 2022 - Dec 31, 2022", // Deferral period with 3-letter months
      receivePayment: "Jan 2023", // 3-letter payment month
    },
  },

  "22B": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("22A"),
    longName: cycleKeyLongNames("22B"),
    nextLongName: cycleKeyLongNames("22D"), // DEV: We skipped 22C, so use 22D
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "February 1",
    eligibilityReportRequestPeriodEnd: "March 1",
    eligibilityReportRequestPeriodEndDate: new Date("2022-03-01T23:59:00-0800"),

    submissionDeadline: "March 9",
    submissionDeadlineDate: new Date("2022-03-09T23:59:00-0800"),
    deferralPeriodStartDate: "April 1",
    deferralPeriodEndDate: "March 31, 2023",

    harvestDeferralPeriod: "April 1, 2022 - March 31, 2023",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Feb 1 until Mar 1", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Mar 9", // Offer submission deadline
      deferHarvest: "Apr 1, 2022 - Mar 31, 2023", // Deferral period with 3-letter months
      receivePayment: "May 2023", // 3-letter payment month
    },
  },

  "22C": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("22B"),
    longName: cycleKeyLongNames("22C"),
    nextLongName: cycleKeyLongNames("22D"),
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "May 1",
    eligibilityReportRequestPeriodEnd: "June 1",
    eligibilityReportRequestPeriodEndDate: new Date("2022-06-01T23:59:00-0800"),

    submissionDeadline: "June 8",
    submissionDeadlineDate: new Date("2022-06-08T23:59:00-0800"),
    deferralPeriodStartDate: "July 1",
    deferralPeriodEndDate: "June 30, 2023",

    harvestDeferralPeriod: "July 1, 2022 - June 30, 2023",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from May 1 until Jun 1", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Jun 8", // Offer submission deadline
      deferHarvest: "Jul 1, 2022 - Jun 31, 2023", // Deferral period with 3-letter months
      receivePayment: "Aug 2023", // 3-letter payment month
    },
  },

  "22D": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("22B"), // DEV: We skipped 22C, so use 22B
    longName: cycleKeyLongNames("22D"),
    nextLongName: cycleKeyLongNames("23D"), // DEV: We skipped 23A+B+C, so use 23D
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "August 1",
    eligibilityReportRequestPeriodEnd: "September 15",
    eligibilityReportRequestPeriodEndDate: new Date("2022-09-15T23:59:00-0800"),
    eligibilityReportDeliveryPeriodEnd: "September 22",

    submissionDeadline: "September 29",
    submissionDeadlineDate: new Date("2022-09-29T23:59:00-0800"),
    deferralPeriodStartDate: "October 1",
    deferralPeriodEndDate: "September 30, 2023",
    harvestDeferralPeriod: "October 1, 2022 - September 30, 2023",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Aug 1 until Sep 19", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Sep 29", // Offer submission deadline
      deferHarvest: "Oct 1, 2022 - Sep 30, 2023", // Deferral period with 3-letter months
      receivePayment: "Nov 2023", // 3-letter payment month
    },
  },

  "23A": {
    ...CYCLE_INFO_DEFAULTS,

    previousLongName: cycleKeyLongNames("22D"),
    longName: cycleKeyLongNames("23A"),
    nextLongName: cycleKeyLongNames("23D"), // DEV: We skipped 23B+C, so use 23D
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "September 16",
    eligibilityReportRequestPeriodEnd: "December 15",
    eligibilityReportRequestPeriodEndDate: new Date("2022-12-15T23:59:00-0800"),
    eligibilityReportDeliveryPeriodEnd: "December 19",

    // `submissionDeadline` is the last matching event date
    submissionDeadline: "December 22",
    submissionDeadlineDate: new Date("2022-12-22T23:59:00-0800"),
    deferralPeriodStartDate: "January 1",
    deferralPeriodEndDate: "December 31, 2023",
    harvestDeferralPeriod: "January 1, 2023 - December 31, 2023",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Oct 31 until Dec 19", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Dec 22", // Offer submission deadline
      deferHarvest: "Jan 1, 2023 - Dec 31, 2023", // Deferral period with 3-letter months
      receivePayment: "Mar 2024", // 3-letter payment month
    },
  },
  "23D": {
    ...CYCLE_INFO_DEFAULTS,

    showDates: false,
    showActionNeeded: false,

    previousLongName: cycleKeyLongNames("22D"), // DEV: We skipped 23A+B+C, so use 23D
    longName: cycleKeyLongNames("23D"),
    nextLongName: cycleKeyLongNames("24A"),
    enrollmentArea: "the eligible states",

    eligibilityReportRequestPeriodStart: "August 1",
    eligibilityReportRequestPeriodEnd: "September 15",
    eligibilityReportRequestPeriodEndDate: new Date("2023-09-15T23:59:00-0800"),
    eligibilityReportDeliveryPeriodEnd: "September 22",

    submissionDeadline: "September 29",
    submissionDeadlineDate: new Date("2023-09-29T23:59:00-0800"),
    deferralPeriodStartDate: "October 1",
    deferralPeriodEndDate: "September 30, 2024",
    harvestDeferralPeriod: "October 1, 2023 - September 30, 2024",

    overviewTimelineContent: {
      requestAssessment: "Less than 5 mins", // Does not change cycle-to-cycle
      receiveAssessment: "Weekly from Aug 1 until Sep 19", // Eligibility report delivery period with 3-letter months
      submitOffer: "Final matching event Sep 29", // Offer submission deadline
      deferHarvest: "Oct 1, 2023 - Sep 30, 2024", // Deferral period with 3-letter months
      receivePayment: "Nov 2024", // 3-letter payment month
    },
  },
}

// DEV: Keep this list in sync with ncapx_platform.models.AccountCycle#STATUS_CHOICES in bark_api.
export const ACCOUNT_CYCLE_STATUS_LIST = [
  "created",
  "assessment_requested",
  "ineligible_geography",
  "ineligible_nonforest",
  // ASANA the ineligible_fortype status should be removed once this migration is complete:
  // https://app.asana.com/0/1198952737412966/1202126222784332/f
  "ineligible_fortype",
  "ineligible_conservation",
  "ineligible_risk",
  "ineligible",
  "assessed",
  "ncapx_sale_agreement_sent",
  "ncapx_sale_agreement_signed",
  "bid_accepted",
  "results_delivered_ineligible",
  "results_delivered",
  "results_review_in_progress",
  "payment_amount_confirmed",
  "payment_sent",
  "payment_delivery_failed",
  "payment_delivered",
]

export const HELP_CENTER_URL = "https://help.ncx.com/hc/en-us"

export const PROJECT_BADGE_TYPES = {
  primary: "primary",
  warning: "warning",
  error: "error",
  neutral: "neutral",
  info: "info",
}

export const LANDOWNER_STATUS = {
  eligible: "eligible",
  ineligible: "ineligible",
  in_progress: "in_progress",
  not_interested: "not_interested",
  request_information: "request_information",
  determining_eligibility: "determining_eligibility",
  under_contract: "under_contract",
  information_needed: "information_needed",
  datapack_shareable: "datapack_shareable",
  lo_not_interested: "lo_not_interested",
  internal_dq: "internal_dq",
}

export const LANDOWNER_STATUS_MESSAGE = {
  eligible: "Eligible",
  ineligible: "Ineligible",
  not_interested: "Not Interested",
  request_information: "Requested",
  determining_eligibility: "Determining Eligibility",
  under_contract: "Contract Signed",
  information_needed: "Action Required",
}

export const PROJECT_SORTING = {
  RECENTLY_ADDED: "Recently added",
  VERIFIED: "Verified",
  ELIGIBILITY: "Eligibility",
  ENROLLMENT_DEADLINE: "Enrollment deadline",
  POTENTIAL_EARNINGS: "Potential earnings",
  MOST_POPULAR: "Most popular",
}

export const PROJECT_SORTING_QS = {
  recently_added: PROJECT_SORTING.RECENTLY_ADDED,
  is_verified: PROJECT_SORTING.VERIFIED,
  eligibility: PROJECT_SORTING.ELIGIBILITY,
  enrollment_deadline: PROJECT_SORTING.ENROLLMENT_DEADLINE,
  potential_earnings: PROJECT_SORTING.POTENTIAL_EARNINGS,
  most_popular: PROJECT_SORTING.MOST_POPULAR,
}

export const DEADLINE_TYPE = {
  specific_date: "specific_date",
  open_enrollment: "open_enrollment",
  pending_interest: "pending_interest",
}

export const NOT_INTERESTED_REASONS = {
  KEY_OBLIGATION: "key_obligation",
  TERM_LENGTH: "term_length",
  LANDOWNER_COSTS: "landowner_costs",
  ELIGIBILITY_REQUIREMENTS: "eligibility_requirements",
  EARNINGS_AND_PENALTIES: "earnings_and_penalties",
  RESTRICTIONS: "restrictions",
  LANDOWNER_RIGHTS: "landowner_rights",
  PROPERTY_ACCESS: "property_access",
  OTHER: "other",
}

interface ProjectsTypeTypes {
  id: string
  long: string
  short?: string
  description: string
  earningsNullLabel?: string
}

type ProjectsTypesTypes = Record<ProgramCategoryType, ProjectsTypeTypes>

export const PROJECT_TYPES: ProjectsTypesTypes = {
  tree_planting: {
    id: "tree_planting",
    long: "Tree Planting",
    description:
      "Replant after a recent harvest or convert fields to forests to earn carbon and or timber revenue.",
  },
  renewable_infrastructure: {
    id: "renewable_infrastructure",
    long: "Renewable Energy",
    description:
      "Lease development rights for solar, wind, and other renewable energy sources.",
  },
  timber: {
    id: "timber",
    long: "Timber Harvest",
    description:
      "Harvest mature timber to earn significant revenue and alter your forest's structure and composition.",
    // we only have null in cases where we don't have all the data needed
    earningsNullLabel: "Unavailable",
  },
  recreation: {
    id: "recreation",
    long: "Recreation",
    description:
      "Lease your land for limited recreational use like hunting, fishing, farm tours, camping, and more.",
  },
  harvest_deferral: {
    id: "harvest_deferral",
    long: "Forest Carbon",
    description: "Generate carbon credits by allowing trees to grow older.",
  },
  forest_carbon: {
    id: "forest_carbon",
    long: "Forest Carbon",
    description: "Generate carbon credits by allowing trees to grow older.",
  },
  water: {
    id: "water",
    long: "Water",
    description:
      "Protect water quality and quantity through a variety of cost share programs.",
  },
  biodiversity: {
    id: "biodiversity",
    long: "Biodiversity",
    description:
      "Restore native habitat, protect pollinators, create connectivity corridors, and more.",
  },
  wildfire: {
    id: "wildfire",
    long: "Forest Health",
    description:
      "Improve forest health by reducing risk from threats and recovering degraded areas.",
  },
  regen_ag: {
    id: "regen_ag",
    long: "Regenerative Agriculture",
    short: "Regenerative Ag",
    description:
      "Improve soil health, carbon sequestration, and operating productivity through rotational grazing and other regenerative practices.",
  },
  advisory_services: {
    id: "advisory_services",
    long: "Advisory Services",
    description:
      "Connect with experts in forestry, wildlife, and real estate to assist your land management and planning",
  },
  other: {
    id: "other",
    long: "Advisory Services",
    description:
      "Connect with experts in forestry, wildlife, and real estate to assist your land management and planning",
  },
}

export const PROJECT_INTEREST = {
  interested_in_biodiversity: "Biodiversity",
  interested_in_harvested_deferral: "Forest Carbon",
  interested_in_ncx_recommendations: "Programs that NCX recommends",
  interested_in_reforestation: "Tree Planting/Revegetation",
  interested_in_renewable_energy: "Renewable Energy",
  interested_in_regen_ag: "Regenerative Agriculture",
  interested_in_recreation: "Recreation",
  interested_in_timber: "Timber Harvest",
  interested_in_water: "Water Quality/Yield",
  interested_in_wildfire: "Forest Health",
  interested_in_advisory_services: "Advisory Services",
}

export const LAND_GOALS = {
  income: "Income Generation",
  environmental: "Environmental and Forest Health",
  wildlife: "Wildlife Conservation",
  hunting: "Hunting Opportunities",
  recreation_non_hunting: "Recreation (Non-Hunting)",
  land_improvement: "Land Improvement/Construction",
}

export const FEATURE_FLAGS = {
  nca_messaging_20241107: "nca_messaging_20241107",
  nci_rollout: "nci_rollout",
}

export const PROJECT_ACTIONS = {
  setCategory: "setCategory",
  setGovtProjectsOn: "setGovtProjectsOn",
  setIneligibleProjectsOn: "setIneligibleProjectsOn",
  setHasLandownerCost: "setHasLandownerCost",
  setTermLengthFilter: "setTermLengthFilter",
  setPaymentTypes: "setPaymentTypes",
  setSearchProjects: "setSearchProjects",
  setInitialState: "setInitialState",
}

export const QUALIFIED_LEADS = "qualified_leads"
export const ELIGIBLE_LEADS = "eligible_leads"

export const PAYMENT_TYPES = {
  annuity: "Annuity (Payment over time)",
  lump_sum: "Lump sum (Payment up front)",
  revenue_share: "Revenue share",
  cost_share: "Cost share",
}

export const CONTACT_METHODS = {
  phone: "Phone call",
  text: "Text message",
  email: "Email",
  video: "Video call (Zoom, Google Meet, etc.)",
}

export const PREFERRED_CONTACT_METHOD = "preferred_contact_method"
export const PHONE_NUMBER = "phone_number"

export const CALCULATOR_INPUTS = {
  ELIGIBLE_ACRES: "eligible_acres",
  YEARLY_REVENUE: "yearly_revenue",
  STARTUP_COST: "startup_cost",
  ANNUAL_COST: "annual_cost",
  INFLATION_RATE: "inflation_rate",
  DISCOUNT_RATE: "discount_rate",
}

export const PARTNERS_ACCOUNT_STATUSES = [
  { value: "pd_attempting_contact", label: "Attempting Contact" },
  { value: "awaiting_more_land", label: "Awaiting More Land" },
  { value: "under_contract", label: "Contract Signed" },
  { value: "pd_contract_sent", label: "Contractual Offer Sent" },
  { value: "pd_contacted", label: "Conversation in Progress" },
  { value: "pd_existing_relationship", label: "Lost: Existing" },
  { value: "lo_offer_declined", label: "Lost: Offer Declined" },
  { value: "pd_not_accepted", label: "Lost: Rejected Ineligible" },
  { value: "lo_not_interested", label: "Lost: Uninterested" },
  { value: "lo_unresponsive", label: "Lost: Unresponsive" },
]

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2lsdmlhdGVycmEiLCJhIjoiY2tocWthOTUzMDJtbDJ5cXB4d2tmbjdtdCJ9.kvPKEDnUGQBtyBmUCPafdA"

//DEV: this is in order we want to display the landcover data
export const LANDCOVER_TYPE_LABELS = {
  forest: "Forested",
  cultivated: "Cultivated",
  herbaceous: "Grassland",
  shrub: "Shrubland",
  water: "Water",
  wetlands: "Wetlands",
  developed: "Developed",
  barren: "Barren",
}

export const mapStyles = {
  aerial: {
    url: "mapbox://styles/silviaterra/ckhb6hcco09os19pshbd2afaq",
    label: "Aerial",
  },
  topo: {
    url: "mapbox://styles/silviaterra/ckhb69dl509dr19mu7iio922e",
    label: "Topography",
  },
  road: {
    url: "mapbox://styles/mapbox/light-v9",
    label: "Road",
  },
}

export const ACRES_PER_SQUARE_METER = 0.000247105

export const NOTIFICATIONS = {
  invite_inaccessible: "invite_inaccessible",
  pd_invite: "pd_invite",
  property_partial_coverage: "property_partial_coverage",
  assessment_request_period_closed: "assessment_request_period_closed",
  ineligible_for_payment: "ineligible_for_payment",
  payment_delivered: "payment_delivered",
  cycle_postponed: "cycle_postponed",
  change_detected: "change_detected",
}
