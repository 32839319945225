import { MouseEvent, MouseEventHandler, ReactNode, useContext } from "react"
import {
  publicApiType,
  ScrollMenu,
  VisibilityContext,
} from "react-horizontal-scrolling-menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

import ProjectCard from "./ProjectCard"
import useDrag from "../hooks/useDrag"
import usePreventBodyScroll from "../hooks/usePreventBodyScroll"
import { onWheel } from "../shared/utils"
import { ProgramType } from "../types/program"
import { DialogStateReturn } from "reakit"

interface ArrowTypes {
  onClick: () => void
  icon: IconDefinitionSvg
}

interface SimilarProgramsSliderTypes {
  programs: ProgramType[]
  className?: string
  cardClassName?: string
  header: ReactNode
  showMinimalInfo?: boolean
  eligibilityDialog?: DialogStateReturn | null
}

const Arrow = ({ onClick, icon }: ArrowTypes) => (
  <button
    type="button"
    onClick={onClick}
    className="similar-programs-slider-arrow"
  >
    <FontAwesomeIcon icon={icon} />
  </button>
)

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext)

  if (isFirstItemVisible) {
    return null
  }

  return <Arrow onClick={() => scrollPrev()} icon={faArrowLeft} />
}

const RightArrow = () => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

  if (isLastItemVisible) {
    return null
  }

  return <Arrow onClick={() => scrollNext()} icon={faArrowRight} />
}

const SimilarProgramsSlider = ({
  programs = [],
  className = "",
  cardClassName = "",
  header,
  showMinimalInfo = false,
  eligibilityDialog = null,
}: SimilarProgramsSliderTypes) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const { disableScroll, enableScroll } = usePreventBodyScroll()

  const handleDrag: (api: publicApiType) => MouseEventHandler<Element> =
    ({ scrollContainer }) =>
    (ev: MouseEvent) => {
      dragMove(ev, (posDiff: number) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff
        }
      })
    }

  return (
    <>
      <div
        className={cx("similar-programs-slider mb-6 sm:mb-24", className)}
        onMouseEnter={disableScroll}
        onMouseLeave={() => {
          enableScroll()
          dragStop()
        }}
      >
        {header}

        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onMouseDown={() => dragStart}
          onMouseUp={() => dragStop}
          onMouseMove={handleDrag}
          onWheel={onWheel}
        >
          {programs.map((program) => (
            <div
              className="flex w-208 md:w-348 lg:w-312 xl:w-344"
              key={program.id}
            >
              <ProjectCard
                className={cx(cardClassName, {
                  "pointer-events-none": dragging,
                })}
                programData={program}
                showMinimalInfo={showMinimalInfo}
                onClick={eligibilityDialog?.hide}
              />
            </div>
          ))}
        </ScrollMenu>
      </div>
    </>
  )
}

export default SimilarProgramsSlider
