import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { coerceNullDataValues, useAccountQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { getProjectList } from "../../../api/data"

export function useProjectList<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query = useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "projects"],
    () => getProjectList(accountId),
    config
  )

  const transformedData = query.data
    ? coerceNullDataValues(query.data)
    : query.data

  return {
    ...query,
    data: transformedData,
  } as UseQueryResult<TData, TError>
}
