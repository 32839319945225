import { Query, QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"
import { AxiosResponse } from "axios"

import { updateAccountProperty } from "../../../api/data"
import { matchesQueryPattern, useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export function useUpdateAccountProperty<
  TData extends AxiosResponse<any>,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => updateAccountProperty(accountId, values),
    {
      _onSuccess: ({ data }) => {
        // Invalidate notifications
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })

        // Invalidate derived data
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-cycles"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })

        // Invalidate property
        // DEV: The code below was written with `["accounts", accountId, "cycles", *, "property"]`
        //   and possibly [..., "property", "preview", *] in mind
        // DEV: By providing a function, we need to also check `isActive()`, otherwise it will refetch inactive as well
        //   https://github.com/tannerlinsley/react-query/blob/v2.26.2/src/core/queryCache.ts#L155-L185
        //   For good measure, we also remove any unused queries which are also now out-of-date
        //   https://app.asana.com/0/1198952737412966/1201081265306151/f
        // DEV: We invalidate `preview` query key since dependent behavior could exist in the future (e.g. `is_different: true`)
        const queryPattern = ["accounts", accountId, "property"]
        queryClient.invalidateQueries({
          predicate: (query: Query) =>
            query.state.status === "success" &&
            matchesQueryPattern(query, queryPattern),
        })
        queryClient.removeQueries({
          predicate: (query: Query) =>
            query.state.status !== "success" &&
            matchesQueryPattern(query, queryPattern),
        })
        // Set the key we do know though
        // DEV: Use `setQueryData` (or `removeQueries`) as invalidate keeps stale data (which is bad for dashboard redirects)
        //   Reproduce error by using `invalidateQueries` + complete fresh onboarding, see redirected back to onboarding despite completion
        queryClient.setQueryData(["accounts", accountId, "property"], data)
      },
      ...config,
    }
  )
}
