import { useQueryClient } from "@tanstack/react-query"
import { AccordionItemPanel } from "react-accessible-accordion"

import HelpfulBtn from "./HelpfulBtn"
import { useMarkAnswerHelpful, useUnmarkAnswerHelpful } from "../../../hooks"

interface AnswerTypes {
  answer: string
  authorName: string
  createdAt: string
  helpfulCount: number
  userMarkedHelpful: boolean
  projectId: string
  questionId: number
  answerId: number
  isAnswerAuthor: boolean
}

const Answer = ({
  answer,
  authorName,
  createdAt,
  helpfulCount,
  userMarkedHelpful,
  projectId,
  questionId,
  answerId,
  isAnswerAuthor,
}: AnswerTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: markAnswerHelpful } = useMarkAnswerHelpful(
    queryClient,
    projectId,
    questionId,
    answerId
  )

  const { mutateAsync: unmarkAnswerHelpful } = useUnmarkAnswerHelpful(
    queryClient,
    projectId,
    questionId,
    answerId
  )

  const handleHelpfulClick = async () => {
    if (userMarkedHelpful) {
      await unmarkAnswerHelpful({})
    } else {
      markAnswerHelpful({})
    }
  }

  return (
    <AccordionItemPanel className="bg-cloud-300 border border-cloud-50 rounded-lg p-4 mt-4">
      <p className="text-base leading-130 tracking-0.32 text-charcoal-500">
        <span className="font-bold">A: </span>
        {answer}
      </p>

      <p className="font-semibold text-sm text-charcoal-500 leading-130 tracking-0.32 mt-2">
        by {authorName} | {createdAt}
      </p>

      <div className="flex items-center mt-6">
        <HelpfulBtn
          handleHelpfulClick={() => {
            handleHelpfulClick()
          }}
          isAnswerAuthor={isAnswerAuthor}
          userMarkedHelpful={userMarkedHelpful}
        />
        <span className="text-sm text-dusk leading-130 tracking-0.14">
          {helpfulCount} found helpful
        </span>
      </div>
    </AccordionItemPanel>
  )
}

export default Answer
