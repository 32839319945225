import { QueryClient } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import { updateProfile } from "../../../api/data"
import { useMutationWithRefresh } from "../utils"
import { FormikValues } from "formik"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export function useUpdateProfile<
  TData extends AxiosResponse<any>,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
) {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    updateProfile,
    {
      _onSuccess: ({ data }) => {
        // DEV: We allow preventing updating cache for scenarios like upgrading `profile_type`
        //   If we did, then it'd cascade a bunch of failing 500 requests for `_single` despite being MA
        if (!config.dontUpdateCache) {
          // Remove account data immediately (since can receive propagation of fields (e.g. phone number))
          //   and during onboarding, we need this data immediately for `initialValues`
          // DEV: We'd also invalidate notifications but this clears that out as well
          queryClient.invalidateQueries({ queryKey: ["accounts"] })
          queryClient.setQueryData(["profile"], data)
        }
      },
      ...config,
    }
  )
}
