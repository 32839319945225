import { useMemo } from "react"
import { findKey } from "lodash"

import { ContentCard } from "../../components/Card"
import HelperCard from "../../components/HelperCard"
import NaturalCapitalPortfolioQuizCard from "./NaturalCapitalPortfolioQuizCard"
import { allProgramTypes } from "../Onboarding/helpers"
import { EligibilityQuizzes } from "@/types/eligibility"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../../types/constants"

interface NaturalCapitalPortfolioTypes {
  quizzesData: EligibilityQuizzes
}

const NaturalCapitalPortfolio = ({
  quizzesData,
}: NaturalCapitalPortfolioTypes) => {
  const quizCardData = useMemo(() => {
    return allProgramTypes
      .map((type) => {
        const quizCategory = findKey(
          quizzesData,
          (d) => d.project_type === type
        ) as EligibilityQuizCategoryType

        const completed = quizzesData[quizCategory]?.completed
        const numEligiblePrograms =
          quizzesData[quizCategory]?.num_eligible_projects

        let potentialEarnings: number | null | undefined = 0 // default value
        if (type === ProgramCategoryType.timber) {
          potentialEarnings =
            quizzesData[quizCategory]?.project_type_stats
              ?.potential_earnings_per_acre?.avg
        } else if (numEligiblePrograms > 0) {
          potentialEarnings = quizzesData[quizCategory]?.project_type_stats
            ?.potential_earnings_per_acre?.max as number
        }

        return {
          quizCategory,
          completed,
          projectType: type,
          potentialEarnings,
          numEligiblePrograms,
        }
      })
      .filter((d) => {
        return Boolean(d.quizCategory) && d.quizCategory !== "advisory_services"
      })
      .sort((a, b) => {
        return a.potentialEarnings === b.potentialEarnings
          ? 0
          : a.potentialEarnings
            ? -1
            : 1
      })
  }, [quizzesData])

  return (
    <ContentCard>
      <h4 className="text-overline">Your Natural capital Portfolio</h4>

      <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mt-2">
        Select a category to complete your eligibility assessment, refine
        potential earnings, and view eligible programs.
      </p>

      <div className="grid grid-cols-1 xl:grid-cols-2 3.5xl:grid-cols-3 gap-6 mt-4">
        {quizCardData.map((cardData) => {
          return cardData.quizCategory ? (
            <NaturalCapitalPortfolioQuizCard
              key={cardData.projectType}
              quizCategory={cardData.quizCategory}
              completed={cardData.completed}
              projectType={cardData.projectType}
              potentialEarnings={cardData.potentialEarnings}
              numEligiblePrograms={cardData.numEligiblePrograms}
              className={`your-ncp-quiz-card your-ncp-${cardData.projectType}`}
            />
          ) : null
        })}
      </div>

      <HelperCard className="mt-4">
        Earnings estimates are based on active program offers and estimated
        eligible acreage. NCX recommends consulting a forester for a more
        accurate timber value estimate. Estimates are not a guarantee of returns
        and are not financial advice.{" "}
      </HelperCard>
    </ContentCard>
  )
}

export default NaturalCapitalPortfolio
