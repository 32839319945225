import { ReactNode } from "react"
import assert from "assert"
import cx from "classnames"
import { basename } from "path"
import JSZip, { JSZipObject } from "jszip"

import { useAccountDocumentActionURL } from "../hooks"
import { accountCycleStatusIsAtLeast } from "../shared/utils"
import { AccountCycleTypes } from "../types/accountCycles"

export interface DocumentLinkBlockTypes {
  children: ReactNode
  prefixContent?: ReactNode
  title?: string
  to?: string
  truncate?: boolean
  [key: string]: any
}

export interface DocumentLinkInlineTypes {
  children: ReactNode
  to: string
  [key: string]: any
}

export interface DocumentLinkTypes {
  variant?: "block" | "inline"
  [key: string]: any
}

export interface CommonDocumentLinkTypes {
  accountId: string
  accountCycle: AccountCycleTypes
  [key: string]: any
}

export interface OwnershipDocumentLinksTypes {
  downloadedZipfile: JSZip
  downloadLocalFile: (file: JSZip.JSZipObject) => void
  [key: string]: any
}

const DocumentLinkBlock = ({
  children,
  prefixContent,
  title,
  to,
  truncate,
  ...props
}: DocumentLinkBlockTypes) => {
  if (truncate) {
    assert(title, "`title` attribute is required for truncation, for a11y")
  }

  return (
    // DEV: If we need swap this for a button ever, then that's some strange UX
    //   We should always have some redirect URL on the API or know the link already
    <a
      className="border rounded border-cloud p-5 block"
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      {prefixContent}

      <span className="flex items-center">
        <span className="flex-row justify-items w-2/3 text-leaf font-semibold">
          <span className="shrink-0">
            <i className="fas fa-file-alt" />
          </span>

          <span
            className={cx("flex-auto ml-3", { truncate: truncate })}
            title={title}
          >
            {children}
          </span>
        </span>
        <span className="w-1/3 flex justify-end">
          <span className="text-leaf font-normal ml-4">
            <i className="fa fa-download fa-sm" /> Download
          </span>
        </span>
      </span>
    </a>
  )
}

const DocumentLinkInline = ({
  children,
  to,
  ...props
}: DocumentLinkInlineTypes) => {
  return (
    <a
      className="link"
      href={to}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    >
      <i className="fas fa-file-alt mr-1" />
      {children}
    </a>
  )
}

const DocumentLink = ({
  variant = "block",
  children,
  to,
  ...props
}: DocumentLinkTypes) => {
  const DocumentLinkComponent =
    variant === "inline" ? DocumentLinkInline : DocumentLinkBlock

  return (
    <DocumentLinkComponent to={to} {...props}>
      {children}
    </DocumentLinkComponent>
  )
}

export const ERDocumentLink = ({
  accountId,
  accountCycle,
  ...props
}: CommonDocumentLinkTypes) => {
  const url = useAccountDocumentActionURL({
    accountId,
    cycleKey: accountCycle.cycle_key,
    documentType: "eligibility_report",
    action: "view",
  })

  return (
    <DocumentLink to={url} {...props}>
      Eligibility Report
    </DocumentLink>
  )
}

export const NSADocumentLink = ({
  accountId,
  accountCycle,
  ...props
}: CommonDocumentLinkTypes) => {
  const url = useAccountDocumentActionURL({
    accountId,
    cycleKey: accountCycle.cycle_key,
    documentType: "ncapx_sale_agreement",
    action: "view",
  })

  const filenamePrefix = accountCycleStatusIsAtLeast(
    accountCycle,
    "bid_accepted"
  )
    ? "Accepted"
    : "Signed"

  const filename = filenamePrefix + " NCX Seller Agreement"

  return (
    <DocumentLink to={url} {...props}>
      {filename}
    </DocumentLink>
  )
}

export const OwnershipDocumentLinks = ({
  downloadedZipfile,
  downloadLocalFile,
  ...props
}: OwnershipDocumentLinksTypes) => {
  const OWNERSHIP_VERIFICATION_FOLDER = "ownership-documents"
  const getZipfileFiles = (zipfile: JSZip) => {
    const folder = zipfile.folder(OWNERSHIP_VERIFICATION_FOLDER)
    if (!folder) {
      return []
    }
    return Object.values(folder.files).filter((file) => file.dir === false)
  }

  const files = getZipfileFiles(downloadedZipfile)

  return (
    <div className="space-y-2">
      {files.map((file, index) => {
        const filepath = file.name // "ownership-documents/example.jpg" (JSZip misnomers filepath as filename)
        const filename = basename(filepath)
        // ASANA: remove this check once zipfile scrubbing to remove dotfiles is in place API-side.
        // https://app.asana.com/0/1198952737412966/1204197939296091/f
        if (
          !["__MACOSX", ".DS_Store"].includes(filename) &&
          !filename.startsWith("._")
        ) {
          return (
            <DocumentLinkBlock
              as="button"
              key={index}
              onClick={() => downloadLocalFile(file)}
              {...props}
            >
              {filename}
            </DocumentLinkBlock>
          )
        } else return null
      })}
    </div>
  )
}

export const RRDocumentLink = ({
  accountId,
  accountCycle,
  ...props
}: CommonDocumentLinkTypes) => {
  const url = useAccountDocumentActionURL({
    accountId,
    cycleKey: accountCycle.cycle_key,
    documentType: "results_report",
    action: "view",
  })

  return (
    <DocumentLink to={url} {...props}>
      Results Report
    </DocumentLink>
  )
}

export const RRReviewDocumentLink = ({
  accountId,
  accountCycle,
  ...props
}: CommonDocumentLinkTypes) => {
  const url = useAccountDocumentActionURL({
    accountId,
    cycleKey: accountCycle.cycle_key,
    documentType: "results_report_review",
    action: "view",
  })

  return (
    <DocumentLink to={url} {...props}>
      Results Report Review
    </DocumentLink>
  )
}
