import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getUserPosition } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useUserCurrentPosition<TData, TError>(
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useQuery({
    queryKey: ["user-position"],
    queryFn: getUserPosition,
    ...config,
  })
}
