import { Link } from "react-router-dom"
import { useDialogState } from "reakit/Dialog"
import { DateTime } from "luxon"

import { ButtonPair } from "../../components/ButtonPair"
import ThreadNotification from "./ThreadNotification"
import Message from "./Message"
import DeclineModal from "./DeclineModal"
import AcceptModal from "./AcceptModal"
import NCX from "../../images/ncx-logo.png"
import { LANDOWNER_STATUS, NOTIFICATIONS } from "../../shared/constants"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import InvitationEligibilityModal from "./InvitationEligibilityModal"
import {
  NotificationStatusType,
  NotificationTypes,
} from "@/types/notifications"
import { Profile } from "@/types"
import { AccountTypes } from "@/types/account"
import { AttestationsType } from "@/types/attestations"
import { QueryStatus } from "@tanstack/react-query"

interface MessageThreadContentTypes {
  activeThread: NotificationTypes | undefined
  profile: Profile | undefined
  account: AccountTypes | undefined
  attestationsData: AttestationsType | undefined
  attestationsStatus: QueryStatus
}

const MessageThreadContent = ({
  activeThread,
  profile,
  account,
  attestationsData,
  attestationsStatus,
}: MessageThreadContentTypes) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const acceptedDialog = useDialogState({ animated: true })
  const declineDialog = useDialogState({ animated: true })
  const attestationsDialog = useDialogState({ animated: true })

  const btnDisabled =
    activeThread?.extra_fields?.badge_display ===
      LANDOWNER_STATUS.request_information ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.under_contract ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.request_information ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.not_interested ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.lo_not_interested

  if (activeThread === null || activeThread === undefined) {
    return null
  }

  let status: NotificationStatusType | null = null
  if (
    activeThread?.extra_fields?.badge_display ===
      LANDOWNER_STATUS.request_information ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.under_contract ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.request_information
  ) {
    status = "accepted"
  } else if (
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.not_interested ||
    activeThread?.extra_fields?.landowner_status ===
      LANDOWNER_STATUS.lo_not_interested
  ) {
    status = "declined"
  }

  const date = DateTime.fromISO(activeThread?.created_at).toFormat(
    "LLLL dd, hh:mm a"
  )

  if (activeThread.key === NOTIFICATIONS.change_detected) {
    return (
      <Message date={date} img={NCX}>
        <p className="text-base leading-130 tracking-0.32 mt-2">
          We've detected some recent changes in your tree cover and wanted to
          make sure you're informed. Check your{" "}
          <Link to="/" className="link">
            dashboard
          </Link>{" "}
          to see what's changed and explore next steps for your forest.
        </p>
      </Message>
    )
  }

  return (
    <>
      <ThreadNotification
        status={status}
        url={
          isMultiAccount
            ? `/accounts/${accountId}/programs/${activeThread?.extra_fields?.project_id}`
            : `/programs/${activeThread?.extra_fields?.project_id}`
        }
      />

      <Message
        data={activeThread?.extra_fields}
        date={date}
        img={activeThread?.extra_fields?.image_url}
      >
        <p className="text-base leading-130 tracking-0.32 break-words">
          {activeThread?.extra_fields?.message}
        </p>
      </Message>

      <hr className="ml-[76px] mr-4" />

      <Message date={date} img={NCX}>
        <p className="text-base leading-130 tracking-0.32">
          Do you want to connect with {activeThread?.extra_fields?.project_name}
          ? Accepting will send them your contact information so they can reach
          out directly.
        </p>

        <p className="text-base leading-130 tracking-0.32 mt-2">
          If you decline, this message will be moved to Read and this program
          will be marked as not interested.
        </p>
      </Message>

      <div className="ml-[84px] mr-[34px] mb-[31px]">
        <ButtonPair
          primary={
            <button
              type="button"
              onClick={acceptedDialog.show}
              disabled={btnDisabled}
            >
              Accept
            </button>
          }
          secondary={
            <button
              type="button"
              onClick={declineDialog.show}
              className="bg-white"
              disabled={btnDisabled}
            >
              Decline
            </button>
          }
        />
      </div>

      <DeclineModal
        dialog={declineDialog}
        accountId={accountId}
        projectId={activeThread?.extra_fields?.project_id?.toString() as string}
        sender={activeThread?.extra_fields?.sender}
      />

      <AcceptModal
        dialog={acceptedDialog}
        attestationsDialog={attestationsDialog}
        accountId={accountId}
        projectId={activeThread?.extra_fields?.project_id?.toString() as string}
        sender={activeThread?.extra_fields?.sender}
        badgeDisplay={activeThread?.extra_fields?.badge_display}
        profile={profile}
      />

      {attestationsStatus === "success" ? (
        <InvitationEligibilityModal
          dialog={attestationsDialog}
          attestationsData={attestationsData}
          accountId={accountId}
          projectId={
            activeThread?.extra_fields?.project_id?.toString() as string
          }
          accountRole={account?.role}
          sender={activeThread?.extra_fields?.sender}
        />
      ) : null}
    </>
  )
}

export default MessageThreadContent
