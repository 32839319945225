import React, { ComponentType, useEffect, useState } from "react"
import assert from "assert"
import JSZip from "jszip"

import { useAxiosData } from "../../hooks"
import { downloadJSZipObject } from "../../shared/utils"
import { Toast } from "../../components/Toast"
import { OwnershipVerificationTypes } from "../../types/ownershipVerification"
import { OwnershipDocumentLinksTypes } from "../../components/DocumentLink"

interface UploadedDocumentsSectionTypes {
  documentComponents: ComponentType<OwnershipDocumentLinksTypes>[]
  ownershipDocuments: OwnershipVerificationTypes | undefined
}

const UploadedDocumentsSection = ({
  documentComponents,
  ownershipDocuments,
}: UploadedDocumentsSectionTypes) => {
  const zipfileUrl = ownershipDocuments?.zipfile_url

  const [downloadedZipfile, setDownloadedZipfile] = useState<JSZip | null>(null)

  const { data: zipfileData, status: zipfileDataStatus } = useAxiosData<
    ArrayBuffer,
    Error
  >(
    { method: "get", url: zipfileUrl, responseType: "arraybuffer" },
    {
      enabled: !!zipfileUrl && !downloadedZipfile,
    }
  )

  const zipfileLoading = downloadedZipfile === null

  useEffect(() => {
    if (downloadedZipfile === null && zipfileDataStatus !== "pending") {
      const newZipfile = new JSZip()

      if (zipfileDataStatus === "error") {
        Toast.error(`Failed to load URL: ${zipfileUrl}`)
      } else if (zipfileDataStatus === "success") {
        newZipfile.loadAsync(zipfileData).then(() => {
          setDownloadedZipfile(newZipfile)
        })
      } else {
        throw new Error(`Unexpected status: ${zipfileDataStatus as string}`)
      }
    }
  }, [downloadedZipfile, zipfileDataStatus, zipfileUrl, zipfileData])

  let noDocumentText
  if (!ownershipDocuments?.zipfile_url) {
    noDocumentText = <>You haven't uploaded any documents yet.</>
  } else {
    assert.notEqual(documentComponents.length, 0)
  }

  return (
    <div className="mb-8">
      <div className="space-y-2">
        <p className="text-overline">Uploaded</p>
        {noDocumentText ? (
          <p>{noDocumentText}</p>
        ) : zipfileLoading ? (
          <p>Loading Files</p>
        ) : (
          <>
            {documentComponents.map((DocumentComponent) => (
              // DEV: Using component function name as our key (e.g. ERDocumentLink)
              <div key={DocumentComponent.name}>
                <DocumentComponent
                  downloadedZipfile={downloadedZipfile}
                  downloadLocalFile={(file) => {
                    downloadJSZipObject(file)
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default UploadedDocumentsSection
