import { shortenAcreage } from "../../../utils"

interface LandHeaderProps {
  acreage: number
  firstName: string | null | undefined
}

const LandHeader = ({ acreage, firstName }: LandHeaderProps) => {
  return (
    <div className="mb-4 text-black text-xl font-medium leading-7 tracking-0.4">
      {`${firstName}'s ${shortenAcreage(acreage)} Acres`}
    </div>
  )
}

export default LandHeader
