import { ChangeEvent } from "react"
import ReactSlider from "react-slider"
import { InputLabel } from "./Input"

interface RangeSliderInputTypes {
  id: string
  label: string
  ariaLabel: string
  value: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  min: number
  max: number
  step: number
}

interface RangeSliderTypes {
  id: string
  ariaLabel: string
  value: [number, number]
  onChange: (value: [number, number]) => void
  min?: number
  max?: number
  step?: number
  lowerInputLabel: string
  upperInputLabel: string
}

const RangeSliderInput = ({
  id,
  label,
  ariaLabel,
  value,
  onChange,
  min,
  max,
  step,
}: RangeSliderInputTypes) => (
  <div className="range-slider-control">
    <InputLabel className="leading-18px text-charcoal-500 mb-2" htmlFor={id}>
      {label}
    </InputLabel>

    <input
      id={id}
      aria-label={`${ariaLabel} lower input`}
      type="number"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      className="range-slider-input"
    />
  </div>
)

const RangeSlider = ({
  id,
  ariaLabel,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  lowerInputLabel,
  upperInputLabel,
}: RangeSliderTypes) => (
  <div>
    <div className="range-slider-top">
      <span>{min}</span>
      <span>{max}+</span>
    </div>

    <ReactSlider
      className="range-slider"
      thumbClassName="range-slider-thumb"
      trackClassName="range-slider-track"
      value={value}
      aria-label={[`${ariaLabel} lower thumb`, `${ariaLabel} upper thumb`]}
      renderThumb={(props) => {
        const { key, ...otherProps } = props
        return <div key={key} {...otherProps} />
      }}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      pearling
    />

    <div className="range-slider-input-group">
      <RangeSliderInput
        id={`${id}-lower-input`}
        label={lowerInputLabel}
        ariaLabel={`${ariaLabel} lower input`}
        value={value[0]}
        onChange={(e) => {
          const newValue = Number(e.target.value)
          onChange([newValue, value[1]])
        }}
        min={min}
        max={value[1] || max}
        step={step}
      />
      <span className="flex items-center h-10 self-end">to</span>
      <RangeSliderInput
        id={`${id}-upper-input`}
        label={upperInputLabel}
        ariaLabel={`${ariaLabel} upper input`}
        value={value[1]}
        onChange={(e) => {
          const newValue = Number(e.target.value)
          onChange([value[0], newValue])
        }}
        min={value[0] || min}
        max={max}
        step={step}
      />
    </div>
  </div>
)

export default RangeSlider
