import { Form, FormikProps } from "formik"

import { SubmitButton } from "../../components/SubmitButton"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ButtonPair } from "../../components/ButtonPair"
import EligibilityInterestModalFormNotification from "./EligibilityInterestModalFormNotification"
import EligibilityInterestModalFormInput from "./EligibilityInterestModalFormInput"
import { AccountRoleType } from "@/types/account"
import { DialogStateReturn } from "reakit"
import { AttestationsType } from "@/types/attestations"

interface EligibilityInterestModalFormTypes {
  accountRole: AccountRoleType | undefined
  formikProps: FormikProps<any>
  dialog: DialogStateReturn
  attestationsData: AttestationsType | undefined
  submitText?: string
  backText?: string
}

const EligibilityInterestModalForm = ({
  accountRole,
  formikProps,
  dialog,
  attestationsData,
  submitText = "Check Eligibility",
  backText = "Back to Program",
}: EligibilityInterestModalFormTypes) => (
  <Form className="inline">
    <div className="flex flex-col lg:flex-row-reverse md:justify-between">
      <EligibilityInterestModalFormNotification />
      <div className="w-full lg:max-w-624 mb-6">
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          {Object.values(attestationsData as AttestationsType)
            ?.sort((a, b) =>
              Number(a.attestation_id) > Number(b.attestation_id) ? 1 : -1
            )
            ?.map((attestation) => (
              <EligibilityInterestModalFormInput
                key={attestation.attestation_id}
                attestationData={attestation}
              />
            ))}
        </div>
      </div>
    </div>

    <hr className="border-charcoal-50 mb-6 lg:max-w-624 xl:max-w-664" />

    <ButtonPair
      // eslint-disable-next-line react/no-unstable-nested-components
      primary={(primaryProps) => (
        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <SubmitButton
            isSubmitting={formikProps.isSubmitting}
            disabled={!(formikProps.isValid && formikProps.dirty)}
            {...primaryProps}
          >
            {submitText}
          </SubmitButton>
        </ActionPermissionWrapper>
      )}
      secondary={
        <button type="button" onClick={dialog.hide} className="py-7px">
          {backText}
        </button>
      }
    />
  </Form>
)

export default EligibilityInterestModalForm
