import { Dialog, DialogBackdrop, DialogStateReturn } from "reakit/Dialog"
import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"

import { Toast } from "../../components/Toast"
import { CloseButton } from "../../components/CloseButton"
import FormComponent from "../ProjectDetails/EligibilityInterestModalForm"
import { useUpdateAccountProject, useUpdateAttestations } from "../../hooks"
import { LANDOWNER_STATUS } from "../../shared/constants"
import { genericErrMsg } from "../../api/auth"
import { AttestationsType } from "@/types/attestations"
import { AccountRoleType } from "@/types/account"

interface InvitationEligibilityModalTypes {
  dialog: DialogStateReturn
  attestationsData: AttestationsType | undefined
  accountId: string
  projectId: string
  accountRole: AccountRoleType | undefined
  sender: string | undefined
}

const validationSchema = (attestationsData: AttestationsType | undefined) => {
  const fields = {}
  const types = {
    bool: yup.bool().oneOf([true], "Required"),
    str: yup
      .string()
      .required("Please fill out this field")
      .max(500, "Maximum length exceeded, please limit to 500 characters"),
    float: yup.number().required("Enter in a number"),
    int: yup.number().integer().required("Enter in a number"),
    multiple_choice: yup.string().required("Choose an option"),
  }
  Object.values(attestationsData as AttestationsType)?.forEach((attestation) =>
    Object.assign(fields, {
      [attestation.attestation_id]: types[attestation.response_type],
    })
  )

  return yup.object().shape(fields)
}

const InvitationEligibilityModal = ({
  dialog,
  attestationsData,
  accountId,
  projectId,
  accountRole,
  sender,
}: InvitationEligibilityModalTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })
        Toast.success(`You accepted an invitation from ${sender}.`)
      },
      onError: (error: { message: string }) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const { mutateAsync: updateAttestations } = useUpdateAttestations(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: async () => {
        await updateAccountProject({
          status: LANDOWNER_STATUS.request_information,
        })
      },
      onError: (error: { detail: string }) => {
        dialog.hide()
        Toast.error(
          error?.detail || "An error occurred while adding your information."
        )
      },
    }
  )

  const onAttestationSubmit = async (values: FormikValues) => {
    const attData = Object.entries(values)?.map(([attestation_id, value]) => ({
      attestation_id: Number(attestation_id),
      value,
    }))
    const postData = { project_id: projectId, att_data: attData }
    await updateAttestations(postData)
  }

  const initialValues = {}
  const valTypes = {
    bool: false,
    str: "",
    float: 0,
    int: 0,
    multiple_choice: "",
  }
  Object.values(attestationsData as AttestationsType)?.forEach((attestation) =>
    Object.assign(initialValues, {
      [attestation.attestation_id]:
        attestation?.value || valTypes[attestation.response_type],
    })
  )

  return (
    <DialogBackdrop {...dialog} className="st-backdrop">
      <Dialog
        {...dialog}
        aria-label="Complete eligibility assessment"
        className="dialog-full"
        hideOnEsc={true}
        hideOnClickOutside={false}
      >
        <div>
          <div className="container xl:max-w-1120 pt-6 pb-16 lg:pt-16">
            <div className="flex flex-col-reverse lg:flex-row justify-between gap-4 mb-7 lg:mb-10">
              <div className="max-w-643">
                <h2 className="flex-auto text-xl leading-6 mb-2">
                  Add land details to complete your eligibility assessment
                </h2>

                <p className="text-base leading-snug">
                  Your land may be eligible based on information you've
                  previously provided, but we need more details to complete the
                  assessment for this project.
                </p>
              </div>

              <CloseButton
                className="shrink-0 justify-self-end self-end lg:self-auto rounded focus:outline-none text-charcoal-500 font-bold lg:absolute lg:top-64 lg:right-64"
                aria-label="Close modal"
                onClick={dialog.hide}
              />
            </div>

            <div>
              {dialog.visible === true ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema(attestationsData)}
                  onSubmit={onAttestationSubmit}
                >
                  {(formikProps) => (
                    <FormComponent
                      formikProps={formikProps}
                      dialog={dialog}
                      attestationsData={attestationsData}
                      accountRole={accountRole}
                      submitText="Check and Accept"
                      backText="Back to Inbox"
                    />
                  )}
                </Formik>
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </DialogBackdrop>
  )
}

export default InvitationEligibilityModal
