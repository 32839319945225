import * as Yup from "yup"

import { privateIndividualFamilyForestOwnerDB } from "../../fixtures/index"
import { FormikProps } from "formik"
import { AccountTypes } from "@/types/account"
import { ChangeEvent } from "react"

export const requiresLegalEntityName = (
  ownership_type: string | undefined | null
): boolean =>
  !!ownership_type && ownership_type !== privateIndividualFamilyForestOwnerDB

export const validationSchema = Yup.object().shape({
  // ownership info
  ownership_type: Yup.string().required("Please provide ownership type"),
  legal_entity_name: Yup.string().when(
    "ownership_type",
    (ownership_type: any, schema: Yup.StringSchema<string | undefined>) =>
      requiresLegalEntityName(ownership_type as string | undefined | null)
        ? schema.required("Please provide legal entity name")
        : schema
  ),

  // consulting forester info (all optional)
  works_with_consulting_forester: Yup.boolean(),
  consulting_forester_name: Yup.string(),
  consulting_forester_company: Yup.string(),
  consulting_forester_phone_number: Yup.string(),
  consulting_forester_email: Yup.string().email("Please provide a valid email"),
  consulting_forester_was_referrer: Yup.boolean(),
})

interface ResetDependentFieldsTypes {
  formikProps: FormikProps<AccountTypes>
  evt: ChangeEvent<HTMLInputElement>
}

export const resetDependentFields = ({
  formikProps,
  evt,
}: ResetDependentFieldsTypes) => {
  const dependentFields = ["ownership_type", "legal_entity_name"]

  for (const fieldName of dependentFields) {
    if (evt.target.name === fieldName) {
      formikProps.setFieldTouched(fieldName, false)
    }
  }
}
