import { Dispatch, SetStateAction } from "react"
import { AxiosError } from "axios"
import { useParams } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import * as yup from "yup"
import cx from "classnames"

import EligibilityQuizHeader from "./EligibilityQuizHeader"
import EligibilityQuizFormInput from "./EligibilityQuizFormInput"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useAccountId, useUpdateAttestationQuiz } from "../../hooks"
import EligibilityQuizBtnContainer from "./EligibilityQuizBtnContainer"
import {
  AttestationQuizesType,
  QuizAttestationType,
  QuizDataTypes,
} from "../../types/eligibilityQuiz"
import { ProgramTypeStats } from "../../types/eligibility"
import {
  EligibilityQuizCategoryType,
  ValidQuizCategories,
} from "../../types/constants"

interface EligibilityQuizFormTypes {
  attestations: QuizAttestationType[] | undefined
  quizData: QuizDataTypes | undefined
  quizStep: number
  setQuizStep: Dispatch<SetStateAction<number | undefined>>
  setCompleted: Dispatch<SetStateAction<boolean>>
  setNumEligible: Dispatch<SetStateAction<number | undefined>>
  setProjCategoryStats: Dispatch<SetStateAction<ProgramTypeStats | undefined>>
  isMobile: boolean
  imageUrl: string | undefined
}

type FormValues = Record<number, boolean | string | number>

const validationSchema = (attestations: QuizAttestationType[]) => {
  const fields = {}
  const types = {
    bool: yup.bool().oneOf([true], "Required"),
    str: yup
      .string()
      .required("Please fill out this field")
      .max(500, "Maximum length exceeded, please limit to 500 characters"),
    float: yup.number().required("Enter in a number"),
    int: yup.number().integer().required("Enter in a number"),
    multiple_choice: yup.string().required("Choose an option"),
  }

  Object.values(attestations)?.forEach((attestation) =>
    Object.assign(fields, {
      [attestation?.id]: types[attestation.response_type],
    })
  )

  return yup.object().shape(fields)
}

const isValidQuizCategory = (
  category: string | undefined
): category is EligibilityQuizCategoryType => {
  return ValidQuizCategories.includes(category as EligibilityQuizCategoryType)
}

const EligibilityQuizForm = ({
  attestations,
  quizData,
  quizStep,
  setQuizStep,
  setCompleted,
  setNumEligible,
  setProjCategoryStats,
  isMobile,
  imageUrl,
}: EligibilityQuizFormTypes) => {
  const { quizCategory } = useParams()
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const questionNum = typeof quizStep === "number" ? quizStep + 1 : null
  const questionsNum = attestations?.length

  const initialValues: FormValues = attestations?.reduce((acc, attestation) => {
    const values = {
      bool: false,
      str: "",
      float: 0,
      int: 0,
      multiple_choice: "",
    }

    return {
      ...acc,
      [attestation?.id]: values[attestation.response_type] || "",
    }
  }, {}) as FormValues

  const { mutateAsync: updateAttestationQuiz } = useUpdateAttestationQuiz(
    queryClient,
    accountId,
    quizCategory as EligibilityQuizCategoryType,
    {
      onSuccess: (
        data: Record<EligibilityQuizCategoryType, AttestationQuizesType>
      ) => {
        if (isValidQuizCategory(quizCategory)) {
          setCompleted(data[quizCategory].completed)
          setNumEligible(data[quizCategory].num_eligible_projects)
          setProjCategoryStats(
            data[quizCategory]?.project_type_stats as ProgramTypeStats
          )
        }
      },
      onError: (error: AxiosError) => {
        Toast.error(
          error?.message || "An error occurred while adding your information."
        )
      },
    }
  )

  const handleSubmit = async (values: FormValues) => {
    const attData = Object.entries(values)?.map(([attestation_id, value]) => ({
      attestation_id: Number(attestation_id),
      value,
    }))

    const postData = { quiz_type: quizData?.quiz_type, att_data: attData }

    await updateAttestationQuiz(postData)

    window.scrollTo({
      top: 0,
      behavior: "instant",
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(attestations as QuizAttestationType[])}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const disabled =
          !attestations ||
          formikProps.values[attestations[quizStep]?.id] === "" ||
          formikProps.values[attestations[quizStep]?.id] === false ||
          formikProps.values[attestations[quizStep]?.id] === null ||
          formikProps.values[attestations[quizStep]?.id] === undefined

        if (!attestations) return null

        return (
          <Form>
            {attestations.map((attestation, index) => (
              <div
                key={attestation?.id}
                className={cx({ hidden: index !== quizStep })}
              >
                <EligibilityQuizHeader
                  eyebrow={`QUESTION ${questionNum} of ${questionsNum}`}
                  text={attestation.text}
                  tooltip={attestation.tooltip}
                  isMobile={isMobile}
                  imageUrl={imageUrl}
                  quizType={quizData?.quiz_type}
                />

                <EligibilityQuizFormInput attestation={attestation} />
              </div>
            ))}

            <EligibilityQuizBtnContainer>
              {attestations && quizStep === attestations.length - 1 ? (
                <SubmitButton
                  className="btn2 btn2-primary font-semibold md:self-start md:my-10"
                  isSubmitting={formikProps.isSubmitting}
                  disabled={disabled}
                >
                  Check Eligibility
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  className="btn2 btn2-primary font-semibold md:self-start md:my-10"
                  onClick={() => {
                    setQuizStep((prevQuizStep) =>
                      prevQuizStep !== undefined ? prevQuizStep + 1 : 0
                    )

                    window.scrollTo({
                      top: 0,
                      behavior: "instant",
                    })
                  }}
                  disabled={disabled}
                >
                  Next
                </button>
              )}
            </EligibilityQuizBtnContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default EligibilityQuizForm
