interface UnverifiedBannerTypes {
  cta_override_url?: string | null | undefined
}

const UnverifiedBanner = ({ cta_override_url }: UnverifiedBannerTypes) => (
  <div className="container px-5 py-6 xl:max-w-1120">
    <div className="card card-content rounded-none border-solid p-4 border-yellow-300 border-l-2">
      <div className="flex flex-row">
        <div className="flex-auto">
          <div className="mb-2">
            <h2 className="text-2xl mb-5 lg:mb-4.5">
              This program's details have not been verified by the developer.
            </h2>
          </div>
          <>
            {cta_override_url ? (
              <p className="leading-[140%] tracking-0.32 my-4">
                Some details may be missing or incomplete. Please visit the{" "}
                <a
                  href={cta_override_url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link"
                >
                  program website
                </a>{" "}
                for more information.
              </p>
            ) : (
              <p className="leading-[140%] tracking-0.32 my-4">
                Some details may be missing or incomplete. Please contact the
                program developer directly for more information.
              </p>
            )}
            <div className="mt-6 lg:mt-0">
              <a
                href="https://help.ncx.com/hc/en-us/articles/27881997151131-Program-Verification-Status"
                rel="noopener noreferrer"
                target="_blank"
                className="link"
              >
                Click here{" "}
              </a>
              to learn more about our program verification process.
            </div>
          </>
        </div>
      </div>
    </div>
  </div>
)

export default UnverifiedBanner
