import { Dispatch, SetStateAction, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import ProjectInterestComponent from "./ProjectInterestComponent"
import { genericErrMsg } from "../../api/auth"
import { useAccountId, useUpdateAccount } from "../../hooks"
import {
  ProjectDataTypes,
  ProjectInterestFormTypes,
  ProjectInterestKeysTypes,
} from "./types"

// DEV: Minimize loading spinner for form by receiving `initialValues` from parent
// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const ProjectInterestForm = ({
  onSuccess,
  submitText,
  accountData,
}: ProjectInterestFormTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const projectData: ProjectDataTypes = (({
    interested_in_reforestation,
    interested_in_harvested_deferral,
    interested_in_recreation,
    interested_in_biodiversity,
    interested_in_timber,
    interested_in_wildfire,
    interested_in_water,
    interested_in_regen_ag,
    interested_in_renewable_energy,
    interested_in_ncx_recommendations,
    interested_in_advisory_services,
  }) => ({
    interested_in_reforestation,
    interested_in_harvested_deferral,
    interested_in_recreation,
    interested_in_biodiversity,
    interested_in_timber,
    interested_in_wildfire,
    interested_in_water,
    interested_in_regen_ag,
    interested_in_renewable_energy,
    interested_in_advisory_services,
    interested_in_ncx_recommendations,
  }))(accountData)

  const [projects, setProjects] = useState<ProjectDataTypes>(projectData)

  // This needs to be updated when we show project info to MA users
  const { mutateAsync: updateAccount, status: updateAccountStatus } =
    useUpdateAccount(queryClient, accountId, {
      onSuccess,
      onError: (error: { detail?: string }) => {
        setErrorMessage(error?.detail || genericErrMsg)
      },
    })

  const handleSubmit = async () => {
    setErrorMessage(null)
    // update accountData with the updated project data
    for (const projectType in projects) {
      // @ts-ignore
      accountData[projectType] = projects[projectType]
    }
    await updateAccount(accountData)
  }

  const onProjectClick = (
    projectDescription: ProjectInterestKeysTypes,
    selectedProjects: Record<ProjectInterestKeysTypes, boolean>,
    setProjects: Dispatch<SetStateAction<ProjectDataTypes>>
  ) => {
    const newSelectedProjects = { ...selectedProjects }
    newSelectedProjects[projectDescription] =
      !newSelectedProjects[projectDescription]
    setProjects(newSelectedProjects)
  }

  return (
    <div className="flex mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3 justify-between">
      <div className="w-auto lg:max-w-664 md:order-1 md:col-span-full">
        <div>
          <p className="mb-2 text-sm text-neutral-dusk-500 italic leading-130">
            Select all programs you're interested in.{" "}
          </p>
        </div>
        <ProjectInterestComponent
          submitText={submitText}
          setProjects={setProjects}
          projects={projects}
          onProjectClick={onProjectClick}
          handleSubmit={() => {
            handleSubmit()
          }}
          errorMessage={errorMessage}
          status={updateAccountStatus}
        />
      </div>
    </div>
  )
}

export default ProjectInterestForm
