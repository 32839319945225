// will replace pages/ResetPassword.js
import React, { Dispatch, SetStateAction } from "react"
import { Formik } from "formik"
import * as Yup from "yup"

import * as auth from "../../api/auth"
import ResetPasswordFormComponent from "./ResetPasswordFormComponent"
import { SuccessCard } from "../../components/Card"
import { useResetPassword } from "../../hooks"
import { AuthDataTypes } from "../../types/auth"

interface ResetPasswordFormTypes {
  authData: AuthDataTypes
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
})

const ResetPasswordForm = ({
  authData,
  setAuthData,
}: ResetPasswordFormTypes) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [successVariables, setSuccessVariables] =
    React.useState<AuthDataTypes | null>(null)

  const { mutateAsync: resetPassword } = useResetPassword({
    onSuccess: (_data: unknown, variables: AuthDataTypes) => {
      setSuccessVariables(variables)
    },
    onError: (error: { detail?: string }) => {
      setErrorMessage(error?.detail || auth.genericErrMsg)
    },
  })

  const handleSubmit = async (values: AuthDataTypes) => {
    setErrorMessage(null)
    await resetPassword(values)
  }

  if (successVariables)
    return (
      <div className="space-y-4">
        <SuccessCard>
          <div className="space-y-2">
            <p>
              We've sent reset password instructions to{" "}
              <strong style={{ overflowWrap: "anywhere" }}>
                {successVariables.email}
              </strong>
              .
            </p>
            <p>
              Please follow the instructions to finish resetting your password.
            </p>
          </div>
        </SuccessCard>
      </div>
    )

  return (
    <Formik
      initialValues={{
        // DEV: Only use `email` to avoid sending `authData.password` to password reset endpoint
        email: authData.email || "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <ResetPasswordFormComponent
          {...formikProps}
          errorMessage={errorMessage}
          setAuthData={setAuthData}
        />
      )}
    </Formik>
  )
}

export default ResetPasswordForm
