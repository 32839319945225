import { Dispatch, SetStateAction } from "react"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import { ViewportTypes } from "@/types"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

export const handlePrevFeature = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: Dispatch<SetStateAction<ViewportTypes | null>>,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => {
  setActiveFeature((oldFeature) => {
    if (oldFeature === null || !parcelData) return oldFeature

    const previousValidIndex = oldFeature - 1 >= 0 ? oldFeature - 1 : oldFeature

    if (previousValidIndex !== oldFeature) {
      const feature = parcelData.features[previousValidIndex]
      if (feature) {
        setViewport(getViewportFromFeature(feature))
      }
    }

    return previousValidIndex
  })
}

export const handleNextFeature = (
  setActiveFeature: Dispatch<SetStateAction<number | null>>,
  setViewport: Dispatch<SetStateAction<ViewportTypes | null>>,
  parcelData: FeatureCollection<Geometry, Properties> | undefined
) => {
  setActiveFeature((oldFeature) => {
    if (oldFeature === null || !parcelData) return oldFeature // Ensure oldFeature is not null and parcelData exists

    const nextValidIndex =
      oldFeature + 1 < parcelData.features.length ? oldFeature + 1 : oldFeature

    if (nextValidIndex !== oldFeature) {
      const feature = parcelData.features[nextValidIndex]
      if (feature) {
        setViewport(getViewportFromFeature(feature))
      }
    }

    return nextValidIndex
  })
}
