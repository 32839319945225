import { UseMutationOptions } from "@tanstack/react-query"
import { AuthData, useSessionStore } from "../../../stores"

export interface ExtendWithAuthPersistenceConfig<TData = any, TError = unknown>
  extends UseMutationOptions<TData, TError> {
  onSuccess?: (data: TData, ...args: unknown[]) => void
}

export const _extendWithAuthPersistence = <
  TData extends AuthData = any,
  TError = unknown,
>(
  config: ExtendWithAuthPersistenceConfig<TData, TError>
): ExtendWithAuthPersistenceConfig<TData, TError> => {
  const { setAuthData } = useSessionStore((state) => state)
  return {
    ...config,
    // DEV: `function` is required for using `arguments`
    // DEV: `data` is the response from account creation, login, and refresh token in `bark_api`
    //   These all share the common signature of `{access (token), refresh (token), profile_type}`
    //   This abstraction allows us to save the same data for synchronous access via `setAuthData`
    onSuccess: (data: TData, ...args: unknown[]) => {
      setAuthData(data)
      if (config.onSuccess) {
        config.onSuccess(data, ...args)
      }
    },
  }
}
