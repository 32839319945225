import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useAccountQueryWithRefresh } from "../utils"
import { getNotifications } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useAccountNotifications<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh(
    queryClient,
    // DEV: Technically we should have `values` in the queryKey but this is an exception (see next lines)
    ["accounts", accountId, "notifications"],
    // DEV: This request is atypical (i.e. account_id being sent to API as a query parameter)
    //   Typically it's done via path (i.e. /accounts/:account_id/resource-name
    //   whereas this is /notifications?account_id=1234&is_global=false
    () => getNotifications({ is_global: false, account_id: accountId }),
    config
  )
}
