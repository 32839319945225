import LossDetectionStats from "./LossDetection/LossDetectionStats"
import LandCoverStats from "../../LandCoverDetails/LandCoverStats"
import { ActiveTabType } from "../Land"
import { AccountProperty } from "@/types/property"

interface LandStatsProps {
  activeTab: ActiveTabType
  property: AccountProperty
  isMember: boolean
}

const LandStats = ({ activeTab, property, isMember }: LandStatsProps) => {
  if (activeTab === "land-cover") {
    return <LandCoverStats landCoverDetails={property.landcover_data} />
  }
  const lossDataNotReady =
    property.change_data === null || property.change_data === undefined
  return (
    <LossDetectionStats
      date={property?.change_data ? property.change_data.date : "2024-11-11"}
      majorLoss={
        lossDataNotReady
          ? "-"
          : property?.change_data?.major_loss
            ? Number(property.change_data.major_loss.toFixed(1))
            : 0
      }
      minorLoss={
        lossDataNotReady
          ? "-"
          : property?.change_data?.minor_loss
            ? Number(property.change_data.minor_loss.toFixed(1))
            : 0
      }
      isMember={isMember}
    />
  )
}

export default LandStats
