import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { resendEmailVerification } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useResendEmailVerification = <
  TData = void,
  TError = unknown,
  TVariables = void,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    resendEmailVerification,
    config
  )
}
