interface GetPrevAndNextReturnType {
  prevPage: string
  nextPage: string
}

export const getPrevAndNext = (
  data: any[],
  id: number
): GetPrevAndNextReturnType => {
  const index = data?.findIndex((project) => project.id === id)
  const prevPage = data[index - 1]?.id ? data[index - 1]?.id : undefined
  const nextPage = data[index + 1]?.id ? data[index + 1]?.id : undefined
  return { prevPage, nextPage }
}
