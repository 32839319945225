import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import {
  _getAccountUserListMutationConfig,
  useMutationWithRefresh,
} from "../utils"
import { updateAccountUser } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { AccountUsers } from "@/types/accountUsers"

export function useUpdateAccountUser<
  TData extends AccountUsers,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  email: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => updateAccountUser(accountId, email, values),
    _getAccountUserListMutationConfig<TData, TError, TVariables>(queryClient, {
      action: "update",
      accountId,
      email,
      listQueryEagerMutateFn: (updatedAccountUser, oldAccountUsersPage) =>
        ({
          count: oldAccountUsersPage.count,
          object_list: oldAccountUsersPage.object_list.map((accountUser) =>
            accountUser.email === email ? updatedAccountUser : accountUser
          ),
        }) as TData,
      config,
    })
  )
}
