import { Dispatch, SetStateAction } from "react"
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog"

import SearchField from "../../../components/SearchField"

interface CommunityQAHeaderTypes {
  setSearchTerm: Dispatch<SetStateAction<string>>
  projectsNumber: number
  firstOnPageNo: number
  lastOnPageNo: number
  askAQuestionDialog: DialogStateReturn
}

const CommunityQAHeader = ({
  setSearchTerm,
  projectsNumber,
  firstOnPageNo,
  lastOnPageNo,
  askAQuestionDialog,
}: CommunityQAHeaderTypes) => (
  <div className="block md:flex md:flex-row gap-7 mt-6">
    <div className="grow">
      <SearchField
        onChange={setSearchTerm}
        placeholder="Search questions and answers"
      />

      <p className="text-sm text-dusk leading-130 tracking-0.14 mt-2 mb-6 md:mb-0">
        Displaying{" "}
        <span className="font-bold">
          {firstOnPageNo}
          {lastOnPageNo > 0 ? `-${lastOnPageNo}` : ""}
        </span>{" "}
        of <span className="font-bold">{projectsNumber}</span> questions
      </p>
    </div>

    <DialogDisclosure
      {...askAQuestionDialog}
      className="btn2 btn2-primary font-semibold md:self-start"
    >
      Ask a Question
    </DialogDisclosure>
  </div>
)

export default CommunityQAHeader
