import { IneligibilityReasonsType } from "@/types/constants"

export const ineligibilityReasonMap = {
  "not in project geography": "Not in program geography",
  "not enough acres meet minimum requirements":
    "Not enough acres meet program requirements",
  "no part of the property meets the project requirements":
    "No part of the property meets program requirements",
}

export function mapIneligibilityReason(
  ineligibilityReason: IneligibilityReasonsType
) {
  /* DEV: Convert undefined reason to sentence case */
  return (
    ineligibilityReasonMap[ineligibilityReason] ||
    ineligibilityReason.charAt(0).toUpperCase() + ineligibilityReason.slice(1)
  )
}
