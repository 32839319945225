import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface BackToTopTypes {
  scrollY: number
}

const BackToTop = ({ scrollY = 200 }: BackToTopTypes) => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollY) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scrollY])

  return (
    <div
      className={cx("back-to-top", {
        "back-to-top-visible": isVisible,
      })}
    >
      <button
        type="button"
        aria-label="back to top"
        className="back-to-top-button"
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>

      <span className="back-to-top-text">Back to top</span>
    </div>
  )
}

export default BackToTop
