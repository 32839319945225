import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { updateAccountProject } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { FormikValues } from "formik"

export const useUpdateAccountProject = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  projectId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh(
    queryClient,
    (values: TVariables) => updateAccountProject(accountId, projectId, values),
    {
      _onSuccess: () => {
        // Wipe out our list data and other ones which might hold a derived property
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "projects", projectId],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-cycles"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })
        queryClient.invalidateQueries({ queryKey: ["accounts", accountId] })
      },
      ...config,
    }
  )
}
