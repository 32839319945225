import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router-dom"
import cx from "classnames"

import { bellIcon } from "../../shared/icons"

interface NavBarInboxLinkTypes {
  partnersNav: boolean
  isMultiAccount: boolean
  accountId: string
  newMessages: boolean
  className?: string
  onClick: () => void
}

const NavBarInboxLink = ({
  partnersNav,
  isMultiAccount,
  accountId,
  newMessages,
  className,
  onClick,
}: NavBarInboxLinkTypes) => {
  if (partnersNav || (isMultiAccount && accountId === "_single")) {
    return null
  }

  return (
    <Link
      to={isMultiAccount ? `/accounts/${accountId}/inbox` : "/inbox"}
      className={cx(
        "h-16 flex flex-row text-white items-center font-semibold hover:bg-nav-active px-3",
        className
      )}
      onClick={onClick}
    >
      <span className="relative flex items-center">
        <FontAwesomeIcon icon={bellIcon as IconProp} size="lg" />
        {newMessages ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            className="absolute right-px top-px"
          >
            <circle cx="4" cy="4" r="4" fill="#D46565" />
          </svg>
        ) : null}
      </span>
    </Link>
  )
}

export default NavBarInboxLink
