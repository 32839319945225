import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import {
  useMutationWithRefresh,
  UseMutationWithRefreshConfig,
} from "../utils/useMutationWithRefresh"
import { _getAccountUserListMutationConfig } from "../utils"
import { removeAccountUser } from "../../../api/data"
import { AccountUsers } from "@/types/accountUsers"

export function useRemoveAccountUser<
  TData extends AccountUsers,
  TError,
  TVariables,
>(
  queryClient: QueryClient,
  accountId: string,
  email: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    () => removeAccountUser(accountId, email),
    _getAccountUserListMutationConfig<TData, TError, TVariables>(queryClient, {
      action: "remove",
      accountId,
      email,
      listQueryEagerMutateFn: (_, oldAccountUsersPage) =>
        ({
          count: oldAccountUsersPage.count - 1,
          object_list: oldAccountUsersPage.object_list.filter(
            (accountUser) => accountUser.email !== email
          ),
        }) as TData,
      config,
    })
  )
}
