import React, { ReactNode } from "react"
import assert from "assert"
import cx from "classnames"
import { useField } from "formik"
// DEV: We're aware Reakit combobox is unstable but we're avoiding experimental features - https://reakit.io/docs/combobox/
import {
  unstable_useComboboxState as useComboboxState,
  unstable_Combobox as ReakitCombobox,
  unstable_ComboboxPopover as ComboboxPopover,
  unstable_ComboboxOption as ComboboxOption,
} from "reakit/Combobox"

interface Option {
  value: string
  label: string
  popoverContent: ReactNode | ((props: { selected: boolean }) => ReactNode)
}

interface ComboboxProps {
  className?: string
  disabled?: boolean
  label: string
  options: Option[]
  popoverClassName?: string
  "aria-label"?: string
  name: string
}

export const Combobox = ({
  className,
  disabled = false,
  label,
  options,
  popoverClassName,
  "aria-label": ariaLabel,
  name,
}: ComboboxProps) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")

  const [field, meta, helpers] = useField(name)
  const combobox = useComboboxState({
    loop: true,
    gutter: 4,
  })

  return (
    <div className={cx("combobox", className)}>
      <div className="relative">
        <ReakitCombobox
          {...field}
          {...combobox}
          as="button"
          aria-label={ariaLabel || label}
          className={cx(
            "form-input st-input pr-10 cursor-default text-left truncate",
            { "bg-gray-100": disabled },
            { "has-error": meta.touched && meta.error }
          )}
          disabled={disabled}
          type="button"
        >
          {field.value ? (
            // DEV: This has `O(n)` runtime but we're prob at most 10 items always
            options?.find((option) => option.value === field.value)?.label
          ) : (
            <span className="placeholder text-gray2">Choose</span>
          )}
        </ReakitCombobox>

        {/* DEV: Use `pointer-events-none` to pass through clicks on arrow to combobox */}
        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <i className="fas fa-chevron-down text-sm text-gray2" />
        </span>
      </div>

      <ComboboxPopover
        {...combobox}
        aria-label={ariaLabel || label}
        as="ul"
        className={cx(
          "absolute z-combobox w-full bg-white shadow-lg max-h-80 rounded-md py-1 text-base overflow-auto focus:outline-none",
          popoverClassName
        )}
      >
        {options.map((option) => (
          <ComboboxOption
            {...combobox}
            as="li"
            className="text-carbon cursor-pointer select-none relative py-5 pl-6 pr-4 hover:bg-green-light hover:bg-opacity-50"
            key={option.value}
            value={option.value}
            onClick={() => {
              helpers.setValue(option.value)
            }}
          >
            {typeof option.popoverContent === "function"
              ? option.popoverContent({
                  selected: option.value === combobox.currentValue,
                })
              : option.popoverContent}
          </ComboboxOption>
        ))}
      </ComboboxPopover>
    </div>
  )
}
