import { useQuery, UseQueryResult } from "@tanstack/react-query"
import Axios, { AxiosRequestConfig } from "axios"

export const useAxiosData = <TData, TError = unknown>(
  axiosConfig: AxiosRequestConfig<any>,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["get-url-data", axiosConfig],
    queryFn: async () => {
      const res = await Axios(axiosConfig)
      return res.data
    },
    ...config,
  })
}
