import React, { ReactElement } from "react"
import { Button } from "reakit/Button"
import {
  useTooltipState,
  Tooltip,
  TooltipArrow,
  TooltipReference,
  TooltipState,
} from "reakit/Tooltip"
import cx from "classnames"

interface TooltipTriggerTypes {
  className?: string
  tooltip: TooltipState
}

interface TooltipContentTypes {
  children: ReactElement | string
  tooltip: TooltipState
}

interface TooltipTypes {
  children: ReactElement | string
  className?: string
}

const TooltipTrigger = ({ className = "", tooltip }: TooltipTriggerTypes) => (
  <TooltipReference
    {...tooltip}
    as={Button}
    className={cx("text-base", className)}
  >
    <i className="fas fa-info-circle" />
  </TooltipReference>
)

export const TooltipContent = ({ children, tooltip }: TooltipContentTypes) => (
  <Tooltip {...tooltip} className="st-tooltip">
    <div className="text-sm max-w-sm p-3 st-tooltip-inner">
      <TooltipArrow {...tooltip} />
      {children}
    </div>
  </Tooltip>
)

export const ToolTip = ({ children, className }: TooltipTypes) => {
  const tooltip = useTooltipState()

  return (
    <>
      <TooltipTrigger className={className} tooltip={tooltip} />
      <TooltipContent tooltip={tooltip}>{children}</TooltipContent>
    </>
  )
}
