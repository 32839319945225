import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { viewNotification } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export function useViewNotification<TData, TError, TVariables extends string>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (id: TVariables) => viewNotification(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "notifications"],
        })
      },
      ...config,
    }
  )
}
