import { Form, useFormikContext } from "formik"
import { DialogStateReturn } from "reakit"

import MultiSelect from "../../components/MultiSelect"
import { WrappedInput } from "../../components/Input"
import { ButtonPair } from "../../components/ButtonPair"
import { SubmitButton } from "../../components/SubmitButton"
import { NOT_INTERESTED_REASONS } from "../../shared/constants"
import { MORE_DETAILS, REASONS } from "./NotInterestedModal"
import { NotInterestedReason } from "@/types/constants"

interface ValuesTypes {
  reasons: NotInterestedReason[]
  more_details: string
}

interface NotInterestedModalFormComponentTypes {
  dialog: DialogStateReturn
}

const OPTIONS = [
  {
    value: NOT_INTERESTED_REASONS.KEY_OBLIGATION,
    label: "Key obligation",
  },
  { value: NOT_INTERESTED_REASONS.TERM_LENGTH, label: "Term length" },
  {
    value: NOT_INTERESTED_REASONS.LANDOWNER_COSTS,
    label: "Landowner costs",
  },
  {
    value: NOT_INTERESTED_REASONS.ELIGIBILITY_REQUIREMENTS,
    label: "Eligibility requirements",
  },
  {
    value: NOT_INTERESTED_REASONS.EARNINGS_AND_PENALTIES,
    label: "Earnings and penalties",
  },
  { value: NOT_INTERESTED_REASONS.RESTRICTIONS, label: "Restrictions" },
  {
    value: NOT_INTERESTED_REASONS.LANDOWNER_RIGHTS,
    label: "Landowner rights",
  },
  {
    value: NOT_INTERESTED_REASONS.PROPERTY_ACCESS,
    label: "Property access",
  },
  {
    value: NOT_INTERESTED_REASONS.OTHER,
    label: "Other",
  },
]

const NotInterestedModalFormComponent = ({
  dialog,
}: NotInterestedModalFormComponentTypes) => {
  const { values, isValid, dirty, isSubmitting } =
    useFormikContext<ValuesTypes>()

  return (
    <Form className="inline">
      <div className="mb-6">
        <MultiSelect
          name={REASONS}
          label="Reason for declining"
          labelClass="label font-bold mb-2 leading-18px"
          options={OPTIONS}
          aria-label="Reason for declining"
        />
      </div>

      <div className="mb-6">
        <WrappedInput
          label={`Why aren't you interested in this program${
            !values[REASONS].includes(
              NOT_INTERESTED_REASONS.OTHER as NotInterestedReason
            )
              ? " (optional)"
              : ""
          }`}
          labelClass="leading-18px"
          name={MORE_DETAILS}
          as="textarea"
          placeholder="Add in more details about why this opportunity did not interest you."
          className="mt-2 resize-none border-charcoal-50"
          aria-label="Why aren't you interested in this program?"
        />
      </div>

      <ButtonPair
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <SubmitButton
            disabled={!(isValid && dirty)}
            isSubmitting={isSubmitting}
            {...primaryProps}
          >
            Submit Feedback
          </SubmitButton>
        )}
        secondary={
          <button type="button" onClick={dialog.hide}>
            Go Back
          </button>
        }
      />
    </Form>
  )
}

export default NotInterestedModalFormComponent
