import { Dispatch, SetStateAction } from "react"
import { ActiveTabType } from "../Land"
import LandTabButton from "./LandTabButton"

interface LandTabsProps {
  activeTab: ActiveTabType
  setActiveTab: Dispatch<SetStateAction<ActiveTabType>>
}

const LandTabs = ({ activeTab, setActiveTab }: LandTabsProps) => (
  <div className="mb-4">
    <LandTabButton
      tab="loss-detection"
      text="Loss Detection"
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />

    <LandTabButton
      tab="land-cover"
      text="Land Cover"
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  </div>
)

export default LandTabs
