import { QueryClient } from "@tanstack/react-query"

import { useQueryWithRefresh } from "../utils"
import { getChangeDetection } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

interface UseChangeDetectionConfig<TData, TError>
  extends Partial<UseQueryWithRefreshConfig<TData, TError>> {
  staleTime?: number
}

export function useChangeDetection<Tdata, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseChangeDetectionConfig<Tdata, TError> = {}
) {
  return useQueryWithRefresh<Tdata, TError>(
    queryClient,
    ["accounts", accountId, "tile", "links", "change_detection"],
    () => getChangeDetection(accountId),
    config
  )
}
