import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesDown, faAnglesUp } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { Spinner } from "../../components/Spinner"
import { shortenAcreage } from "../../utils"
import { ProgramCategoryType } from "../../types/constants"
import { PROJECT_TYPES } from "../../shared/constants"

interface AssessmentItem {
  category: string
  n_programs: number
}

interface TeaserMapSidebarTypes {
  acreage: number
  assessmentData?: AssessmentItem[]
  assessmentStatus: string
  parcelIds: string[]
  onSubmit: () => void
}

const TeaserMapSidebar = ({
  acreage,
  assessmentData = [],
  assessmentStatus,
  parcelIds,
  onSubmit,
}: TeaserMapSidebarTypes) => {
  const [open, setOpen] = useState<boolean>(true)

  // Create a lookup object to get n_programs by category
  const assessmentLookup = assessmentData.reduce(
    (acc, item) => {
      acc[item.category] = item.n_programs
      return acc
    },
    {} as Record<string, number>
  )
  // Mapping data for table rows
  const assessmentCategories = [
    {
      label:
        PROJECT_TYPES[ProgramCategoryType.forest_carbon]?.long ||
        PROJECT_TYPES[ProgramCategoryType.harvest_deferral]?.long,
      keys: [
        ProgramCategoryType.forest_carbon,
        ProgramCategoryType.harvest_deferral,
      ],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.biodiversity].long,
      keys: [ProgramCategoryType.biodiversity],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.tree_planting].long,
      keys: [ProgramCategoryType.tree_planting],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.recreation].long,
      keys: [ProgramCategoryType.recreation],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.water].long,
      keys: [ProgramCategoryType.water],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.wildfire].long,
      keys: [ProgramCategoryType.wildfire],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.regen_ag].short, // Short label so stays on 1 line
      keys: [ProgramCategoryType.regen_ag],
    },
    {
      label: PROJECT_TYPES[ProgramCategoryType.renewable_infrastructure].long,
      keys: [ProgramCategoryType.renewable_infrastructure],
    },
    {
      label:
        PROJECT_TYPES[ProgramCategoryType.advisory_services]?.long ||
        PROJECT_TYPES[ProgramCategoryType.other].long,
      keys: [ProgramCategoryType.other, ProgramCategoryType.advisory_services],
    },
  ]

  return (
    <div
      className={cx("col-auto bg-grass-50 flex flex-col teaser-map-sidebar", {
        closed: !open,
      })}
    >
      <div className="bg-grass-900 flex justify-between items-center pl-4 pr-1 xl:pr-4 py-1 xl:py-4">
        <h3 className="text-white text-lg font-body font-bold leading-130 tracking-0.378">
          Parcel No.{" "}
          {parcelIds.length === 0
            ? ""
            : parcelIds.length === 1
              ? parcelIds[0]
              : "Multiple"}
        </h3>

        <button
          className="text-white px-3 py-3 xl:hidden"
          onClick={() => setOpen((oldOpen) => !oldOpen)}
        >
          {open ? (
            <FontAwesomeIcon icon={faAnglesDown} />
          ) : (
            <FontAwesomeIcon icon={faAnglesUp} />
          )}
        </button>
      </div>

      <div className="p-4 teaser-map-sidebar-content">
        <h3 className="text-lg font-body leading-130 tracking-0.378">
          <strong>Size in acres:</strong> {shortenAcreage(acreage)}
        </h3>

        <table className="w-full table-auto mt-4">
          <thead>
            <tr className="text-lg text-left font-bold">
              <th>Category</th>
              <th className="px-4">Programs</th>
              <th>Value</th>
            </tr>
          </thead>

          <tbody className="leading-130 tracking-0.32">
            {assessmentCategories.map((category) => {
              // Combine the values for all keys in this category
              const combinedValue = category.keys.reduce((total, key) => {
                return total + (assessmentLookup[key] || 0) // Sum up the values for the keys, defaulting to 0
              }, 0)

              return (
                <tr key={category.label} className="mt-1">
                  <td>{category.label}</td>
                  <td className="px-4">
                    {assessmentStatus === "pending" ? (
                      <Spinner loadingText="" />
                    ) : (
                      combinedValue
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn2 btn2-primary text-xs leading-[21px] tracking-0.36 !py-0 !px-6"
                      onClick={() => onSubmit()} // Attach the Formik onSubmit function
                    >
                      Unlock
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TeaserMapSidebar
