import { useQueryClient } from "@tanstack/react-query"

import { ContentCard } from "../../components/Card"
import { Toast } from "../../components/Toast"
import { useProfile, useResendEmailVerification } from "../../hooks"

import mailicon from "../../images/mail-icon.svg"
import { Profile } from "../../types"

const VerifyEmail = () => {
  const queryClient = useQueryClient()
  const { data: profile } = useProfile<Profile, Error>(queryClient)

  const { mutateAsync: resendEmailVerification } = useResendEmailVerification(
    queryClient,
    {
      onSuccess: () => Toast.success(`Email sent to ${profile?.email}`),
      onError: () => Toast.error("Error resending email. Try again later"),
    }
  )

  const handleResendVerification = async () => {
    await resendEmailVerification()
  }

  return (
    <ContentCard>
      <h3 className="text-center mb-2">Verify your email address</h3>
      <div className="text-center text-base leading-130 tracking-0.32 mb-8">
        Check {profile?.email || "your email"} and click the verification link
        to access your account
      </div>
      <div className="mb-8">
        <img className="mx-auto" src={mailicon} alt="Check your email" />
      </div>
      <div className="text-center">
        Need help?{" "}
        <a className="link" href="mailto:landowners@ncx.com">
          Get in touch
        </a>
      </div>

      <hr className="border-charcoal-50 mt-8 mb-4" />

      <div className="text-center text-dusk"> Don't see an email?</div>
      <div className="text-center text-dusk">
        {" "}
        Check your spam folder or{" "}
        <button
          type="button"
          className="link"
          onClick={() => {
            handleResendVerification()
          }}
          aria-label="click to resend email verification"
        >
          resend verification{" "}
        </button>
      </div>
    </ContentCard>
  )
}
export default VerifyEmail
