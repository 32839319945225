import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"
import { AxiosError } from "axios"

import { AuthData, useSessionStore } from "../../../stores"
import { isAuthenticationError, refreshToken } from "../../../api/auth"

export interface UseMutationWithRefreshConfig<
  TData,
  TError,
  TVariables,
  TContext = unknown,
> extends UseMutationOptions<TData, TError, TVariables, TContext> {
  _onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext
  ) => void | Promise<void>
  dontUpdateCache?: boolean
}

export function useMutationWithRefresh<
  TData,
  TError,
  TVariables,
  TContext = unknown,
>(
  queryClient: QueryClient,
  _mutationFn: (variables: TVariables) => Promise<TData>,
  config?: UseMutationWithRefreshConfig<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> {
  // Support custom `_onSuccess` config, to add "pit of success" where we always call `onSuccess` still
  if (config?._onSuccess) {
    const origOnSuccess = config.onSuccess || (() => {})
    const { _onSuccess, ...restConfig } = config
    config = {
      ...restConfig,
      onSuccess: (...args) => {
        _onSuccess.call(config, ...args)
        return origOnSuccess.call(config, ...args)
      },
    }
  }

  // DEV: Historically we tried to wrap the `useMutation` return values
  //   However, that descended into needing to define a custom `onError` and such
  //   only to try to re-define what's in the source again
  //   This is the saner path
  //   https://app.asana.com/0/1199976942355619/1203100543654550/f
  const mutationFn = async (variables: TVariables): Promise<TData> => {
    let retVal: TData
    try {
      retVal = await _mutationFn(variables)
    } catch (error: any) {
      // If the error was due to authentication, refresh the access token and try the mutation again
      // DEV: To test this sanely, reduce `ACCESS_TOKEN_LIFETIME` to 5 seconds in API
      const err = error as AxiosError
      if (isAuthenticationError(err)) {
        try {
          useSessionStore
            .getState()
            .setAuthData((await refreshToken()) as AuthData)
        } catch (refreshError) {
          useSessionStore.getState().setAuthData(null)
          queryClient.invalidateQueries({ queryKey: ["refresh"] })
        }
        retVal = await _mutationFn(variables)
      } else {
        throw error
      }
    }
    return retVal
  }

  return useMutation({ mutationFn, ...config })
}
