import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { coerceNullDataValues, useQueryWithRefresh } from "../utils"
import { getProfile } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useProfile<TData, TError>(
  queryClient: QueryClient,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query: UseQueryResult<TData, TError> = useQueryWithRefresh<
    TData,
    TError
  >(queryClient, ["profile"], getProfile, {
    staleTime: Infinity,
    ...config,
  })

  const transformedData = query.data
    ? coerceNullDataValues(query.data)
    : query.data

  return {
    ...query,
    data: transformedData,
  } as UseQueryResult<TData, TError>
}
