import { Formik, FormikValues } from "formik"
import * as yup from "yup"
import { useQueryClient } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit/Dialog"

import { Toast } from "../../../components/Toast"
import { Modal } from "../../../components/Modal"
import { usePostQuestion } from "../../../hooks"
import AskAQuestionModalForm from "./AskAQuestionModalForm"

interface AskAQuestionModalTypes {
  dialog: DialogStateReturn
  projectId: string
}

const validationSchema = yup.object().shape({
  question: yup.string().required("Question input required."),
})

const AskAQuestionModal = ({ dialog, projectId }: AskAQuestionModalTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: postQuestion } = usePostQuestion(
    queryClient,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success(
          "Your question has been successfully submitted, and will appear once it's been approved."
        )
      },
      onError: () => {
        dialog.hide()
        Toast.error(
          "Unable to post your question at this time. Try again later."
        )
      },
    }
  )

  const handleSubmit = async (values: FormikValues) => {
    await postQuestion(values)
  }

  return (
    <Formik
      initialValues={{
        question: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => (
        <Modal
          header="Ask a question"
          aria-label="Ask a question"
          dialog={dialog}
          className="max-w-343 p-4"
          onDismiss={formikProps.resetForm}
        >
          <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mb-4">
            Have a question about this program?
            <br />
            Submit your question below to learn more.
            <br />
            When you submit your question, your first name and last initial will
            be visible.
          </p>

          <AskAQuestionModalForm formikProps={formikProps} dialog={dialog} />

          <p className="text-base text-charcoal-500 text-center leading-130 tracking-0.32 mt-4">
            By submitting, you agree to the{" "}
            <a
              href="https://ncx.com/terms"
              className="link font-semibold"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>{" "}
            and{" "}
            <a
              href="https://ncx.com/privacy"
              className="link font-semibold"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </p>
        </Modal>
      )}
    </Formik>
  )
}

export default AskAQuestionModal
