// @ts-nocheck
import MailCheck from "react-mailcheck"
import mailcheck from "mailcheck"

const domains = mailcheck.defaultDomains
domains.push("ncx.com")

const domains2ndLevel = mailcheck.defaultSecondLevelDomains
domains2ndLevel.push("ncx")

export default MailCheck
