import { ReactElement } from "react"
import assert from "assert"
import { useNavigate } from "react-router-dom"
import { Form, Formik } from "formik"
import { useQueryClient } from "@tanstack/react-query"

import { SubmitButton } from "../../../components/SubmitButton"
import { useUpdateProfile } from "../../../hooks"

interface SwitchToMultiAccountButtonTypes {
  onError: (error: Error) => void
  className: string
  children: ReactElement | string
}

const SwitchToMultiAccountButton = ({
  onError,
  className,
  children,
}: SwitchToMultiAccountButtonTypes) => {
  assert(onError)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    // Prevent cache from updating to avoid making `_single` requests as an MA user (if successful)
    dontUpdateCache: true,
  })

  const handleSubmit = async () => {
    await updateProfile(
      { profile_type: "multi_account" },
      {
        onSuccess: () => {
          // Reload our page to `/` to ensure fresh status for our new account
          navigate("/")
        },
      }
    )
    // DEV: Any further code will still run regardless of error/not due to `onError` handling
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form className="inline">
          <SubmitButton
            isSubmitting={formikProps.isSubmitting}
            className={className}
          >
            {children}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  )
}

export default SwitchToMultiAccountButton
