import { useState } from "react"
import { Link } from "react-router-dom"

import { useAccountUrlPrefix } from "../../../hooks"
import { useDialogState } from "reakit"
import LandMapOverlayDialog from "./LandMapOverlayDialog"

interface LandMapOverlayProps {
  isMember: boolean
  show: boolean
  noDetections: boolean
  dataNotReady: boolean
}

const LandMapOverlay = ({
  isMember,
  show,
  noDetections,
  dataNotReady,
}: LandMapOverlayProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const dialog = useDialogState({ modal: false })
  const [visible, setVisible] = useState<boolean>(true)

  if (show && isMember && (noDetections || dataNotReady)) {
    return (
      <LandMapOverlayDialog
        dialog={dialog}
        visible={visible}
        setVisible={setVisible}
        dataNotReady={dataNotReady}
      />
    )
  }

  if (!show || isMember) {
    return null
  }

  return (
    <div className="relative flex flex-col-reverse w-full h-full z-[2] bg-dusk-500/75">
      <div className="w-full bg-sand flex flex-col items-center p-6">
        <h3 className="text-charcoal-500 text-4xl font-medium leading-[124%] tracking-[0.72px]">
          Get full access to NCX
        </h3>

        <p className="text-autumn text-2xl font-medium leading-[124%] tracking-0.48 mt-3">
          Become a member today
        </p>

        <Link
          to={`${accountUrlPrefix}/become-a-member`}
          className="change-map-membership btn2 btn2-primary mt-4"
          state={{ scrollToTop: true }}
        >
          Learn More
        </Link>
      </div>
    </div>
  )
}

export default LandMapOverlay
