import { EligibilityQuizCategoryType } from "../../../types/constants"

interface GuideCategoryTypes {
  headline: string
  url: string
}

export type GuideType = GuideCategoryTypes | null

export const getGuide = (
  quizCategory: EligibilityQuizCategoryType | undefined
): GuideType => {
  const categories = {
    forest_carbon: {
      headline: "Guide to Forest Carbon Programs",
      url: "https://ncx.com/forest-carbon-guide/",
    },
    tree_planting: {
      headline: "Guide to Tree Planting Programs",
      url: "https://ncx.com/tree-planting-programs-guide/",
    },
    timber: {
      headline: "Guide to Timber",
      url: "https://ncx.com/timber-guide/",
    },
    wildfire: null,
    renewable_infrastructure: {
      headline: "Guide to Solar Energy Programs",
      url: "https://ncx.com/solar-program-guide/",
    },
    recreation: {
      headline: "Guide to Recreation Programs",
      url: "https://ncx.com/recreation-programs-guide/",
    },
    regen_ag: null,
    advisory_services: null,
    general: null,
  }

  return quizCategory ? categories[quizCategory] : null
}
