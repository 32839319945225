import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useAccountQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { getProjectTypes } from "../../../api/data"

export function useProjectTypes<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query = useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "projects", "project-types"],
    () => getProjectTypes(),
    config
  )

  return query
}
