import { Form, FormikProps } from "formik"

import PreferredContactInput from "../../components/PreferredContactInput"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import RequestInformationBtn from "./RequestInformationBtn"
import { DialogDisclosure, DialogStateReturn } from "reakit"
import { WrappedInput } from "../../components/Input"
import { AccountRoleType } from "@/types/account"

export interface PreferredContactMethodFormValuesTypes {
  preferred_contact_method: string[]
  phone_number: string | undefined
}

interface PreferredContactMethodFormTypes {
  dialog: DialogStateReturn
  notInterestedDialog: DialogStateReturn
  accountRole: AccountRoleType
  formikProps: FormikProps<PreferredContactMethodFormValuesTypes>
  enrollmentDeadlineType: any
  requires_service_provider: any
  ctaOverride: any
  isValidPhoneNumber: any
  landowner_status: any
  projectId: any
  accountId: any
  ctaOverrideUrl: any
  modalBodyText?: string
  submitBtnText?: string
  isMember: boolean | undefined
  isGovernmentProgram: boolean
  isSponsored: boolean
  isInvited: boolean
}

const PreferredContactMethodForm = ({
  dialog,
  notInterestedDialog,
  accountRole,
  formikProps,
  enrollmentDeadlineType,
  requires_service_provider,
  ctaOverride,
  isValidPhoneNumber,
  landowner_status,
  projectId,
  accountId,
  ctaOverrideUrl,
  modalBodyText,
  submitBtnText,
  isMember,
  isGovernmentProgram,
  isSponsored,
  isInvited,
}: PreferredContactMethodFormTypes) => {
  const showPhoneField =
    !isValidPhoneNumber &&
    (formikProps.values.preferred_contact_method.includes("phone") ||
      formikProps.values.preferred_contact_method.includes("text"))

  return (
    <Form>
      <div className="flex flex-col w-full max-w-614 border border-dusk-50 rounded p-6 mt-5 mb-6">
        <p className="text-overline">ADD YOUR PREFERRED CONTACT METHOD</p>

        <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mt-3">
          {modalBodyText
            ? modalBodyText
            : `When you request a call, NCX will share your preferred contact method and information with ${
                !requires_service_provider
                  ? "this program partner"
                  : "a forester"
              }.`}
        </p>

        <p className="text-base text-charcoal-500 leading-130 tracking-0.32 mt-3">
          You can update this at anytime in Account Settings.
        </p>

        <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-4 mt-6">
          <div>
            <PreferredContactInput />
          </div>
          {showPhoneField ? (
            <div>
              <WrappedInput
                labelClass="label font-bold mb-2 leading-18px"
                aria-label="Phone number"
                label="Phone number"
                placeholder="Phone number"
                type="text"
                name="phone_number"
              />
            </div>
          ) : null}
        </div>
      </div>

      <hr className="border-charcoal-50 mb-6 lg:max-w-624 xl:max-w-664" />

      <div className="flex gap-4">
        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <DialogDisclosure
            {...notInterestedDialog}
            className="btn2 btn2-outline-primary bg-cloud-50 text-base !font-bold py-7px not-interested-btn-project-modal"
          >
            Not Interested
          </DialogDisclosure>
        </ActionPermissionWrapper>

        <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
          <RequestInformationBtn
            landowner_status={landowner_status}
            projectId={projectId}
            accountId={accountId}
            sourceIdentifier="project-modal"
            accountRole={accountRole}
            enrollmentDeadlineType={enrollmentDeadlineType}
            ctaOverride={ctaOverride}
            ctaOverrideUrl={ctaOverrideUrl}
            formikProps={formikProps as FormikProps<unknown> | undefined}
            customBtnText={submitBtnText}
            isMember={isMember}
            isGovernmentProgram={isGovernmentProgram}
            isSponsored={isSponsored}
            isInvited={isInvited}
          />
        </ActionPermissionWrapper>
      </div>

      <button
        className="block text-leaf font-semibold hover:underline focus:outline-none mb-3 mt-3 skip-btn-project-modal"
        type="button"
        onClick={dialog.hide}
      >
        Skip for now
      </button>
    </Form>
  )
}

export default PreferredContactMethodForm
