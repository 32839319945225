import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { requestAccountPropertyPreview } from "../../../api/data"
import { useAccountQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { FeatureType } from "@/types"

interface DataTypes {
  features: FeatureType[]
}

export function useAccountPropertyPreview<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  data: DataTypes,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  return useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "property", "preview", data],
    () => requestAccountPropertyPreview(accountId, data),
    config
  )
}
