import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"

interface IconButtonTypes {
  icon: IconDefinitionSvg
  text: string
  onClick: () => void
  className: string
}

const IconButton = ({ icon, text, onClick, className }: IconButtonTypes) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      "block text-leaf font-semibold hover:underline focus:outline-none",
      className
    )}
  >
    <span className="inline-block mr-1 w-6 leading-6 text-center">
      <FontAwesomeIcon icon={icon} />
    </span>
    {text}
  </button>
)

export default IconButton
