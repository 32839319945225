import { AxiosError } from "axios"
import { Formik, FormikValues } from "formik"
import * as yup from "yup"
import { useQueryClient } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit"

import { Modal } from "../../components/Modal"
import { Toast } from "../../components/Toast"
import { NOT_INTERESTED_REASONS } from "../../shared/constants"
import { useUpdateAccountProject } from "../../hooks"
import NotInterestedModalFormComponent from "./NotInterestedModalFormComponent"

interface NotInterestedModalTypes {
  dialog: DialogStateReturn
  accountId: string
  projectId: string
  onClose?: () => void
}

export const REASONS = "reasons"
export const MORE_DETAILS = "more_details"

const validationSchema = yup.object().shape({
  [REASONS]: yup
    .array()
    .min(1, "At least one reason is required")
    .of(yup.string().required())
    .required(),
  [MORE_DETAILS]: yup.string().when(REASONS, (reasons, schema) => {
    return reasons.includes(NOT_INTERESTED_REASONS.OTHER)
      ? yup.string().required("Please fill out this field.")
      : schema
  }),
})

const NotInterestedModal = ({
  dialog,
  accountId,
  projectId,
  onClose,
}: NotInterestedModalTypes) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateAccountProject } = useUpdateAccountProject(
    queryClient,
    accountId,
    projectId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success(
          "Your feedback about this program has been successfully sent."
        )
        onClose && onClose()
      },
      onError: (error) => {
        const err = error as AxiosError
        dialog.hide()
        Toast.error(
          err?.message || "An error occurred while updating your preferences."
        )
      },
    }
  )

  const handleSubmit = async (values: FormikValues) => {
    const newValues = {
      status: "not_interested",
      not_interested_reasons: values.reasons,
      not_interested_reason_additional: values?.more_details,
    }
    await updateAccountProject(newValues)
  }

  return (
    <Modal
      header="Not interested? Tell us more."
      aria-label="Not interested? Tell us more."
      dialog={dialog}
      className="max-w-400 p-5"
    >
      <p className="mb-4">
        Select a reason for declining interest in this opportunity and tell us
        more about your decision.
      </p>
      {/* DEV: Force formik re-render to reset initial values on cached projects */}
      {dialog.visible === true ? (
        <Formik
          initialValues={{
            [REASONS]: [],
            [MORE_DETAILS]: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <NotInterestedModalFormComponent dialog={dialog} />
        </Formik>
      ) : null}
    </Modal>
  )
}

export default NotInterestedModal
