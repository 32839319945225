import PropertyMap from "../../../sections/LandCoverDetails/PropertyMap"
import LandMapOverlay from "./LandMapOverlay"
import LandMapLegend from "./LandMapLegend"
import { ActiveTabType } from "../Land"

interface LandMapProps {
  accountId: string
  isMember: boolean
  activeTab: ActiveTabType
  noDetections: boolean
  dataNotReady: boolean
}

const LandMap = ({
  accountId,
  isMember,
  activeTab,
  noDetections,
  dataNotReady,
}: LandMapProps) => (
  <PropertyMap
    accountId={accountId}
    overlay={
      <LandMapOverlay
        isMember={isMember}
        show={activeTab === "loss-detection"}
        noDetections={noDetections}
        dataNotReady={dataNotReady}
      />
    }
    legend={<LandMapLegend show={activeTab === "loss-detection"} />}
    changeDetection={activeTab === "loss-detection"}
    enableZoomPan
    isWidget
  />
)

export default LandMap
