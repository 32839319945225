import React, { ReactNode, ButtonHTMLAttributes } from "react"
import cx from "classnames"
import { Button as RButton } from "reakit/Button"

interface MapMainMenuButtonTypes
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  fullWidth?: boolean
  className?: string
  isActive?: boolean
  isDisabled?: boolean
  onClick?: () => void
  iconClassName?: string
}

const MapMainMenuButton = ({
  children,
  fullWidth = true,
  className,
  isActive,
  isDisabled,
  onClick,
  iconClassName,
  ...rest
}: MapMainMenuButtonTypes) => {
  const buttonClass = cx(
    "btn-secondary rounded-none p-2 flex first:rounded-t-md last:rounded-b-md",
    className,
    {
      "shadow-inner bg-gray-300": isActive,
      "bg-white": !isActive,
      "text-gray-300": isDisabled,
      "w-full": fullWidth,
    }
  )

  return (
    <RButton
      className={buttonClass}
      onClick={onClick}
      disabled={isDisabled}
      {...rest}
    >
      {iconClassName && (
        <div className="inline ml-0 mr-2 w-7">
          <i className={`${iconClassName} text-xl`}></i>
        </div>
      )}
      {children}
    </RButton>
  )
}

export default MapMainMenuButton
