import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"

import { checkUserByEmail } from "../../../api/auth"
import { AuthDataTypes } from "@/types/auth"

export function useCheckUserByEmail<
  TData,
  TError,
  TValues extends AuthDataTypes,
>(
  config: UseMutationOptions<TData, TError, TValues> = {}
): UseMutationResult<TData, TError, TValues, unknown> {
  return useMutation({ mutationFn: checkUserByEmail, ...config })
}
