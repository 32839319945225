import { Form, FormikProps } from "formik"
import { DialogStateReturn } from "reakit/Dialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"

import { ButtonPair } from "../../../components/ButtonPair"
import { WrappedInput } from "../../../components/Input"

interface AskAQuestionModalFormTypes {
  formikProps: FormikProps<{ question: string }>
  dialog: DialogStateReturn
}

const AskAQuestionModalForm = ({
  formikProps,
  dialog,
}: AskAQuestionModalFormTypes) => (
  <Form className="inline">
    <div className="mb-6">
      <WrappedInput
        label="Question"
        labelClass="leading-18px"
        name="question"
        as="textarea"
        placeholder="Write your question here."
        className="mt-2 resize-none border-charcoal-50"
        aria-label="Write your question here."
      />
    </div>

    <ButtonPair
      primary={
        // DEV: There's not enough space for SubmitButton component to fit without breaking into multiple lines on submit, so we're using a custom button instead.
        <button
          type="submit"
          className="font-bold"
          disabled={!(formikProps.isValid && formikProps.dirty)}
        >
          {formikProps.isSubmitting ? (
            <>
              Submitting{" "}
              <FontAwesomeIcon icon={faSpinnerThird} className="fa-spin" />
            </>
          ) : (
            "Submit Question"
          )}
        </button>
      }
      secondary={
        <button type="button" onClick={dialog.hide} className="font-bold">
          Cancel
        </button>
      }
    />
  </Form>
)

export default AskAQuestionModalForm
