import { createContext } from "react"
import { AxiosError } from "axios"
import { MutationStatus } from "@tanstack/react-query"

export interface VerifyEmailResponseTypes {
  data: unknown
  error: AxiosError<unknown, any> | null
  status: MutationStatus
  isIdle: boolean
  reset: () => void
}

export const VerifyUserEmailResponseContext =
  createContext<VerifyEmailResponseTypes | null>(null)
