import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { getAddressLatLng } from "../../../api/data"

export function useAddressLatLng<TData, TError>(
  address: string,
  config = {}
): UseQueryResult<TData, TError> {
  return useQuery({
    queryKey: ["address", address],
    queryFn: () => getAddressLatLng(address),
    ...config,
  })
}
