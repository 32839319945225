import cx from "classnames"

interface StatTypes {
  label: string
  value: string | number
  showPct?: boolean
  valueClass?: string
}

const Stat = ({ label, value, showPct = true, valueClass }: StatTypes) => (
  <div className="h-[54px] flex flex-col justify-start items-center gap-1">
    <div
      className={cx(
        "text-center text-black text-xl font-medium leading-130 tracking-0.32",
        valueClass
      )}
    >
      {value}
      {showPct ? "%" : ""}
    </div>

    <div className="text-leaf text-base font-bold leading-130 tracking-0.32">
      {label}
    </div>
  </div>
)

export default Stat
