import { RefObject } from "react"
import assert from "assert"
import create, { UseBoundStore } from "zustand"
import { assertAccountId, makeCompositeKey } from "../utils"

export const ALLOWED_ACCOUNT_VALUE_OVERRIDES = ["payment_info_complete"]

export interface AuthData {
  access: string
  refresh: string
  profile_type: "single_account" | "multi_account"
  user_id: number
}

interface SessionStoreState {
  authData: AuthData | null
  setAuthData: (authData: AuthData | null) => void
  inaccessibleAccountIds: Record<string, boolean>
  setInaccessibleAccountId: (id: string) => void
  clearInaccessibleAccountIds: () => void
  accountValueOverrideMap: Record<string, any>
  setAccountValueOverride: (
    accountId: string,
    propertyName: string,
    value: any
  ) => void
  getAccountValueOverride: (accountId: string, propertyName: string) => any
  clearAccountValueOverrides: () => void
  accountCycleStatusOverrideMap: Record<string, any>
  setAccountCycleStatusOverride: (
    accountId: string,
    cycleKey: string,
    status: any
  ) => void
  getAccountCycleStatusOverride: (accountId: string, cycleKey: string) => any
  clearAccountCycleStatusOverrides: () => void
  parcelCache: Record<string, any>
  addParcelToCache: (parcel: any) => void
  identified: boolean
  setIdentified: (newIdentified: boolean) => void
  ncxLogoRef: React.RefObject<HTMLAnchorElement> | null
  setNcxLogoRef: (ref: React.RefObject<HTMLAnchorElement>) => void
}

interface CustomSessionStore extends UseBoundStore<SessionStoreState> {
  getAccessToken: () => string | undefined
}

const assertAllowedAccountValueOverride = (propertyName: string) => {
  assert(
    ALLOWED_ACCOUNT_VALUE_OVERRIDES.includes(propertyName),
    `Unsupported propertyName ${propertyName}`
  )
}

const store = create<SessionStoreState>((set, get) => ({
  authData: null, // { access: <token>, refresh: <token>, profile_type: "single_account"|"multi_account" }
  setAuthData: (authData: AuthData | null) => set(() => ({ authData })),

  // Track which accounts are inaccessible so we can 404 without modifying the URL
  //   Oddly nothing is built-in for this, https://app.asana.com/0/0/1201040667108751/f
  // DEV: A more generic version might be `inaccessiblePages` - but that resends requests on each page
  inaccessibleAccountIds: {},
  setInaccessibleAccountId: (id: string) =>
    set(({ inaccessibleAccountIds }) => {
      return {
        // DEV: Providing new value after spread ensures it overrides current values
        inaccessibleAccountIds: { ...inaccessibleAccountIds, [id]: true },
      }
    }),
  clearInaccessibleAccountIds: () =>
    set(() => ({ inaccessibleAccountIds: {} })),

  accountValueOverrideMap: {},
  setAccountValueOverride: (
    accountId: string,
    propertyName: string,
    value: any
  ) => {
    assertAccountId(accountId)
    assertAllowedAccountValueOverride(propertyName)
    set(({ accountValueOverrideMap }) => {
      const key = makeCompositeKey([accountId, propertyName])
      return {
        // DEV: Providing override after spread ensures it overrides current values
        accountValueOverrideMap: { ...accountValueOverrideMap, [key]: value },
      }
    })
  },
  getAccountValueOverride: (accountId: string, propertyName: string) => {
    assertAccountId(accountId)
    assertAllowedAccountValueOverride(propertyName)
    const key = makeCompositeKey([accountId, propertyName])
    const { accountValueOverrideMap } = get()
    return accountValueOverrideMap[key]
  },
  clearAccountValueOverrides: () =>
    set(() => ({ accountValueOverrideMap: {} })),

  accountCycleStatusOverrideMap: {},
  setAccountCycleStatusOverride: (
    accountId: string,
    cycleKey: string,
    status: any
  ) => {
    assertAccountId(accountId)
    set(({ accountCycleStatusOverrideMap }) => {
      const key = makeCompositeKey([accountId, cycleKey])
      return {
        accountCycleStatusOverrideMap: {
          ...accountCycleStatusOverrideMap,
          // DEV: Providing override after spread ensures it overrides current values
          [key]: status,
        },
      }
    })
  },
  getAccountCycleStatusOverride: (accountId: string, cycleKey: string) => {
    assertAccountId(accountId)
    const key = makeCompositeKey([accountId, cycleKey])
    const { accountCycleStatusOverrideMap } = get()
    return accountCycleStatusOverrideMap[key]
  },
  clearAccountCycleStatusOverrides: () =>
    set(() => ({ accountCycleStatusOverrideMap: {} })),

  // parcelCache is a dict of robust_id to parcel object as returned from data.js::getParcelData
  // Parcel example: {parcel_id: "1404200004002002", county_id: 1105, rausa_id: 2996, county_name: "Perry", muni_name: "Marion", …}
  parcelCache: {},

  // Add the parcel to the session cache. If the cache already has 1000 objects, wipe it out and start over.
  // We never expect to hit this limit during normal user operation, it's just a safety cap to prevent
  // unchecked growth.
  addParcelToCache: (parcel: any) =>
    set((state) => ({
      parcelCache:
        Object.keys(state.parcelCache).length > 999
          ? { [parcel.robust_id]: parcel }
          : { ...state.parcelCache, [parcel.robust_id]: parcel },
    })),
  identified: false,
  setIdentified: (newIdentified: boolean) =>
    set(() => ({ identified: newIdentified })),
  ncxLogoRef: null,
  setNcxLogoRef: (ref: RefObject<HTMLAnchorElement>) =>
    set(() => ({ ncxLogoRef: ref })),
}))

export const useSessionStore = store as CustomSessionStore

useSessionStore.getAccessToken = () => {
  return useSessionStore.getState().authData?.access
}

export const useAccessToken = () => {
  const { authData } = useSessionStore((state) => state)
  return authData?.access
}

export const useProfileType = () => {
  const { authData } = useSessionStore((state) => state)
  return authData?.profile_type
}
