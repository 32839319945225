import {
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
} from "react-accessible-accordion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface QuestionTypes {
  question: string
  authorName: string
  createdAt: any
  disabled: any
}

interface QuestionContentTypes extends QuestionTypes {
  expanded: boolean
}

const QuestionContent = ({
  question,
  authorName,
  createdAt,
  expanded = false,
  disabled,
}: QuestionContentTypes) => (
  <div className="flex justify-between text-base text-leaf font-bold leading-130 tracking-0.32">
    <p>
      Q: {question}{" "}
      <span className="text-charcoal-500 font-normal">
        by {authorName} | {createdAt}
      </span>
    </p>

    <span className={cx("pl-2", { "opacity-50": disabled })}>
      <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
    </span>
  </div>
)

const Question = ({
  question,
  authorName,
  createdAt,
  disabled,
}: QuestionTypes) => {
  if (disabled) {
    return (
      <QuestionContent
        question={question}
        authorName={authorName}
        createdAt={createdAt}
        expanded={false}
        disabled={disabled}
      />
    )
  }

  return (
    <AccordionItemHeading
      className={cx({ "cursor-default pointer-events-none": disabled })}
    >
      <AccordionItemButton
        className="focus:ring focus:ring-blue-400 focus:ring-opacity-45"
        aria-label={question}
      >
        <AccordionItemState>
          {({ expanded }: { expanded: boolean }) => (
            <QuestionContent
              question={question}
              authorName={authorName}
              createdAt={createdAt}
              expanded={expanded}
              disabled={disabled}
            />
          )}
        </AccordionItemState>
      </AccordionItemButton>
    </AccordionItemHeading>
  )
}
export default Question
