import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import {
  coerceNullDataValues,
  useAccountOverrider,
  useAccountQueryWithRefresh,
} from "../utils"
import { getAccount } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export const useAccount = <TData extends Record<string, any>, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  const applyAccountOverride = useAccountOverrider()
  const query: UseQueryResult<TData, TError> = useAccountQueryWithRefresh<
    TData,
    TError
  >(queryClient, ["accounts", accountId], () => getAccount(accountId), config)

  const transformedData = query.data
    ? coerceNullDataValues(query.data)
    : query.data

  if (transformedData) {
    applyAccountOverride(transformedData, accountId)
  }

  return {
    ...query,
    data: transformedData,
  } as UseQueryResult<TData, TError>
}
