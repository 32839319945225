import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { updateAccountInterest } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export function useUpdateAccountInterest<
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> {
  return useMutationWithRefresh(
    queryClient,
    (values) => updateAccountInterest(accountId, values),
    config
  )
}
