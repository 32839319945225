import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { coerceNullDataValues, useAccountQueryWithRefresh } from "../utils"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import { getAttestations } from "../../../api/data"

export function useAttestations<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  projectId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query = useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "projects", projectId, "attestations"],
    () => getAttestations(accountId, projectId.toString()),
    config
  )

  const transformedData = query.data
    ? coerceNullDataValues(query.data)
    : query.data

  return {
    ...query,
    data: transformedData,
  } as UseQueryResult<TData, TError>
}
