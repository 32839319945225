import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useQueryWithRefresh } from "../utils"
import { getQuestions } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export const useGetQuestions = <TData, TError>(
  queryClient: QueryClient,
  projectId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["projects", projectId, "questions"],
    getQuestions.bind(this, projectId),
    config
  )
}
