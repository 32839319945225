import { useState, useEffect } from "react"

interface ViewportReturnResult {
  width: number
  height: number
}

const useViewport = (): ViewportReturnResult => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [height, setHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return { width, height }
}

export default useViewport
