import React, { Dispatch, SetStateAction } from "react"
import { Form, FormikProps } from "formik"

import { ContentCard, ErrorCard } from "../../components/Card"
import { SsoAuthButtons, ContinueSeparator } from "./SsoAuthButtons"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { useQueryParam } from "../../hooks"
import SignUpFooter from "./SignUpFooter"
import { GettingStartedFormValues } from "./GettingStartedForm"

interface GettingStartedFormComponentTypes
  extends FormikProps<GettingStartedFormValues> {
  errorMessage: string | null
  onSsoSuccess: (values: any) => void
  onSsoError: () => void
  setAuthData: Dispatch<SetStateAction<GettingStartedFormValues>>
}

const GettingStartedFormComponent = ({
  values, // form values
  isSubmitting,
  errorMessage,
  onSsoSuccess,
  onSsoError,
  setAuthData,
}: GettingStartedFormComponentTypes) => {
  const queryEmail = useQueryParam("email")

  function handleBlur() {
    setAuthData({
      email: values.email,
    })
  }
  return (
    <ContentCard>
      <h3 className="text-center text-lg mb-2">Welcome to NCX</h3>
      <div className="text-center text-base leading-130 tracking-0.32 mb-4">
        Log in or Sign up
      </div>
      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}

        <div className="space-y-1">
          <WrappedInput
            data-test="input-email"
            label="Email address"
            name="email"
            placeholder="name@example.com"
            type="text"
            as="email"
          />
          {queryEmail && queryEmail !== values.email && (
            <div className="text-sm leading-5 text-dusk">
              Changing your email will lose the invite
            </div>
          )}
        </div>

        <SubmitButton
          className="btn2 btn2-primary btn2-block font-semibold"
          isSubmitting={isSubmitting}
          data-test="continue-with-email"
        >
          Continue
        </SubmitButton>

        <ContinueSeparator />

        <SsoAuthButtons onSuccess={onSsoSuccess} onError={onSsoError} />

        <hr className="border-charcoal-50 mx-4" />

        <SignUpFooter
          secondaryInfo={
            <>
              Do you represent landowners?{" "}
              <a
                className="link--underline-only text-sm text-dusk-500"
                href="https://info.ncx.com/consulting-forester-and-affiliate-program-registration"
                data-test="forester-signup"
              >
                <span className="link">Request an account</span>
              </a>
            </>
          }
        />
      </Form>
    </ContentCard>
  )
}

export default GettingStartedFormComponent
