import { WEBGL_NOT_SUPPORTED } from "./constants"

interface MapboxGLNotSupportedProps {
  reason: string
}

const MapboxGLNotSupported = ({ reason }: MapboxGLNotSupportedProps) => (
  <div className="bg-gray2 w-full h-full">
    <div className="w-1/2 mx-auto mt-1/4 bg-white p-12">
      {reason === WEBGL_NOT_SUPPORTED ? (
        <>
          WebGL is required to view your property boundaries on a map. Your
          browser does not support WebGL, or WebGL is currently disabled. For
          help, visit{" "}
          <a
            className="link"
            href="https://get.webgl.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://get.webgl.org/
          </a>
        </>
      ) : (
        <>
          Mapbox GL is required to view your property boundaries on a map. Your
          browser does not support Mapbox GL due to {reason}.
        </>
      )}
    </div>
  </div>
)

export default MapboxGLNotSupported
