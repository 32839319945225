import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { updateAttestations } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { AccountProjectDataTypes, ProjectListTypes } from "@/types/program"

export const useUpdateAttestations = <
  TData extends AccountProjectDataTypes,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  projectId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => updateAttestations(accountId, values),
    {
      _onSuccess: (data: AccountProjectDataTypes) => {
        // Update projects cache directly from mutation response data
        const projects = queryClient.getQueryData([
          "accounts",
          accountId,
          "projects",
        ]) as ProjectListTypes

        const accountProjectIndex = projects?.account_project_data.findIndex(
          (project) => project.id === data.id
        )

        if (accountProjectIndex !== -1) {
          projects.account_project_data[accountProjectIndex] = data
        } else {
          projects.account_project_data.push(data)
        }

        queryClient.setQueryData(["accounts", accountId, "projects"], projects)

        // Wipe out our list data and other ones which might hold a derived property
        queryClient.invalidateQueries({
          queryKey: [
            "accounts",
            accountId,
            "projects",
            projectId,
            "attestations",
          ],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-cycles"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })
        queryClient.invalidateQueries({ queryKey: ["accounts", accountId] })
      },
      ...config,
    }
  )
}
