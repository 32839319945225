import { ComponentType } from "react"

import { CYCLE_INFOS_BY_KEY } from "../../shared/constants"
import NothingFound from "../../components/NothingFound"
import { CommonDocumentLinkTypes } from "../../components/DocumentLink"
import { AccountCycleTypes } from "@/types/accountCycles"

interface CreatedDocumentsSectionTypes {
  accountCycle: AccountCycleTypes
  accountId: string
  documentComponents: ComponentType<CommonDocumentLinkTypes>[]
}

const CreatedDocumentsSection = ({
  accountCycle,
  accountId,
  documentComponents,
}: CreatedDocumentsSectionTypes) => {
  const cycleInfo = CYCLE_INFOS_BY_KEY[accountCycle.cycle_key]

  return (
    <div className="mb-8">
      {cycleInfo.longName !== "Upcoming" ? (
        <p className="text-lg mb-2">NCX {cycleInfo.longName}</p>
      ) : null}
      <div className="space-y-2">
        <p className="text-overline">Created by NCX</p>
        {documentComponents?.length === 0 ? (
          <NothingFound
            type="docs"
            title="Nothing to see here"
            text="NCX will fill this area with documents that we send you."
            className="py-8 md:py-12"
          />
        ) : (
          <>
            {documentComponents.map((DocumentComponent) => (
              // DEV: Using component function name as our key (e.g. ERDocumentLink)
              <div key={DocumentComponent.name}>
                <DocumentComponent
                  accountId={accountId}
                  accountCycle={accountCycle}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default CreatedDocumentsSection
