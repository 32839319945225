import { ReactNode } from "react"

interface ModalTextOnlyLayoutTypes {
  children: ReactNode
}

const ModalTextOnlyLayout = ({ children }: ModalTextOnlyLayoutTypes) => (
  <div className="flex flex-col lg:max-w-1095 md:justify-between gap-6">
    <div className="w-full space-y-p lg:max-w-624 mb-6">
      {children}
      <hr className="border-charcoal-50 mt-6 lg:max-w-624 xl:max-w-664" />
    </div>
  </div>
)

export default ModalTextOnlyLayout
