import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { _useDeleteNotification } from "../utils"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"

export function useDeleteAccountNotification<
  TData extends DashboardAccountsTypes,
  TError,
  TVariables extends string,
>(
  queryClient: QueryClient,
  accountId: string
): UseMutationResult<TData, TError, TVariables, unknown> {
  return _useDeleteNotification<TData, TError, TVariables>(queryClient, {
    listQueryKey: ["accounts", accountId, "notifications"],
  })
}
