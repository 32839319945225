import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import cx from "classnames"

import EMPTY_CARD from "../images/empty-card.svg"
import EMPTY_DOCS from "../images/empty-docs.svg"

interface NothingFound {
  type?: "card" | "docs"
  title: string
  text: ReactNode
  linkText?: string
  to?: string
  className?: string
  component?: ReactNode
  small?: boolean
}

const NothingFound = ({
  type = "card",
  title,
  text,
  linkText,
  to,
  className,
  component,
  small = false,
}: NothingFound) => {
  const types = {
    card: {
      img: EMPTY_CARD,
      className: small ? "w-92 md:w-17.5" : "w-92 md:w-134",
    },
    docs: {
      img: EMPTY_DOCS,
      className: "w-222 md:w-320",
    },
  }

  return (
    <div className={cx(className ? className : "container py-12 md:py-32")}>
      <div className="max-w-409 mx-auto text-center flex flex-col items-center">
        <div
          className={cx(
            "h-222 w-222 bg-grass-50 flex justify-center items-center rounded-full",
            small ? "md:h-41.5 md:w-41.5" : "md:h-320 md:w-320"
          )}
        >
          <img src={types[type].img} alt="" className={types[type].className} />
        </div>
        <h2 className="text-xl mt-8 mb-2">{title}</h2>
        <p className="font-normal text-dusk mb-6">{text}</p>
        {component}
        {to ? (
          <Link to={to} className="btn2 btn2-primary font-semibold mb-12">
            {linkText}
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default NothingFound
