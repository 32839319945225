import React, { Dispatch, SetStateAction, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Form, FormikProps } from "formik"

import { AUTH_STEPS } from "./constants"
import { ContentCard, ErrorCard } from "../../components/Card"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { AuthDataTypes } from "../../types/auth"

interface ResetPasswordFormComponentTypes extends FormikProps<AuthDataTypes> {
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
  errorMessage: string | null
}

const ResetPasswordFormComponent = ({
  setAuthData,
  values,
  errorMessage,
  isSubmitting,
}: ResetPasswordFormComponentTypes) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleBlur = useCallback(() => {
    setAuthData({
      email: values.email,
      password: values.password,
    })
  }, [setAuthData, values.email, values.password])

  return (
    <ContentCard>
      <h3 className="text-lg text-center mb-2">
        <span>Reset your password</span>
      </h3>
      <Form className="space-y-4 mb-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-4">
          <div className="space-y-1 mb-6">
            <WrappedInput
              data-test="input-email"
              label="Account email address"
              name="email"
              placeholder="name@example.com"
              type="text"
              as="email"
            />
          </div>

          <SubmitButton
            className="btn2 btn2-primary btn2-block font-semibold"
            isSubmitting={isSubmitting}
            data-test="reset-password"
          >
            Reset password
          </SubmitButton>
          <button
            type="button"
            data-test="log-in"
            className="btn2 btn2-outline-primary w-full font-bold text-sm md:text-base"
            onClick={() => {
              navigate(location.pathname, {
                state: { ...location.state, authStep: AUTH_STEPS.emailLogin },
              })
            }}
          >
            Never mind, return to login
          </button>
        </div>
      </Form>
      <hr className="border-cloud my-4"></hr>
      <div className="text-center">
        <a
          className="link--underline-only text-base text-dusk-500"
          href="mailto:landowners@ncx.com"
        >
          Having trouble with password reset?
          <br />
          Email us at <span className="link">landowners@ncx.com</span>
        </a>
      </div>
    </ContentCard>
  )
}

export default ResetPasswordFormComponent
