import Stat from "../../components/Stat"
import { LANDCOVER_TYPE_LABELS } from "../../shared/constants"
import { LandCoverData } from "@/types/property"

interface LandCoverStatsTypes {
  landCoverDetails: LandCoverData | null
}

const LandCoverStats = ({ landCoverDetails }: LandCoverStatsTypes) => (
  <div className="w-full landcover-stats">
    {landCoverDetails
      ? Object.keys(LANDCOVER_TYPE_LABELS)
          .filter(
            (type) =>
              landCoverDetails[type as keyof typeof landCoverDetails].pct *
                100 >
              0
          )
          .sort(
            (a, b) =>
              landCoverDetails[b as keyof typeof landCoverDetails].pct -
              landCoverDetails[a as keyof typeof landCoverDetails].pct
          )
          .map((type, i) => (
            <Stat
              key={i}
              label={
                LANDCOVER_TYPE_LABELS[
                  type as keyof typeof LANDCOVER_TYPE_LABELS
                ]
              }
              value={(
                landCoverDetails[type as keyof typeof landCoverDetails].pct *
                100
              ).toLocaleString("en-US")}
            />
          ))
      : null}
  </div>
)

export default LandCoverStats
