export const bellIcon = {
  icon: [
    24,
    24,
    [],
    "",
    "M18.7188 15.3438C18.9062 15.5312 19 15.7812 19 16C18.9688 16.5312 18.5938 17 17.9688 17H6C5.375 17 5 16.5312 5 16C4.96875 15.7812 5.0625 15.5312 5.25 15.3438C5.84375 14.6875 7 13.7188 7 10.5C7 8.09375 8.6875 6.15625 11 5.65625V5C11 4.46875 11.4375 4 12 4C12.5312 4 12.9688 4.46875 12.9688 5V5.65625C15.2812 6.15625 16.9688 8.09375 16.9688 10.5C16.9688 13.7188 18.125 14.6875 18.7188 15.3438ZM7.09375 15.5H16.875C16.2188 14.6562 15.5 13.1875 15.4688 10.5312C15.4688 10.5312 15.5 10.5312 15.5 10.5C15.5 8.59375 13.9062 7 12 7C10.0625 7 8.5 8.59375 8.5 10.5C8.5 10.5312 8.5 10.5312 8.5 10.5312C8.46875 13.1875 7.75 14.6562 7.09375 15.5ZM12 20C10.875 20 10 19.125 10 18H13.9688C13.9688 19.125 13.0938 20 12 20Z",
  ],
  iconName: "simple-icons-bell",
  prefix: "simple-icons",
}

export const circleQuestionMark = {
  icon: [
    24,
    24,
    [],
    "",
    "M12 4.25C16.25 4.25 19.75 7.75 19.75 12C19.75 16.2812 16.25 19.75 12 19.75C7.71875 19.75 4.25 16.2812 4.25 12C4.25 7.75 7.71875 4.25 12 4.25ZM12 18.25C15.4375 18.25 18.25 15.4688 18.25 12C18.25 8.5625 15.4375 5.75 12 5.75C8.53125 5.75 5.75 8.5625 5.75 12C5.75 15.4688 8.53125 18.25 12 18.25ZM15.3438 10.2812C15.3438 12.375 13.0625 12.4062 13.0625 13.1875V13.375C13.0625 13.5938 12.9062 13.75 12.6875 13.75H11.2812C11.0625 13.75 10.9062 13.5938 10.9062 13.375V13.125C10.9062 12 11.75 11.5625 12.375 11.1875C12.9375 10.875 13.2812 10.6875 13.2812 10.2812C13.2812 9.71875 12.5938 9.375 12.0312 9.375C11.3125 9.375 10.9688 9.71875 10.5 10.3125C10.375 10.4688 10.125 10.5 9.96875 10.375L9.09375 9.71875C8.9375 9.59375 8.90625 9.375 9.03125 9.21875C9.75 8.125 10.6875 7.5 12.1562 7.5C13.6875 7.5 15.3438 8.71875 15.3438 10.2812ZM13.3125 15.5C13.3125 16.25 12.7188 16.8125 12 16.8125C11.25 16.8125 10.6875 16.25 10.6875 15.5C10.6875 14.7812 11.25 14.1875 12 14.1875C12.7188 14.1875 13.3125 14.7812 13.3125 15.5Z",
  ],
  iconName: "simple-icons-bell",
  prefix: "simple-icons",
}
