import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
} from "react"

import PageHero from "./PageHero"
import CategoryFilter, { FilterType } from "./CategoryFilter/CategoryFilter"
import ProjectFilters from "./ProjectFilters"
import NothingFound from "../../components/NothingFound"
import { PROJECT_ACTIONS } from "../../shared/constants"
import ToggleSwitch from "../../components/ToggleSwitch"
import SelectMenu, { OptionType } from "../../components/SelectMenu"
import Banners from "./Banners/Banners"
import InvitationsBanner from "./InvitationsBanner"
import ProjectCard from "../../components/ProjectCard"
import NumericPagination from "../../components/NumericPagination"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { ProjectsContext } from "../../context/ProjectsContext"
import { ProjectDataTypes, ProjectListTypes } from "../../types/program"
import { Profile } from "../../types"
import { NotificationTypes } from "../../types/notifications"
import { DropdownOptionsType } from "../../pages/ProjectListing"
import { EligibilityQuizzes } from "../../types/eligibility"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../../types/constants"
import { AccountTypes } from "../../types/account"

interface ProjectListingContentTypes {
  data: ProjectListTypes | undefined
  profile: Profile | undefined
  account: AccountTypes | undefined
  projectsData: ProjectDataTypes[]
  invitesData: NotificationTypes[] | undefined
  handleFirstPageRedirect: () => void
  projectTypeFilters: FilterType[]
  dropdownOptions: DropdownOptionsType[]
  sortBy: string | undefined
  setSortBy: Dispatch<SetStateAction<string>>
  quizesData: EligibilityQuizzes | undefined
  quizCategory: EligibilityQuizCategoryType | undefined
  projectsDataIneligibleOnLength: number
  pageId: string | undefined
  PageSize: number
  listingRef: MutableRefObject<HTMLDivElement | null>
}

const ProjectListingContent = ({
  data,
  profile,
  account,
  projectsData,
  invitesData,
  handleFirstPageRedirect,
  projectTypeFilters,
  dropdownOptions,
  sortBy,
  setSortBy,
  quizesData,
  quizCategory,
  projectsDataIneligibleOnLength,
  pageId,
  PageSize,
  listingRef,
}: ProjectListingContentTypes) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const {
    state: { category, ineligibleProjectsOn },
    dispatch,
  } = useContext(ProjectsContext)

  const handleFiltersOnChange = useCallback(
    (filter: FilterType) => {
      const filterId = filter.id as ProgramCategoryType

      dispatch({
        type: PROJECT_ACTIONS.setCategory,
        payload: filterId === category ? null : filter.id,
      })

      handleFirstPageRedirect()
    },
    [category, dispatch, handleFirstPageRedirect]
  )

  if (profile === null || profile === undefined) {
    return null
  }

  if (data?.project_data?.length === 0) {
    return (
      <div className="bg-cloud-50">
        <PageHero handleFirstPageRedirect={handleFirstPageRedirect} />

        <NothingFound
          title="No programs found"
          text="There's no program opportunities available for review. Check back soon!"
          linkText="View Dashboard"
          to="/"
        />
      </div>
    )
  }

  return (
    <div className="bg-cloud-50">
      <PageHero handleFirstPageRedirect={handleFirstPageRedirect} />

      <CategoryFilter
        filters={projectTypeFilters}
        onChange={handleFiltersOnChange}
      />

      <div className="container max-w-6xl overflow-x-hidden sticky md:static top-16 md:top-auto z-10 bg-white shadow-dropdown md:shadow-none">
        <div className="flex flex-wrap flex-row items-center gap-4 pt-6 pb-6">
          <ProjectFilters
            filteredNumber={projectsData.length}
            handleFirstPageRedirect={handleFirstPageRedirect}
          />

          <div className="hidden xl:flex p-1.75 border border-dusk-50 rounded order-2">
            <ToggleSwitch
              label="Ineligible programs"
              value={ineligibleProjectsOn}
              onChange={() => {
                dispatch({
                  type: PROJECT_ACTIONS.setIneligibleProjectsOn,
                  payload: !ineligibleProjectsOn,
                })
                handleFirstPageRedirect()
              }}
            />
          </div>

          <SelectMenu
            className="order-1 xl:order-3 min-w-[252px]"
            menuClassName="min-w-[252px]"
            options={dropdownOptions}
            label="Sort by"
            showSelected={true}
            selectedOption={sortBy}
            setSelectedOption={setSortBy as (e: OptionType) => void}
            onSelect={handleFirstPageRedirect}
            placement="bottom-end"
            gutter={10}
          />
        </div>
      </div>

      <Banners
        account={account}
        quizesData={quizesData}
        quizCategory={quizCategory}
      />

      <InvitationsBanner invitesData={invitesData} />

      <div ref={listingRef} className="container max-w-6xl mb-12 mt-6 md:mt-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {projectsData.length === 0 ? (
            <NothingFound
              title={
                projectsDataIneligibleOnLength === 0
                  ? "No programs found"
                  : "No eligible programs"
              }
              text={
                projectsDataIneligibleOnLength === 0
                  ? "There are no project opportunities with those filters. Clear a filter to try again."
                  : "You're not eligible for any programs in this category. To see more, turn on ineligible programs."
              }
              linkText="View Dashboard"
              className="col-span-full py-18.5"
              component={
                projectsDataIneligibleOnLength > 0 ? (
                  <div className="flex p-1.75 border border-dusk-50 rounded order-3">
                    <ToggleSwitch
                      label="Ineligible programs"
                      value={ineligibleProjectsOn}
                      onChange={() => {
                        dispatch({
                          type: PROJECT_ACTIONS.setIneligibleProjectsOn,
                          payload: !ineligibleProjectsOn,
                        })
                        handleFirstPageRedirect()
                      }}
                    />
                  </div>
                ) : null
              }
              small
            />
          ) : (
            projectsData
              ?.slice(
                (Number(pageId) - 1) * PageSize,
                (Number(pageId) - 1) * PageSize + PageSize
              )
              .map((program) => (
                <ProjectCard key={program.id} programData={program} />
              ))
          )}
        </div>

        {projectsData && (
          <NumericPagination
            currentPage={Number(pageId)}
            totalCount={projectsData.length}
            pageSize={PageSize}
            urlRoot={
              isMultiAccount
                ? `/accounts/${accountId}/programs/page`
                : "/programs/page"
            }
            className="mt-18"
          />
        )}
      </div>
    </div>
  )
}

export default ProjectListingContent
