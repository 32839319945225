import { Dispatch, RefObject, SetStateAction } from "react"
import MapGL, { NavigationControl, Viewport } from "@urbica/react-map-gl"
// @ts-ignore
import Draw from "@urbica/react-map-gl-draw"
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
// @ts-ignore
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import { AddressAutocomplete } from "../AddressAutocomplete"
import { MapMainMenu } from "../MapMainMenu"
import { LayerPicker } from "../LayerPicker"
import { ParcelSelectLayer, ParcelSelectMode } from "../ParcelSelectLayer"
import mapVisualizationDrawStyles from "../../shared/mapVisualizationDrawStyles"
import { MAPBOX_TOKEN, mapStyles } from "../../shared/constants"
import { FileUploadMode } from "./helpers"
import { LayerType } from "@/types/mapbox"
import { FeatureType } from "@/types"
import { DataTypes, DrawRef, MapGLRef, PayloadType } from "./types"

interface MapVisualizationMapProps {
  mapContainerRef: RefObject<HTMLDivElement>
  mapRef: MapGLRef
  drawRef: DrawRef
  layer: LayerType
  viewport: Viewport
  mode: string
  onViewportChange: (viewport: Viewport) => void
  features: FeatureType[]
  displayMapEditingTools: boolean
  modeOptions: any
  handleDrawSelectionChange: (data: DataTypes) => void
  handleDrawDelete: (payload: PayloadType) => void
  handleDrawCreate: (payload: PayloadType) => void
  handleDrawUpdate: (payload: PayloadType) => void
  handleDrawModeChange: ({ mode }: { mode: string }) => void
  showMenuSelect: () => boolean | undefined
  showMenuParcelSelect: () => boolean | undefined
  showMenuDrawPolygon: () => boolean | undefined
  showMenuEdit: () => boolean | undefined
  showMenuTrash: () => boolean | undefined
  showMenuBack: () => boolean | undefined
  showMenuClear: () => boolean | undefined
  handleMenuParcelSelect: () => void
  handleMenuDrawPolygon: () => void
  handleMenuEdit: () => void
  handleMenuTrash: () => void
  handleMenuBack: () => void
  handleMenuFileUpload: () => void
  onFeatureClear: () => void
  showMenuFileUpload: () => boolean | undefined
  handleMenuSelect: () => void
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  handleSearchSelect: (address: string) => void
  setLayer: Dispatch<SetStateAction<LayerType>>
  setMode: Dispatch<SetStateAction<string>>
}

const MapVisualizationMap = ({
  mapContainerRef,
  mapRef,
  drawRef,
  layer,
  viewport,
  mode,
  onViewportChange,
  features,
  displayMapEditingTools,
  modeOptions,
  handleDrawSelectionChange,
  handleDrawDelete,
  handleDrawCreate,
  handleDrawUpdate,
  handleDrawModeChange,
  showMenuSelect,
  showMenuParcelSelect,
  showMenuDrawPolygon,
  showMenuEdit,
  showMenuTrash,
  showMenuBack,
  showMenuClear,
  handleMenuParcelSelect,
  handleMenuDrawPolygon,
  handleMenuEdit,
  handleMenuTrash,
  handleMenuBack,
  handleMenuFileUpload,
  onFeatureClear,
  showMenuFileUpload,
  handleMenuSelect,
  search,
  setSearch,
  handleSearchSelect,
  setLayer,
  setMode,
}: MapVisualizationMapProps) => (
  <div
    ref={mapContainerRef}
    tabIndex={0}
    className="map-vizualization relative flex-auto flex flex-col"
    aria-label="Map"
  >
    <MapGL
      ref={mapRef}
      pitchWithRotate={false}
      dragRotate={false}
      className="flex-auto"
      mapStyle={mapStyles[layer].url}
      accessToken={MAPBOX_TOKEN}
      onViewportChange={onViewportChange}
      latitude={viewport.latitude}
      longitude={viewport.longitude}
      zoom={viewport.zoom}
      showTileBoundaries={false}
      maxZoom={17}
    >
      {mode === "parcel_select" && (
        <ParcelSelectLayer mapRef={mapRef} layer={layer} features={features} />
      )}

      {/* DEV: Draw must always be rendered to properly load `ParcelSelectMode` */}
      <Draw
        ref={drawRef}
        data={{
          type: "FeatureCollection",
          features: mode === "parcel_select" ? [] : features,
        }}
        styles={mapVisualizationDrawStyles}
        mode={!displayMapEditingTools ? "static" : mode}
        modeOptions={modeOptions}
        modes={(defaultModes: { [x: string]: any }) => ({
          ...defaultModes,
          parcel_select: ParcelSelectMode,
          file_upload: FileUploadMode,
          reset: defaultModes["simple_select"],
          static: StaticMode,
        })}
        lineStringControl={false}
        polygonControl={false}
        pointControl={false}
        combineFeaturesControl={false}
        uncombineFeaturesControl={false}
        trashControl={false}
        onDrawSelectionChange={handleDrawSelectionChange}
        onDrawDelete={handleDrawDelete}
        onDrawCreate={handleDrawCreate}
        onDrawUpdate={handleDrawUpdate}
        onDrawModeChange={handleDrawModeChange}
      />

      <NavigationControl showCompass={false} showZoom position="top-right" />
    </MapGL>

    {/* DEV: We don't use any top-bar <div> as it would block click actions at top of map, despite transparent bg */}
    {displayMapEditingTools && (
      <MapMainMenu
        className="absolute top-20 left-4 md:top-4 lg:top-12 lg:left-12"
        mode={mode}
        isSelectVisible={showMenuSelect()}
        isParcelSelectVisible={showMenuParcelSelect()}
        isDrawPolygonVisible={showMenuDrawPolygon()}
        isFileUploadVisible={showMenuFileUpload()}
        isEditVisible={showMenuEdit()}
        isTrashVisible={showMenuTrash()}
        isBackVisible={showMenuBack()}
        isClearVisible={showMenuClear()}
        onClickSelect={handleMenuSelect}
        onClickParcelSelect={handleMenuParcelSelect}
        onClickDrawPolygon={handleMenuDrawPolygon}
        onClickFileUpload={handleMenuFileUpload}
        onClickEdit={handleMenuEdit}
        onClickTrash={handleMenuTrash}
        onClickBack={handleMenuBack}
        onClickClear={onFeatureClear}
      />
    )}

    {/* DEV: inset-x-0 sets left/right: 0 which combined with mx-auto centers absolutely */}
    {/*   https://css-tricks.com/forums/topic/horizontal-centering-of-an-absolute-element/ */}
    {typeof google?.maps?.places !== "undefined" ? (
      <div className="absolute xs:max-w-[356px] top-4 lg:top-12 inset-x-4 md:inset-x-0 md:mx-auto">
        <AddressAutocomplete
          className="bg-white rounded"
          value={search}
          onChange={setSearch}
          onSelect={(address) => {
            handleSearchSelect(address)
          }}
          onError={() => {}}
        />
      </div>
    ) : null}

    {/* DEV: Using div wrapper for LayerPicker as LayerPicker is using `absolute` and we can't guarantee CSS order */}
    <div className="absolute md:top-28 md:right-4 lg:top-36 lg:right-12 hidden md:block">
      <LayerPicker
        layer={layer}
        onLayerChange={(layer) => {
          setLayer(layer)
          // urbica react-map-gl does not support creating a map initially in direct_select mode,
          // because this mode requires a featureId to be provided as `modeOptions`, and the MapGL
          // component doesn't accept `modeOptions` on creation.
          // A layer change will cause a rerender of the MapGL component, so reset the mode if it
          // is currently direct_select.
          if (mode === "direct_select") {
            setMode("reset")
          }
        }}
      />
    </div>
  </div>
)

export default MapVisualizationMap
