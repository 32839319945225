import { useEffect, useRef, useState } from "react"
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts"
import debounce from "lodash/debounce"
import cx from "classnames"

import CustomTooltip from "./CustomTooltip"
import { useChartData, useYearTicks } from "./helpers"
import { INDEX_DATA_NAMES, scenarios } from "./constants"
import { IndexDataScenarioType, IndexDataType } from "@/types/property"

interface NaturalCapitalEstimatorChartTypes {
  indexData: IndexDataType
}

const NaturalCapitalEstimatorChart = ({
  indexData,
}: NaturalCapitalEstimatorChartTypes) => {
  const [chartWidth, setChartWidth] = useState<number>(0)
  const [selectedScenario, setSelectedScenario] =
    useState<IndexDataScenarioType>("base")

  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chartData = useChartData(indexData, selectedScenario)
  const { minYear, maxYear, ticks } = useYearTicks(indexData)

  const updateChartWidth = () => {
    if (chartContainerRef.current) {
      const containerWidth = chartContainerRef.current.offsetWidth
      setChartWidth(containerWidth)
    }
  }

  const debouncedUpdateChartWidth = debounce(updateChartWidth, 250)

  useEffect(() => {
    // DEV: Initial width calculation
    updateChartWidth()

    window.addEventListener("resize", debouncedUpdateChartWidth)

    return () => {
      window.removeEventListener("resize", debouncedUpdateChartWidth)
      debouncedUpdateChartWidth.cancel() // DEV: Cancel any pending debounced calls
    }
  }, [debouncedUpdateChartWidth])

  return (
    <div ref={chartContainerRef} className="w-full">
      <LineChart
        data={chartData}
        width={chartWidth}
        height={400}
        margin={{ top: 20, right: 15, left: -10, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey="year"
          type="number"
          domain={[minYear, maxYear]}
          ticks={ticks}
          tick={{
            fill: "#6B7280",
            stroke: "none",
            fontWeight: "normal",
            fontSize: 14,
            letterSpacing: "0.32px",
          }}
          label={{
            value: "Year",
            dy: 15,
          }}
        />

        <YAxis
          domain={[0, 1]}
          ticks={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
          tickFormatter={(v) => `${(v * 100).toFixed(0)}`}
          tick={{
            fill: "#6B7280",
            stroke: "none",
            fontWeight: "normal",
            fontSize: 14,
            letterSpacing: "0.32px",
          }}
          label={{
            value: "Index",
            angle: -90,
            position: "insideLeft",
            dx: 10,
          }}
        />

        <Tooltip content={<CustomTooltip />} />

        {/* Timber Index - History Line */}
        <Line
          type="monotone"
          dataKey="timberIndexHistory"
          stroke="#dd6d2c"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.timber_index}
        />

        {/* Timber Index - Scenario Line */}
        <Line
          type="monotone"
          dataKey="timberIndexScenario"
          stroke="#dd6d2c"
          strokeDasharray="5 5"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.timber_index}
        />

        {/* Forest Health Index - History Line */}
        <Line
          type="monotone"
          dataKey="forestHealthHistory"
          stroke="#107C42"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.forest_health_index}
        />

        {/* Forest Health Index - Scenario Line */}
        <Line
          type="monotone"
          dataKey="forestHealthScenario"
          stroke="#107C42"
          strokeDasharray="5 5"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.forest_health_index}
        />

        {/* Carbon Index - History Line */}
        <Line
          type="monotone"
          dataKey="carbonIndexHistory"
          stroke="#3B30A3"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.carbon_index}
        />

        {/* Carbon Index - Scenario Line */}
        <Line
          type="monotone"
          dataKey="carbonIndexScenario"
          stroke="#3B30A3"
          strokeDasharray="5 5"
          strokeWidth={1}
          dot={false}
          activeDot={{ r: 8 }}
          name={INDEX_DATA_NAMES.carbon_index}
        />
      </LineChart>

      <div className="flex relative w-full mt-4" role="group">
        {scenarios.map((scenario, i, arr) => (
          <button
            key={scenario.key}
            onClick={() => setSelectedScenario(scenario.key)}
            className={cx(
              "btn2 btn2-sm sm:px-6 sm:py-3 grow font-semibold relative text-sm sm:text-base rounded-none hover:z-[2]",
              { "-ml-px": i !== 0 },
              { "rounded-s": i === 0 },
              { "rounded-e": i === arr.length - 1 },
              selectedScenario === scenario.key
                ? "btn2-primary"
                : "btn2-outline-primary"
            )}
          >
            {scenario.label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default NaturalCapitalEstimatorChart
