// Provide a common mutation config for accountUser mutations, to avoid redundant cache busting
import { QueryClient, UseMutationOptions } from "@tanstack/react-query"

import { _getListMutationConfig } from "."
import { AccountUsers } from "@/types/accountUsers"

interface AccountUserListMutationConfig<TData> {
  action: "add" | "update" | "remove"
  accountId: string
  email?: string | null
  listQueryEagerMutateFn: (item: unknown, list: TData) => TData
  [key: string]: any
}

export const _getAccountUserListMutationConfig = <
  TData extends AccountUsers,
  TError,
  TVariables,
>(
  queryClient: QueryClient,
  {
    action,
    accountId,
    email,
    listQueryEagerMutateFn,
    ...properties
  }: AccountUserListMutationConfig<TData>
): UseMutationOptions<TData, TError, TVariables> => {
  const listQueryKey = ["accounts", accountId, "users"]

  return _getListMutationConfig<TData, TError, TVariables>(queryClient, {
    listQueryKeyAndEagerMutateFnTuples: [
      [listQueryKey, listQueryEagerMutateFn],
    ],
    onSettled: () => {
      if (action === "add") {
        return
      } else if (action === "update") {
        const previousAccountUsersPage =
          queryClient.getQueryData<TData>(listQueryKey)

        if (
          previousAccountUsersPage?.object_list.find(
            (accountUser) => accountUser.email === email
          )?.is_requester
        ) {
          queryClient.invalidateQueries({
            queryKey: ["accounts", accountId],
            exact: true,
          })
        }
      }
    },
    ...properties,
  })
}
