import { ReactNode, useEffect, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"
import cx from "classnames"

import MapboxGLNotSupported from "../../components/MapVisualization/MapboxGLNotSupported"
import ProjectMapVisualization from "../ProjectDetails/ProjectMap/ProjectMapVisualization"
import ProjectMapParcels from "../ProjectDetails/ProjectMap/ProjectMapParcels"
import ProjectMapSkeleton from "../ProjectDetails/ProjectMap/ProjectMapSkeleton"
import { getViewportFromFeature } from "../ProjectDetails/ProjectMap/helpers"
import { genericErrMsg } from "../../api/auth"
import { useChangeDetection, useGetParcelGeoms } from "../../hooks/index"
import useViewport from "../../hooks/useViewport"
import { handleNextFeature, handlePrevFeature } from "./helpers"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import { ViewportTypes } from "@/types"
import { TileURLsTypes } from "@/types/tiles"

interface PropertyMapTypes {
  accountId: string
  footer?: ReactNode
  legend?: ReactNode
  overlay?: ReactNode
  changeDetection?: boolean
  enableZoomPan?: boolean
  isWidget?: boolean
}

// This is similar to ProjectMap except removing project-related eligibility elements
const PropertyMap = ({
  accountId,
  footer,
  legend,
  overlay,
  changeDetection = false,
  enableZoomPan = false,
  isWidget = false,
}: PropertyMapTypes) => {
  const [activeFeature, setActiveFeature] = useState<number | null>(0)
  const [viewport, setViewport] = useState<ViewportTypes | null>(null)
  const { width } = useViewport()
  const queryClient = useQueryClient()

  const isMobile = width < 768

  // DEV: we only care about parcel data because this isn't about eligibility, just a parcel viewer
  const { data: parcelData, isLoading: parcelIsLoading } = useGetParcelGeoms<
    FeatureCollection<Geometry, Properties>,
    Error
  >(accountId)

  const multipleParcels =
    Array.isArray(parcelData?.features) && parcelData?.features.length > 1

  const { data: changeDetectionData, isLoading: changeDetectionIsLoading } =
    useChangeDetection<TileURLsTypes, Error>(queryClient, accountId, {
      enabled: changeDetection,
    })

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  return (
    <div className="card card-content shadow-none relative my-4 p-0">
      <div
        className={cx(
          "relative rounded overflow-hidden",
          isWidget ? "xl:flex" : "lg:flex"
        )}
      >
        {parcelIsLoading || changeDetectionIsLoading ? (
          <ProjectMapSkeleton animate isWidget={isWidget} />
        ) : parcelData ? (
          <>
            {isMobile ? footer : null}
            <ProjectMapVisualization
              viewport={viewport}
              setViewport={setViewport}
              parcelData={parcelData}
              tileData={changeDetectionData}
              activeFeature={activeFeature}
              isIneligible={false}
              enableEligibility={changeDetection}
              enableZoomPan={enableZoomPan}
              legend={legend}
              overlay={overlay}
            />
            {multipleParcels && (
              <ProjectMapParcels
                isIneligible={false}
                parcelData={parcelData}
                activeFeature={activeFeature}
                setActiveFeature={setActiveFeature}
                setViewport={setViewport}
                onNextFeatureClick={handleNextFeature}
                onPrevFeatureClick={handlePrevFeature}
                enableEligibility={false}
                className="property-map-landcover-report"
                isWidget={isWidget}
              />
            )}
          </>
        ) : (
          <ProjectMapSkeleton
            message={
              <div
                className={cx(
                  "h-[400px] relative rounded overflow-hidden p-6",
                  isWidget ? "xl:max-w-full xl:flex" : "lg:max-w-full lg:flex "
                )}
              >
                <div className="w-full h-full">
                  <div
                    className={cx(
                      "w-full mx-auto mt-1/4 bg-white p-12",
                      isWidget ? "lg:w-2/3 xxl:w-1/2" : "md:w-2/3 xl:w-1/2"
                    )}
                  >
                    {genericErrMsg}
                  </div>
                </div>
              </div>
            }
            isWidget={isWidget}
          />
        )}
      </div>
      {!isMobile ? footer : null}
    </div>
  )
}

export default PropertyMap
