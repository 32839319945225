import { ProgramCategoryType } from "@/types/constants"
import DOMPurify from "dompurify"

interface EligibilityQuizHeaderTypes {
  eyebrow: string
  text: string
  tooltip?: string
  isMobile: boolean
  imageUrl: string | undefined
  quizType: ProgramCategoryType | undefined
}

const EligibilityQuizHeader = ({
  eyebrow,
  text,
  tooltip = "",
  isMobile,
  imageUrl,
  quizType,
}: EligibilityQuizHeaderTypes) => (
  <>
    <p className="text-overline">{eyebrow}</p>

    <h2 className="text-charcoal-500 text-xl lg:text-2xl leading-120 tracking-0.4 md:tracking-0.48 mt-2">
      {text}
    </h2>

    {tooltip?.length > 0 ? (
      <div
        className="quiz-header-tooltip text-dusk text-base leading-130 tracking-0.32 mt-3"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(tooltip, {
            ADD_ATTR: ["target"],
          }),
        }}
      />
    ) : null}

    {isMobile ? (
      <div className="w-full flex justify-center mt-8 md:mt-12">
        <img
          src={imageUrl}
          alt={quizType}
          className="w-24 sm:w-28 md:w-[184px] h-auto block"
        />
      </div>
    ) : null}
  </>
)

export default EligibilityQuizHeader
