import { useState } from "react"
import { useDropzone } from "react-dropzone"
import cx from "classnames"
import shp from "shpjs"
// @ts-ignore
import tj from "@mapbox/togeojson"
// @ts-ignore
import flatten from "geojson-flatten"
import prettyBytes from "pretty-bytes"

import JSZip, { JSZipObject } from "jszip"

import { Feature, Properties, feature as turfFeature } from "@turf/helpers"

import { PlatformError } from "../api/errors"

interface UploadPropertyTypes {
  onAddBoundsToMap: (data: { features: any[] }) => void
  onSuccess: () => void
}

const ALLOWED_GEOMETRY_TYPES = ["Polygon", "MultiPolygon"]
// Extensions in this list are assumed to be 3 characters long
const MANDATORY_SHAPEFILE_EXTENSIONS = ["shp", "shx", "dbf", "prj"]

const getZipFiles = async (buffer: ArrayBuffer) => {
  const zip = new JSZip()
  await zip.loadAsync(buffer)

  return zip.file(/.+/)
}

const isShapefileValid = (zipFiles: JSZipObject[]) => {
  return MANDATORY_SHAPEFILE_EXTENSIONS.every((extension) =>
    zipFiles.some((file) => file.name.slice(-3).toLowerCase() === extension)
  )
}

const isProjectedInNatAtlas = async (zipFiles: JSZipObject[]) => {
  const prjFile = zipFiles.filter(
    (a) => a.name.slice(-3).toLowerCase() === "prj"
  )
  const prjContent = await prjFile[0].async("string")
  return prjContent.includes("US_National_Atlas_Equal_Area")
}

const getRandomFeatureId = () => {
  return "_" + Math.random().toString(36).substr(2, 9)
}

const reduceFeatures = (result: Feature[], feature: Feature) => {
  if (ALLOWED_GEOMETRY_TYPES.includes(feature?.geometry?.type)) {
    result.push({
      ...feature,
      id: getRandomFeatureId(),
    })
  }
  return result
}

const convertMultipolygon = (feature: Feature) => {
  if (feature?.geometry?.type === "MultiPolygon") {
    const result: Feature<Properties>[] = []
    feature.geometry.coordinates.forEach(function (coords) {
      result.push(
        turfFeature({
          type: "Polygon",
          coordinates: coords,
          id: getRandomFeatureId(),
        })
      )
    })
    return result
  }
  return feature
}

const dropZCoordValue = (feature: {
  geometry: { coordinates: string | any[] }
}) => {
  if (feature?.geometry?.coordinates?.length === 0) {
    return feature
  }
  feature.geometry.coordinates[0].forEach((coord: void[]) => {
    if (coord?.length === 3) {
      // pop removed the last item in an array
      coord.pop()
    }
  })
  return feature
}

export const UploadProperty = ({
  onAddBoundsToMap,
  onSuccess,
}: UploadPropertyTypes) => {
  const [files, setFiles] = useState<File[]>([])
  const [error, setError] = useState<PlatformError | null>(null)

  const handleDrop = (files: File[]) => {
    setFiles(files)
    setError(null)
  }

  // ASANA once design direction is decided for upload failure, replace this
  // https://app.asana.com/0/1202717680944003/1203162385484071/f
  const handleRejected = () => {
    alert("Please upload a single KML (.kml) or zipped ESRI (.zip) shapefile.")
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDropAccepted: handleDrop,
    onDropRejected: handleRejected,
    accept: [".kml", ".zip"],
  })

  const dzClass = cx(
    "dropzone border-2 border-dashed p-4 h-64 flex items-center justify-center rounded",
    {
      "border-gray-300": !isDragActive,
      "border-green-light bg-gray-100": isDragActive,
    }
  )

  const handleRemove = () => {
    setFiles([])
    setError(null)
  }

  const handleUpload = async () => {
    try {
      const parser = new DOMParser()
      await Promise.all(
        files.map(async (file) => {
          let geojson: any
          let isFileinNatAtlas = false

          if (file.name.endsWith(".kml")) {
            const kmlText = await file.text()
            const kml = parser.parseFromString(kmlText, "text/xml")
            geojson = flatten(tj.kml(kml))
          } else if (file.name.endsWith(".zip")) {
            const buffer = await file.arrayBuffer()
            const zipFiles = await getZipFiles(buffer)
            if (!isShapefileValid(zipFiles)) {
              throw new PlatformError(
                `The uploaded shapefile must contain ${MANDATORY_SHAPEFILE_EXTENSIONS.join(
                  ", "
                )} files`,
                { showMessage: true }
              )
            }

            isFileinNatAtlas = await isProjectedInNatAtlas(zipFiles)
            geojson = await shp(buffer)
          }
          if (isFileinNatAtlas) {
            throw new PlatformError(
              "It appears your shapefile is projected in National Atlas (EPSG:2163). " +
                "2163 has recently been deprecated and we are unable to process the projection " +
                "properly.  We suggest reprojecting to another coordinate system such as " +
                "WGS 84.  If you have any questions, contact us at landowners@ncx.com",
              { showMessage: true }
            )
          }
          const rawFeatures = geojson.features.reduce(reduceFeatures, [])
          const features = rawFeatures
            .map(convertMultipolygon)
            .flat()
            .map(dropZCoordValue)
            .flat()

          if (features?.length === 0) {
            throw new PlatformError(
              "No supported spatial features were found. Supported types: " +
                ALLOWED_GEOMETRY_TYPES.join(","),
              { showMessage: true }
            )
          }
          onAddBoundsToMap({ features })
        })
      )
      setFiles([])
      setError(null)
      onSuccess()
    } catch (e: any) {
      console.error(e)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setError(e)
    }
  }

  const fileClass = cx("py-4 border-b border-gray-200", {
    "bg-red-100": !!error,
  })

  return (
    <div className="bg-white rounded">
      <div
        {...getRootProps({
          className: dzClass,
        })}
      >
        <input {...getInputProps()} />
        <div className="text-center stack-4">
          <p className="text-base text-gray-500 max-w-xs">
            Drag and drop your .kml or .zip ESRI shapefile here or click to
            manually select a file for upload
          </p>
          <p className="text-base text-gray-500 max-w-xs mt-2">
            All uploaded files must total 10MB or less
          </p>

          <div className="mt-4">
            <button
              className="btn2 btn2-outline-primary font-semibold"
              type="button"
            >
              Choose file
            </button>
          </div>
        </div>
      </div>
      {files.length > 0 && (
        <div className="my-4">
          <div className="border-b border-gray-200 pb-2">
            <p className="text-xs text-overline">Selected File</p>
          </div>
          {files.map((file) => {
            return (
              <div key={file.name} className={fileClass}>
                <div className="flex items-center justify-between">
                  <div className="space-x-2 flex items-center">
                    <button
                      type="button"
                      onClick={handleRemove}
                      className="rounded-full w-8 h-8 border border-transparent hover:border-gray-100 hover:bg-gray-100 text-gray-600 hover:text-dark-900"
                    >
                      <i
                        className="fal fa-fw fa-times"
                        aria-label="Remove file"
                      />
                    </button>
                    <p>
                      {file.name} ({prettyBytes(file.size)})
                    </p>
                  </div>
                  <button
                    type="button"
                    className="btn2 btn2-primary font-semibold"
                    onClick={() => {
                      handleUpload()
                    }}
                  >
                    Add Bounds to Map
                  </button>
                </div>
              </div>
            )
          })}
          {error && (
            <div className="my-4">
              <p className="text-red-600 text-sm">
                {error?.showMessage
                  ? error.message
                  : "An error occurred. Please try selecting a different file or uploading again."}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
