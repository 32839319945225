import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getAttestationQuiz } from "../../../api/data"
import { EligibilityQuizCategoryType } from "@/types/constants"

export const useGetAttestationQuiz = <TData, TError = unknown>(
  accountId: string,
  quizCategory: EligibilityQuizCategoryType,
  sendAll: boolean,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "attestationQuiz", quizCategory],
    queryFn: async () => getAttestationQuiz(accountId, quizCategory, sendAll),
    ...config,
  })
}
