import { Feature, FeatureCollection, Geometry, Properties } from "@turf/helpers"

import { getViewportFromFeature, simplifyGeoJSON } from "./helpers"
import { MAPBOX_TOKEN, mapStyles } from "../../../shared/constants"

interface ProjectMapParcelsFeatureProps {
  parcelData: FeatureCollection<Geometry, Properties>
  featureIndex: number
}

const SIZE = 48

const ProjectMapParcelsFeature = ({
  parcelData,
  featureIndex,
}: ProjectMapParcelsFeatureProps) => {
  const { longitude, latitude, zoom } = getViewportFromFeature(
    parcelData?.features[featureIndex],
    SIZE,
    SIZE
  )
  const parcelGeoJSON: Feature<Geometry, Properties> =
    parcelData?.features[featureIndex]
  const styledGeoJSON: FeatureCollection<Geometry, Properties> = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          fill: "#3BB2D0",
          "fill-opacity": 0.8,
          stroke: "transparent",
        },
        geometry: parcelGeoJSON.geometry,
      },
    ],
  }

  const geoJSONString: string = encodeURIComponent(
    JSON.stringify(simplifyGeoJSON(styledGeoJSON))
  )
  const mapStyle: string = mapStyles.aerial.url.slice("mapbox://styles/".length)
  const staticMapURL: string = `https://api.mapbox.com/styles/v1/${mapStyle}/static/geojson(${geoJSONString})/${longitude},${latitude},${zoom}/${SIZE}x${SIZE}?access_token=${MAPBOX_TOKEN}&format=png`

  return (
    <div className="project-map-parcel-feature w-12 h-12 bg-gray-500 rounded overflow-hidden">
      <img
        src={staticMapURL}
        alt="Static Map"
        className="w-full h-full object-cover"
      />
    </div>
  )
}

export default ProjectMapParcelsFeature
