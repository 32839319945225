import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getParcelAssessment } from "../../../api/data"

export const useGetParcelAssessment = <TData, TError>(
  accountId: string,
  projectId: string,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "parcel", "assessment", projectId],
    queryFn: async () => getParcelAssessment(accountId, projectId),
    ...config,
  })
}
