import { Dispatch, SetStateAction } from "react"
import cx from "classnames"

import { ActiveTabType } from "../Land"

interface LandTabButtonProps {
  tab: ActiveTabType
  text: "Land Cover" | "Loss Detection"
  activeTab: ActiveTabType
  setActiveTab: Dispatch<SetStateAction<ActiveTabType>>
}

const LandTabButton = ({
  tab,
  text,
  activeTab,
  setActiveTab,
}: LandTabButtonProps) => (
  <button
    type="button"
    className={cx(
      "btn2 font-bold",
      tab === activeTab
        ? "btn2-primary border border-grass hover:border-grass-900"
        : "btn2-outline-primary",
      tab === "land-cover"
        ? "rounded-se-none rounded-ee-none"
        : "rounded-ss-none rounded-es-none"
    )}
    onClick={() => setActiveTab(tab)}
  >
    {text}
  </button>
)

export default LandTabButton
