import TREE_PLANTING from "../../images/eligibility-quiz-tree_planting.webp"
import FOREST_CARBON from "../../images/eligibility-quiz-forest_carbon.webp"
import RECREATION from "../../images/eligibility-quiz-recreation.webp"
import REGEN_AG from "../../images/eligibility-quiz-regen_ag.webp"
import RENEWABLE_INFRASTRUCTURE from "../../images/eligibility-quiz-renewable_infrastructure.webp"
import WILDFIRE from "../../images/eligibility-quiz-wildfire.webp"
import TIMBER from "../../images/eligibility-quiz-timber.webp"
import ADVISORY_SERVICES from "../../images/eligibility-quiz-advisory_services.webp"
import GENERAL from "../../images/eligibility-quiz-general.webp"
import TREE_PLANTING_GUIDE from "../../images/tree-planting-guide.png"
import FOREST_CARBON_GUIDE from "../../images/forest-carbon-guide.jpg"
import RENEWABLE_GUIDE from "../../images/solar-guide.jpg"
import TIMBER_GUIDE from "../../images/timber-guide.png"
import RECREATION_GUIDE from "../../images/recreation-guide.jpg"
import { EligibilityQuizCategoryType } from "@/types/constants"

export const eligibilityQuizBackground = (
  category: EligibilityQuizCategoryType | undefined
) => {
  const categories = {
    tree_planting: TREE_PLANTING,
    forest_carbon: FOREST_CARBON,
    recreation: RECREATION,
    regen_ag: REGEN_AG,
    renewable_infrastructure: RENEWABLE_INFRASTRUCTURE,
    wildfire: WILDFIRE,
    timber: TIMBER,
    advisory_services: ADVISORY_SERVICES,
    default: GENERAL,
  }

  return categories[category as keyof typeof categories] || categories.default
}

export const guidesBackground = (
  category: EligibilityQuizCategoryType | undefined
) => {
  const categories = {
    timber: TIMBER_GUIDE,
    renewable_infrastructure: RENEWABLE_GUIDE,
    forest_carbon: FOREST_CARBON_GUIDE,
    tree_planting: TREE_PLANTING_GUIDE,
    recreation: RECREATION_GUIDE,
  }

  return categories[category as keyof typeof categories]
}
