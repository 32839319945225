import React from "react"
import cx from "classnames"
import { usePopoverState } from "reakit/Popover"

import Popover from "./Popover"
import UNVERIFIED_BADGE from "../images/unverified-badge.svg"
import VERIFIED_BADGE from "../images/verified-badge.svg"

interface ProjectVerificationBadgeTypes {
  isVerified?: boolean
  includeLabel: boolean
}

const ProjectVerificationBadge = ({
  isVerified,
  includeLabel,
}: ProjectVerificationBadgeTypes) => {
  const verificationPopover = usePopoverState({
    placement: "top",
  })

  const learnMoreLink = (
    <a
      href="https://help.ncx.com/hc/en-us/articles/27881997151131-Program-Verification-Status"
      rel="noopener noreferrer"
      target="_blank"
      className="link"
    >
      Learn More
    </a>
  )

  const tooltipText = isVerified
    ? "This program's details have been verified."
    : "This developer has not verified their program details."

  const content = (
    <div className="flex items-center verified-badge-icon">
      <img
        className={cx("inline-block shrink-0 mr-2")}
        src={isVerified ? VERIFIED_BADGE : UNVERIFIED_BADGE}
        alt={isVerified ? "verified" : "unverified"}
      />
      {includeLabel && (
        <span
          className={cx(
            "verified-text",
            isVerified ? "text-leaf font-semibold" : "text-dusk-700 font-normal"
          )}
        >
          {isVerified ? "Verified" : "Unverified"}
        </span>
      )}
    </div>
  )

  return (
    <Popover
      popover={verificationPopover}
      content={content}
      label={isVerified ? "Verified" : "Unverified"}
      className="w-64"
    >
      <span>
        {tooltipText} {learnMoreLink}
      </span>
    </Popover>
  )
}

export default ProjectVerificationBadge
