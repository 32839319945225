import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { _useDeleteNotification } from "../utils"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"

export function useDeleteGlobalNotification<
  TData extends DashboardAccountsTypes,
  TError,
  TVariables extends string,
>(
  queryClient: QueryClient
): UseMutationResult<TData, TError, TVariables, unknown> {
  return _useDeleteNotification(queryClient, {
    listQueryKey: ["notifications", "global"],
  })
}
