import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { createAsanaTask } from "../../../api/data"
import { AsanaTaskCreationParams } from "@/types/asana"

/**
 * Creates a new Asana task in the given project(s)
 * @param {QueryClient} queryClient - the query client to use
 * @param {object} taskData - the data to use to create the task with following keys:
 *  {string} taskName - the name to give the task
 *  {string[]} projects - the project(s) to add the task to
 *  {string} description - the body of the description. Can be HTML
 *  see https://developers.asana.com/reference/createtask for more info on html formatting
 *  {boolean} useHtmlDescription - whether to use HTML in the description (default is false)
 */
export const useCreateAsanaTask = <
  TData,
  TError,
  TVariables extends AsanaTaskCreationParams,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> => {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (taskParams: TVariables) => createAsanaTask(taskParams),
    config
  )
}
