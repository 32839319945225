import React from "react"
import { Link, useLocation } from "react-router-dom"

import { ProjectBadge } from "../../components/ProjectBadge"

interface AccountRowTypes {
  id: number
  name: string
  totalAcres: number | string
  accountProjectsToView: number
}

const AccountRow = ({
  id,
  name,
  totalAcres,
  accountProjectsToView,
}: AccountRowTypes) => {
  const location = useLocation()
  const returnPath = location.state?.from?.pathname
  const returnPathIsNotProjectListing =
    returnPath && !returnPath.includes("page")
  const returnPathIsInbox = returnPath && returnPath.includes("inbox")

  return (
    <div className="flex flex-wrap justify-between sm:items-center gap-x-2 gap-y-4 md:gap-6.5 bg-white px-4 sm:px-6 py-4 shadow-sm rounded text-carbon mb-2">
      <strong className="w-44 sm:w-28 md:w-40 break-words">{name}</strong>

      <span className="font-normal">
        {totalAcres}
        <br className="hidden sm:block lg:hidden" />
        <small className="text-sm"> total acres</small>
      </span>

      {returnPathIsNotProjectListing && (
        <Link
          className="link !w-full sm:!w-auto sm:ml-auto"
          to={`/accounts/${id}${returnPath}`}
        >
          View {returnPathIsInbox ? "inbox" : "program"} with this account
        </Link>
      )}

      <Link
        className="!w-full sm:!w-auto sm:ml-auto"
        to={`/accounts/${id}/programs`}
      >
        <ProjectBadge
          type={accountProjectsToView === 0 ? "neutral" : "warning"}
          size="sm"
          block
        >
          {accountProjectsToView === 0
            ? "No programs to review"
            : `${accountProjectsToView} programs to review`}
        </ProjectBadge>
      </Link>

      <Link
        className="btn2 btn2-outline-primary font-semibold w-full text-center sm:w-auto"
        to={`/accounts/${id}`}
      >
        View account
      </Link>
    </div>
  )
}

export default AccountRow
