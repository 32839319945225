import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"

import { inviteYourNeighbor } from "../../../api/data"
import { FormikValues } from "formik"

export const useInviteYourNeighbor = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  config: UseMutationOptions<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutation<TData, TError, TVariables, unknown>({
    mutationFn: inviteYourNeighbor,
    ...config,
  })
}
