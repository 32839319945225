const LossDetectionText = () => (
  <div className="space-y-p my-4">
    <p className="italic">
      Losses depicted on this map were detected up to 1 month prior to the
      detection date.
    </p>

    <p>
      Loss Detection alerts you when a new tree loss event occurs on your land.
      Detection updates weekly with new confirmed disturbances to your forested
      land based on satellite imagery.
    </p>

    <p>
      Major severity loss means total tree loss was observed. Minor severity
      loss means partial tree loss was observed.
    </p>

    <p>
      Learn more about{" "}
      <a
        className="link"
        href="https://ncx.com/learning-hub/protect-your-land-with-loss-detection/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Loss Detection
      </a>
      .
    </p>
  </div>
)

export default LossDetectionText
